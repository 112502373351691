import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Container, Col, Row, Button } from 'reactstrap'
import { InputFormat, SinglePageCard } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { activate, selectSessionStatus, clearErrorMessage } from 'slices/sessionSlice'
import styles from './Activate.module.scss'

const Activate: React.FC = () => {
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [name, setName] = React.useState<string | undefined>(undefined)
  const [password, setPassword] = React.useState<string | undefined>(undefined)
  const [confirmation, setConfirmation] = React.useState<string | undefined>(undefined)
  const [nameValidity, setNameValidity] = React.useState(false)
  const [passwordValidity, setPasswordValidity] = React.useState(false)
  const [confirmationValidity, setConfirmationValidity] = React.useState(false)

  const dispatch = useDispatch()
  const { loggedIn, activated, errorMessage } = useSelector(selectSessionStatus)
  const history = useHistory()
  const onSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    if (name && password) {
      dispatch(activate({ name, password }))
    }
  }
  React.useEffect(() => {
    if (!loggedIn) {
      history.push('/signup')
      return
    }

    if (activated) {
      history.push('/login')
    }
  }, [history, loggedIn, activated])

  React.useEffect(() => {
    if (errorMessage !== '') {
      setModalErrorMessage('登録に失敗しました。')
    } else {
      setModalErrorMessage(undefined)
    }
  }, [errorMessage])

  const disabled = React.useMemo(
    () => !(name && nameValidity && password && passwordValidity && confirmation && confirmationValidity),
    [name, nameValidity, password, passwordValidity, confirmation, confirmationValidity]
  )

  return (
    <SinglePageCard
      title="アカウント情報"
      errorMessage={modalErrorMessage}
      onHideNotification={() => {
        setModalErrorMessage(undefined)
        dispatch(clearErrorMessage())
      }}
    >
      <Container>
        <InputFormat
          label="お名前"
          placeholder="お名前"
          value={name}
          size="large"
          maxLength={100}
          formText="アプリケーション上で表示されます。後で変更することも可能です。"
          onChange={value => setName(value)}
          validations={[Rules.Required]}
          onValidate={setNameValidity}
        />

        <div className="text-center py-3">新しいパスワード（半角英数字8文字以上・大文字・小文字を含む）を作成してください。</div>

        <InputFormat
          label="新しいパスワード"
          type="password"
          placeholder="新しいパスワード"
          value={password}
          size="large"
          maxLength={32}
          onChange={value => setPassword(value)}
          validations={[Rules.MinLength(8), Rules.Password]}
          onValidate={setPasswordValidity}
        />

        <InputFormat
          label="パスワード（確認）"
          type="password"
          placeholder="パスワード（確認）"
          value={confirmation}
          size="large"
          maxLength={32}
          onChange={value => setConfirmation(value)}
          validations={[Rules.MinLength(8), Rules.Password, Rules.PasswordConfirmation(password)]}
          onValidate={setConfirmationValidity}
        />

        <Row>
          <Col className="text-center mt-3">
            <Button color="primary" className={styles.button} disabled={disabled} onClick={onSubmit}>
              登録
            </Button>
          </Col>
        </Row>
      </Container>
    </SinglePageCard>
  )
}

export default Activate
