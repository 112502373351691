import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Card, CardBody, Row, Col } from 'reactstrap'
import moment from 'moment'
import { UpdateWorkSchedule } from 'api/works'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectTenantsStatus, getWorkspaceSummary } from 'slices/tenantsSlice'
import { updateWork } from 'slices/worksSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'
import { BadgeLabel, WorksSelector, MultipleFooter, WorkerPopover, SubmitFooter, TimeSelect } from 'components/common'
import {
  getEditSchedules,
  getOriginalSchedulesFromWork,
  getUpdateWorkerSchedules,
  SHIFT_SCHEDULE_TYPE_ID,
  SUPPORT_SCHEDULE_TYPE_ID,
  ColorTypes,
} from 'components/common/utils'
import { ColorType, SelectedScheduleType, EditSchedule } from 'components/common/types'
import EditChangesDiscardDialog from 'components/EditChangesDiscardDialog/EditChangesDiscardDialog'
import useAssignment from 'hooks/useAssignment'
import TeamWorkerCard from './TeamWorkerCard'
import TeamWorkerReassign, { ScheduleEditType, Worker } from './TeamWorkerReassign'
import { getWorkerPerformance } from './utils'
import styles from './TeamAssignment.module.scss'

const TeamAssignment: React.FC = () => {
  const [focusId, setFocusId] = React.useState<number | undefined>()
  const [selectorOpen, setSelectorOpen] = React.useState(false)
  const [multipleMode, setMultipleMode] = React.useState(false)
  const [selectedWorker, setSelectedWorker] = React.useState<number[]>([])
  const [initEditData, setInitEditData] = React.useState<ScheduleEditType[]>([])
  const [editData, setEditData] = React.useState<ScheduleEditType[]>([])
  const [currentScheduleType, setCurrentScheduleType] = React.useState<SelectedScheduleType | undefined>()
  const [selectedScheduleType, setSelectedScheduleType] = React.useState<SelectedScheduleType | undefined>()
  const [openReassign, setOpenReassign] = React.useState(false)
  const [editChangesDiscardDialogOpen, setEditChangesDiscardDialogOpen] = React.useState(false)

  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    team: { groupId },
  } = useSelector(selectSessionStatus)

  const { workspaceSummary } = useSelector(selectTenantsStatus)
  const { scheduleTypes } = useSelector(selectScheduleTypesStatus)
  const { workspaces } = useSelector(selectWorkspacesStatus)

  const {
    startHour,
    startMinute,
    openMoment,
    businessStartTime,
    businessEndTime,
    workspaceId,
    work,
    isBetween,
    setSubmitted,
  } = useAssignment()

  const group = React.useMemo(() => work?.groups.find(g => g.groupId === groupId), [groupId, work])

  React.useEffect(() => {
    if (!workspaceId) {
      return
    }
    dispatch(getWorkspaceSummary(workspaceId, moment().format('YYYY-MM-DD')))
    dispatch(getScheduleTypeList(workspaceId))
  }, [dispatch, workspaceId])

  const getWorkersFromScheduleTypeId = React.useCallback(
    (scheduleTypeId: number): Worker[] => {
      if (!group) {
        return []
      }
      return group.workers.reduce((acc: Worker[], cur) => {
        const filtered = cur.schedules.filter(s => isBetween(s.startAt, s.duration))
        const found = filtered.find(s => s.scheduleTypeId === scheduleTypeId)

        if ((filtered.length <= 1 || scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID) && found) {
          acc.push({
            scheduleId: scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID ? null : found.scheduleId,
            workerId: cur.workerId,
            workerName: cur.name,
            groupName: group.name ?? '未所属',
            groupColor: group.color,
            status: null,
            startAt: found.startAt,
            duration: found.duration,
            skillIds: cur.skillIds,
          })
        }
        return acc
      }, [])
    },
    [isBetween, group]
  )

  const getWorkersFromSupportSchedules = React.useCallback(
    (targetWorkspaceId: number): Worker[] => {
      if (!group) {
        return []
      }
      return group.workers.reduce((acc: Worker[], cur) => {
        const filtered = cur.schedules.filter(s => isBetween(s.startAt, s.duration))
        const found = filtered.find(s => s.scheduleTypeId === SUPPORT_SCHEDULE_TYPE_ID)

        if (found?.supportWorkspaceId === targetWorkspaceId) {
          acc.push({
            scheduleId: found.scheduleId,
            workerId: cur.workerId,
            workerName: cur.name,
            groupName: group.name ?? '未所属',
            groupColor: group.color,
            status: null,
            startAt: found.startAt,
            duration: found.duration,
            skillIds: cur.skillIds,
          })
        }
        return acc
      }, [])
    },
    [isBetween, group]
  )

  React.useEffect(() => {
    const scheduleTypeData: ScheduleEditType[] = scheduleTypes.map(scheduleType => ({
      scheduleTypeId: scheduleType.scheduleTypeId,
      name: scheduleType.name,
      color: scheduleType.color,
      requiredSkills: scheduleType.requiredSkills.map(s => s.skillId),
      workers: getWorkersFromScheduleTypeId(scheduleType.scheduleTypeId),
    }))
    const workspaceData: ScheduleEditType[] = workspaces
      .filter(w => w.workspaceId !== workspaceId)
      .map(workspace => ({
        scheduleTypeId: workspace.workspaceId,
        name: workspace.name,
        requiredSkills: [],
        workers: getWorkersFromSupportSchedules(workspace.workspaceId),
      }))
    const noSchedules: ScheduleEditType = {
      scheduleTypeId: SHIFT_SCHEDULE_TYPE_ID,
      name: '予定未入力',
      color: ColorTypes.Silver,
      requiredSkills: [],
      workers: getWorkersFromScheduleTypeId(SHIFT_SCHEDULE_TYPE_ID),
    }

    setInitEditData(_.concat(scheduleTypeData, workspaceData, [noSchedules]))
    setEditData(_.concat(scheduleTypeData, workspaceData, [noSchedules]))
  }, [getWorkersFromScheduleTypeId, getWorkersFromSupportSchedules, scheduleTypes, workspaceId, workspaces])

  const getSubmitSchedules = (worker: Worker, id: number, isSupport: boolean): UpdateWorkSchedule[] => {
    const originalSchedules: EditSchedule[] =
      group?.workers.filter(w => w.workerId === worker.workerId).flatMap(w => getEditSchedules(w.schedules)) || []
    const scheduleTypeId = isSupport ? SUPPORT_SCHEDULE_TYPE_ID : id
    const supportWorkspaceId = isSupport ? id : null
    const updateSchedules = getUpdateWorkerSchedules(
      worker.startAt,
      worker.duration,
      scheduleTypeId,
      supportWorkspaceId,
      null,
      originalSchedules
    )

    const deleteData: UpdateWorkSchedule[] = originalSchedules
      .filter(org => !updateSchedules.find(s => s.scheduleId === org.scheduleId))
      .map(org => ({ scheduleId: org.scheduleId, schedule: null }))

    const updateData: UpdateWorkSchedule[] = updateSchedules
      .filter(s => !originalSchedules.some(org => _.isEqual(org, s)))
      .map(s => ({
        scheduleId: s.scheduleId,
        schedule: {
          scheduleTypeId: s.scheduleTypeId,
          supportWorkspaceId: s.supportWorkspaceId,
          startAt: s.startAt,
          duration: s.duration,
          workerId: worker.workerId,
          groupId: null,
        },
      }))
    return deleteData.concat(updateData)
  }

  const onCancel = () => {
    setEditData(initEditData)
    setEditChangesDiscardDialogOpen(false)
  }
  const onSubmit = () => {
    if (!work) {
      return
    }

    setSubmitted(true)
    const compare = initEditData.flatMap(s => s.workers)
    const data: UpdateWorkSchedule[] = editData
      .filter(edit => edit.scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID && edit.name !== '予定未入力')
      .flatMap(edit => {
        return edit.workers
          .filter(worker => !_.isEqual(_.find(compare, ['workerId', worker.workerId]), worker))
          .flatMap(worker => getSubmitSchedules(worker, edit.scheduleTypeId, !edit?.color))
      })
    const originalSchedules = getOriginalSchedulesFromWork(data, work)

    dispatch(updateWork(workspaceId, work.workId, { schedules: data, originalSchedules }))
  }

  const onPopoverOpen = (workerId: number, scheduleTypeId: number, name: string) => {
    setFocusId(workerId)
    setSelectedWorker([workerId])
    setCurrentScheduleType({ scheduleTypeId, name })
  }

  const onPopoverClose = () => {
    setFocusId(undefined)
  }

  const initialSelected = () => {
    setSelectorOpen(false)
    setMultipleMode(false)
    setSelectedWorker([])
    setCurrentScheduleType(undefined)
  }

  const onSelectorWorkClick = (scheduleTypeId: number, name: string, color?: ColorType) => {
    setSelectedScheduleType({ scheduleTypeId, name, color: color })
    setOpenReassign(true)
  }

  const onWorkerCardClick = (workerId: number, scheduleTypeId: number, name: string) => {
    const sameScheduleType = currentScheduleType?.scheduleTypeId === scheduleTypeId
    if (sameScheduleType && selectedWorker.includes(workerId)) {
      setSelectedWorker(selectedWorker.filter(id => id !== workerId))
    } else if (sameScheduleType) {
      setSelectedWorker([...selectedWorker, workerId])
    } else {
      setCurrentScheduleType({ scheduleTypeId, name })
      setSelectedWorker([workerId])
    }
  }

  const onReassignClose = () => {
    initialSelected()
    setOpenReassign(false)
  }
  const onMultipleButtonClick = () => {
    setSelectedWorker([])
    setMultipleMode(!multipleMode)
  }

  const unchanged = React.useMemo(() => _.isEqual(initEditData, editData), [editData, initEditData])

  const sortSchedule = (a: ScheduleEditType, b: ScheduleEditType) => {
    // 予定未入力にworkersがいる場合は必ず先頭に来るようにする
    if (a.name === '予定未入力' && a.workers.length > 0) {
      return -1
    }
    if (b.name === '予定未入力' && b.workers.length > 0) {
      return 1
    }

    // workersの数でソートする
    const aHasWorkers = a.workers.length > 0
    const bHasWorkers = b.workers.length > 0

    // workersがいる場合は先、いない場合は後に来るようにする
    if (aHasWorkers && !bHasWorkers) {
      return -1
    }
    if (!aHasWorkers && bHasWorkers) {
      return 1
    }

    return 0
  }

  const sortedData = editData.slice().sort(sortSchedule)

  return (
    <>
      <div className={styles.container}>
        <div className="d-flex align-items-center">
          <div className="font-x-large font-weight-bold flex-grow-1">人員配置</div>
          <div className="bg-white rounded">
            <Button outline onClick={() => history.push(`team-schedules/${moment().format('YYYY-MM-DD')}`)}>
              本日の作業計画
            </Button>
          </div>
          <div className="bg-white rounded ml-3">
            <Button
              outline
              className="d-flex align-items-center"
              disabled={multipleMode}
              onClick={onMultipleButtonClick}
            >
              <i className="icf-edit pr-2 font-large" />
              <div className="pl-1">まとめて配置変更</div>
            </Button>
          </div>
        </div>
        <div className="d-flex align-items-center pt-3">
          <div className="pr-2">表示中の配置:</div>
          <TimeSelect
            hour={startHour}
            minute={startMinute}
            label=""
            start={businessStartTime}
            end={businessEndTime}
            onChange={(hour, minute) => {
              history.replace(`?hour=${hour}&minute=${minute}`)
            }}
          />
          <div className="pr-2">からの配置</div>
          <Button color="link" onClick={() => history.replace(pathname)}>
            <div>現在時刻の配置にする</div>
          </Button>
        </div>
        {sortedData.map((scheduleType, index) => (
          <Card key={`card-${scheduleType.scheduleTypeId}-${index}`} className="my-3 shadow-sm">
            <CardBody className="p-3">
              <div className="d-flex mb-2">
                <BadgeLabel label={scheduleType.name} color={scheduleType.color} />
              </div>
              <Row sm={4} className="mx-0">
                {scheduleType.workers.map((worker, i) => (
                  <Col key={`col-${worker.workerId}`} className={`pt-2 pr-0 pl-${i % 4 !== 0 ? '2' : '0'}`}>
                    {multipleMode ? (
                      <TeamWorkerCard
                        workerId={worker.workerId}
                        workerName={worker.workerName}
                        groupName={worker.groupName}
                        groupColor={worker.groupColor}
                        status={selectedWorker.includes(worker.workerId) ? 'selected' : worker.status}
                        performance={getWorkerPerformance(workspaceSummary, groupId, worker.workerId)}
                        onClick={() =>
                          onWorkerCardClick(worker.workerId, scheduleType.scheduleTypeId, scheduleType.name)
                        }
                      />
                    ) : (
                      <WorkerPopover
                        startAt={worker.startAt}
                        duration={worker.duration}
                        scheduleType={{
                          scheduleTypeId: scheduleType.scheduleTypeId,
                          name: scheduleType.name,
                          color: scheduleType?.color,
                        }}
                        offsetY={-45}
                        onClick={() => setSelectorOpen(true)}
                        onOpen={() => onPopoverOpen(worker.workerId, scheduleType.scheduleTypeId, scheduleType.name)}
                        onClose={onPopoverClose}
                      >
                        <TeamWorkerCard
                          workerId={worker.workerId}
                          workerName={worker.workerName}
                          groupName={worker.groupName}
                          groupColor={worker.groupColor}
                          status={worker.status}
                          isFocus={focusId === worker.workerId}
                          performance={getWorkerPerformance(workspaceSummary, groupId, worker.workerId)}
                        />
                      </WorkerPopover>
                    )}
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        ))}
      </div>
      <TeamWorkerReassign
        isOpen={openReassign}
        selectedWorkerIds={selectedWorker}
        selectedScheduleType={selectedScheduleType}
        currentScheduleType={currentScheduleType}
        editData={editData}
        currentTime={openMoment.format('HH:mm')}
        setEditData={setEditData}
        onClose={onReassignClose}
        getWorkerPerformance={workerId => getWorkerPerformance(workspaceSummary, groupId, workerId)}
      />
      <WorksSelector
        open={selectorOpen}
        scheduleTypes={scheduleTypes}
        workspaces={workspaces}
        currentScheduleType={currentScheduleType}
        workspaceId={workspaceId}
        showWorkspace={true}
        onClose={() => setSelectorOpen(!selectorOpen)}
        onWorkClick={onSelectorWorkClick}
      />
      {multipleMode && (
        <MultipleFooter
          stepNumber={selectorOpen ? 2 : 1}
          stepText={selectorOpen ? '作業内容を選択' : 'スケジュールを変更したいユーザーを選択'}
          disabled={selectedWorker.length === 0}
          selectorOpen={selectorOpen}
          decisionButtonClick={() => setSelectorOpen(true)}
          onCancel={initialSelected}
        />
      )}
      {!multipleMode && !unchanged && (
        <SubmitFooter
          onCancel={() => setEditChangesDiscardDialogOpen(true)}
          onSubmit={onSubmit}
          updatedBy={work?.updatedBy}
          updatedAt={work?.updatedAt}
        />
      )}

      <EditChangesDiscardDialog
        isOpen={editChangesDiscardDialogOpen}
        onCancel={() => setEditChangesDiscardDialogOpen(false)}
        onDiscard={onCancel}
      />
    </>
  )
}

export default TeamAssignment
