import { AxiosError } from 'axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from 'store'
import * as API from 'api/works_lite'
import * as WorkAPI from 'api/works'
import { makeErrorMessage, UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'
import { commonParams, getSplitPeriods } from 'slices/utils'
import * as Spinner from 'slices/spinnerSlice'
import { validateToken } from 'slices/sessionSlice'
import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'

type WorkLiteState = API.WorkLiteListResponse & {
  isRequesting: boolean
  errorMessage: string
}

const initialState: WorkLiteState = {
  isRequesting: false,
  errorMessage: '',
  works: [],
}

export const worksLiteSlice = createSlice({
  name: 'works',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
      state.works = []
    },
    apiFailureUnclearWorks: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getWorkLiteListSuccess: (state, action: PayloadAction<API.WorkLiteListResponse>) => {
      state.isRequesting = false
      state.works = action.payload.works
    },
    getWorkLiteSuccess: (state, action: PayloadAction<API.WorkLiteResponse>) => {
      state.isRequesting = false
      const index = state.works.findIndex(work => work.workId === action.payload.workId)
      state.works.splice(index, 1, action.payload)
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  apiFailureUnclearWorks,
  getWorkLiteListSuccess,
  getWorkLiteSuccess,
} = worksLiteSlice.actions

export const getWorkLiteList = (workspaceId: number, from: string, to: string): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(startRequest())

  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }
  const splitPeriods = getSplitPeriods(from, to)
  dispatch(Spinner.start())
  try {
    // 並列でリクエストすると直列で実行するより時間がかかりタイムアウトする場合があるので直列で実装する
    const workList = await splitPeriods.reduce(
      (promise, splitPeriod) =>
        promise.then(async works => [
          ...works,
          await API.getWorkLiteList(commonParams(getState), workspaceId, splitPeriod[0], splitPeriod[1]),
        ]),
      Promise.resolve([] as API.WorkLiteListResponse[])
    )
    dispatch(getWorkLiteListSuccess({ works: workList.flatMap(val => val.works) }))
  } catch (res) {
    const errorCode = makeErrorMessage(res as AxiosError)
    if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
      dispatch(SessionTimeoutDialog.open())
    } else {
      dispatch(NetworkErrorDialog.open({ code: errorCode }))
    }
    dispatch(apiFailure({ errorMessage: errorCode }))
  } finally {
    dispatch(Spinner.stop())
  }
}

export const updateTargetValuesLite = (
  workspaceId: number,
  workId: number,
  data: WorkAPI.UpdateTargetValuesType,
  showErrorDialog?: boolean
): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())

  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.updateTargetValuesLite(commonParams(getState), workspaceId, workId, data)
    .then((res: API.WorkLiteResponse) => dispatch(getWorkLiteSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (showErrorDialog) {
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
      }
      dispatch(apiFailureUnclearWorks({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const selectWorksLiteStatus = (state: RootState) => ({ ...state.worksLite })

export default worksLiteSlice.reducer
