import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap'
import { Role } from 'api/users'
import { showError, showSuccess } from 'slices/notificationSlice'
import { updateAccountInformation, selectSessionStatus, clearErrorMessage, logout } from 'slices/sessionSlice'
import { InputFormat, NavMenu, CardSubmitFooter } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import EditChangesDiscardDialog from 'components/EditChangesDiscardDialog/EditChangesDiscardDialog'
import PasswordChange from './PasswordChange'
import AccountDelete from './AccountDelete'

const AccountInformationEdit: React.FC = () => {
  const dispatch = useDispatch()
  const { user, isRequesting, errorMessage } = useSelector(selectSessionStatus)
  const [name, setName] = React.useState(user.name)
  const [email, setEmail] = React.useState(user.email)
  const [nameValidity, setNameValidity] = React.useState(false)
  const [emailValidity, setEmailValidity] = React.useState(false)
  const [isEmailChange, setIsEmailChange] = React.useState(false)
  const [openPasswordChange, setOpenPasswordChange] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [openEditChangesDiscardDialog, setOpenEditChangesDiscardDialog] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  const onSubmit = () => {
    setSubmitted(true)
    setIsEmailChange(user.email !== email)
    dispatch(updateAccountInformation({ name, email }))
  }
  const handlePasswordChange = () => {
    setOpenPasswordChange(false)
    dispatch(showSuccess())
  }
  const handleDelete = () => {
    setOpenDelete(false)
  }
  const handleEditChangesDiscard = () => {
    setOpenEditChangesDiscardDialog(false)
    setName(user.name)
    setEmail(user.email)
  }

  const history = useHistory()
  const handleNavMenuClick = (menuType: string) => {
    if (menuType === 'company') {
      history.push('/company/edit')
    }
  }

  const menuItems = [
    {
      type: 'information',
      label: 'アカウント情報',
    },
  ]
  if (user.role === Role.Admin) {
    menuItems.push({
      type: 'company',
      label: 'ご登録企業情報',
    })
  }

  const unchanged = React.useMemo(() => {
    return user.name === name && user.email === email
  }, [user, name, email])

  const disabled = React.useMemo(() => !(name && nameValidity && email && emailValidity), [
    name,
    nameValidity,
    email,
    emailValidity,
  ])

  React.useEffect(() => {
    if (isRequesting || !submitted) {
      return
    }

    if (errorMessage === '') {
      dispatch(showSuccess())
      if (isEmailChange) {
        dispatch(logout())
      }
    } else {
      dispatch(showError())
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, isEmailChange, dispatch])

  return (
    <>
      <NavMenu type={'information'} items={menuItems} onNavMenuClick={handleNavMenuClick}>
        <div className="m-3">
          <Row noGutters className="mb-3">
            <Col className="font-x-large font-weight-bold">アカウント情報</Col>
          </Row>
          <Card>
            <CardBody>
              <Row>
                <Col md={8}>
                  <InputFormat
                    label="お名前"
                    placeholder="お名前"
                    className="mt-4"
                    value={name}
                    size="middle"
                    onChange={value => setName(value)}
                    validations={[Rules.Required]}
                    onValidate={setNameValidity}
                    maxLength={100}
                  />

                  <InputFormat
                    label="メールアドレス"
                    placeholder="メールアドレス"
                    className="mt-4"
                    value={email}
                    size="middle"
                    formText="メールアドレス変更保存後、自動的にログアウトします。新しいメールアドレスに確認メールが送信されますので、再度ログインしてください。"
                    onChange={value => setEmail(value)}
                    validations={[Rules.Required, Rules.Email]}
                    onValidate={setEmailValidity}
                    maxLength={100}
                  />

                  <FormGroup row className="mt-4">
                    <Label md={4}>パスワード</Label>
                    <Col md={8} className="align-self-center">
                      強固なパスワードにするには、数字、文字、記号を組み合わせることをお勧めします。
                      また、推測が困難で実際に使われていない言葉を使ったり、このアカウントで独自に設定することをお勧めします。
                      <Row className="mt-3">
                        <Col md={5}>
                          <Button outline color="primary" size="sm" block onClick={() => setOpenPasswordChange(true)}>
                            パスワードを変更
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>

                  <FormGroup row className="mt-4">
                    <Label md={4}>アカウントの削除</Label>
                    <Col md={8} className="align-self-center">
                      アカウントを削除すると、アカウント情報などはすべて失われ、復旧できません。
                      <Row className="mt-3">
                        <Col md={5}>
                          <Button outline color="danger" size="sm" block onClick={() => setOpenDelete(true)}>
                            アカウントを削除
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>

            <CardSubmitFooter
              onCancel={() => setOpenEditChangesDiscardDialog(true)}
              onSubmit={onSubmit}
              cancelDisabled={unchanged}
              submitDisabled={unchanged || disabled}
            />
          </Card>
        </div>
      </NavMenu>

      <PasswordChange
        isOpen={openPasswordChange}
        onSuccess={handlePasswordChange}
        onCancel={() => setOpenPasswordChange(false)}
      />

      <AccountDelete isOpen={openDelete} onSuccess={handleDelete} onCancel={() => setOpenDelete(false)} />

      <EditChangesDiscardDialog
        isOpen={openEditChangesDiscardDialog}
        onCancel={() => setOpenEditChangesDiscardDialog(false)}
        onDiscard={handleEditChangesDiscard}
      />
    </>
  )
}
export default AccountInformationEdit
