import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText, CardColumns } from 'reactstrap'
import { ScheduleTypeResponse, connectionTypes } from 'api/schedule_types'
import { CONFLICT_ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'
import { showError, showSuccess } from 'slices/notificationSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import {
  clearErrorMessage,
  getScheduleTypeList,
  selectScheduleTypesStatus,
  updateScheduleType,
} from 'slices/scheduleTypesSlice'
import { getDataConnectionInfo } from 'slices/tenantsSlice'
import { getWorkspace, selectWorkspacesStatus } from 'slices/workspacesSlice'
import { getSkillList, selectSkillsStatus } from 'slices/skillsSlice'
import {
  NavMenu,
  List,
  InputGroupFormat,
  InputFormat,
  SelectBoxFormat,
  ColorPickerFormat,
  CardSubmitFooter,
  CustomButton,
  ItemEdit,
} from 'components/common'
import { SuggestionItem } from 'components/common/types'
import { getConnectionTypeLabel, isReadOnlyWorkspace } from 'components/common/utils'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import useScheduleType from 'hooks/useScheduleType'
import placeholder from 'images/allEmpty.svg'
import FilterEdit from './FilterEdit'
import ScheduleTypeDelete from './ScheduleTypeDelete'
import { ParamProps, ScheduleTypeEditData } from './types'
import styles from './WorkspaceDetail.module.scss'

const menuItems = [
  {
    type: 'workspaces',
    label: 'ワークスペース一覧',
  },
]

const onDetailClick = () => window.open('https://help.smileboard.jp/setup_work', '_blank')

const WorkspaceDetail: React.FC = () => {
  const params = useParams<ParamProps>()
  const workspaceId = Number(params.workspaceId)

  const [selectedScheduleType, setSelectedScheduleType] = React.useState<ScheduleTypeResponse | undefined>()
  const [initEditData, setInitEditData] = React.useState<ScheduleTypeEditData | undefined>()
  const [submitted, setSubmitted] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)

  const { user } = useSelector(selectSessionStatus)
  const { workspaces } = useSelector(selectWorkspacesStatus)
  const { skills } = useSelector(selectSkillsStatus)
  const { scheduleTypes, isRequesting, errorMessage } = useSelector(selectScheduleTypesStatus)

  const history = useHistory()
  const dispatch = useDispatch()

  const {
    setNameValidity,
    setTargetDatabaseValidity,
    setTargetColumnValidity,
    setUnitValidity,
    setDefaultPerformanceIndexValidity,
    disabled,
    editData,
    setEditData,
    scheduleTypeUpdateData,
    targetDatabases,
    targetColumns,
    filterColumns,
  } = useScheduleType()

  React.useEffect(() => {
    dispatch(getWorkspace(workspaceId))
    dispatch(getScheduleTypeList(workspaceId))
  }, [dispatch, workspaceId])

  React.useEffect(() => {
    dispatch(getDataConnectionInfo())
    dispatch(getSkillList())
  }, [dispatch])

  const workspace = React.useMemo(() => workspaces.find(w => w.workspaceId === workspaceId), [workspaces, workspaceId])
  const isReadOnly = React.useMemo(() => isReadOnlyWorkspace(user, workspace), [user, workspace])

  const scheduleTypeListItems = React.useMemo(
    () =>
      scheduleTypes.map(scheduleType => {
        return {
          id: scheduleType.scheduleTypeId,
          title: scheduleType.name,
          option: <div className={`${styles.square} bg-${scheduleType.color}`} />,
        }
      }),
    [scheduleTypes]
  )

  React.useEffect(() => {
    if (selectedScheduleType) {
      const nextScheduleType = scheduleTypes.find(
        scheduleType => scheduleType.scheduleTypeId === selectedScheduleType.scheduleTypeId
      )
      if (nextScheduleType) {
        // updateの結果としてfilterの内容が変わるケースがあるため、新しいデータをselectedScheduleTypeに入れる。
        setSelectedScheduleType(nextScheduleType)
        return
      }
    }

    if (scheduleTypes.length > 0) {
      // 初回表示時は一番上を選択
      setSelectedScheduleType(scheduleTypes[0])
    } else {
      setSelectedScheduleType(undefined)
    }
  }, [scheduleTypes, selectedScheduleType])

  const updateEditData = React.useCallback(
    (scheduleType: ScheduleTypeResponse | undefined) => {
      const newEditData = scheduleType && {
        name: scheduleType.name,
        color: scheduleType.color,
        requiredSkills: scheduleType.requiredSkills,
        connectionType: scheduleType.connectionType || connectionTypes.None,
        dataConnection: scheduleType.dataConnection,
        targetDatabase: scheduleType.targetDatabase ?? undefined,
        targetColumn: scheduleType.targetColumn ?? undefined,
        unit: scheduleType.unit ?? undefined,
        filter: scheduleType.filter,
        defaultPerformanceIndex: scheduleType.defaultPerformanceIndex?.toString(),
      }

      setEditData(newEditData)
      setInitEditData(newEditData)
    },
    [setEditData, setInitEditData]
  )

  React.useEffect(() => {
    updateEditData(selectedScheduleType)
  }, [selectedScheduleType, updateEditData])

  const unchanged = React.useMemo(() => _.isEqual(initEditData, editData), [initEditData, editData])

  const onSelectItem = (selectedId: number) => {
    const scheduleType = scheduleTypes.find(s => s.scheduleTypeId === selectedId)
    setSelectedScheduleType(scheduleType)
  }

  const onSubmit = () => {
    if (!scheduleTypeUpdateData || !selectedScheduleType) {
      return
    }
    setSubmitted(true)
    dispatch(updateScheduleType(workspaceId, selectedScheduleType.scheduleTypeId, scheduleTypeUpdateData))
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
    } else {
      if (errorMessage === CONFLICT_ERROR_STATUS_CODE) {
        dispatch(showError({ errorMessage: 'すでにこの名前の作業は存在しています。' }))
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
        dispatch(showError())
      }
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  const handleSkillEdit = (items: SuggestionItem[]) => {
    if (!editData) {
      return
    }
    const requiredSkills = skills.filter(s => items.some(i => i.id === s.skillId))
    setEditData({ ...editData, requiredSkills })
  }

  return (
    <>
      <NavMenu type="workspaces" items={menuItems} onNavMenuClick={() => history.push(`/workspaces`)}>
        <div className="mt-3 mx-3">
          <div className={styles.topContents}>
            <Link to="/workspaces" className="d-flex align-items-center text-secondary">
              <i className="icf-chevron_left" />
              <span style={{ marginTop: '0.2rem' }}>ワークスペース一覧へ</span>
            </Link>
            <div className="d-flex align-items-center mb-3">
              <div className="font-x-large flex-grow-1 font-weight-bold">{workspace?.name}</div>
              <CustomButton
                outline
                disabled={isReadOnly}
                onClick={() => history.push(`/workspaces/${workspaceId}/templates`)}
              >
                予定テンプレート管理
              </CustomButton>
              <CustomButton
                outline
                disabled={isReadOnly}
                className="ml-2"
                onClick={() => history.push(`/workspaces/${workspaceId}/groups`)}
              >
                グループ管理
              </CustomButton>
              <CustomButton
                outline
                icon="setting"
                disabled={isReadOnly}
                className="ml-2"
                onClick={() => history.push(`/workspaces/${workspaceId}/edit`)}
              >
                設定
              </CustomButton>
              <CustomButton
                icon="plus"
                disabled={isReadOnly}
                className="ml-2"
                onClick={() => history.push(`/workspaces/${workspaceId}/create`)}
              >
                作業の追加
              </CustomButton>
            </div>
          </div>
          <Row className={`pt-2 pb-3 ${styles.row}`}>
            <Col md={4} className="h-100">
              <Card className={styles.list}>
                {scheduleTypeListItems.length > 0 ? (
                  <List
                    items={scheduleTypeListItems}
                    selectedId={selectedScheduleType?.scheduleTypeId}
                    onAction={onSelectItem}
                  />
                ) : (
                  <CardBody className="text-center">
                    <img className={`mx-auto d-block w-100 ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle font-weight-bold py-4">作業がまだ登録されていません</div>
                    <div>
                      作業を登録することで、メンバーの予定を作ったり、作業毎の生産性を見ることができます。まずは最初の作業を登録してみましょう。
                    </div>
                    <Button className="mx-auto d-block m-4" size="sm" outline onClick={onDetailClick}>
                      作業についてもっと詳しく
                    </Button>
                  </CardBody>
                )}
              </Card>
            </Col>
            <Col md={8} className="h-100">
              <Card className="h-100">
                {editData ? (
                  <>
                    <div className={styles.workDetail}>
                      <CardBody>
                        <CardColumns className="d-flex">
                          <CardTitle className="font-large flex-grow-1 font-weight-bold">作業の詳細</CardTitle>
                          <span className="text-muted">※必須項目</span>
                        </CardColumns>
                        <InputFormat
                          label="名称※"
                          placeholder="作業名を入力"
                          value={editData.name}
                          size="middle"
                          maxLength={100}
                          disabled={isReadOnly}
                          onChange={value => setEditData({ ...editData, name: value })}
                          validations={[Rules.Required]}
                          onValidate={setNameValidity}
                        />
                        <ColorPickerFormat
                          label="キーカラー"
                          color={editData.color}
                          size="middle"
                          disabled={isReadOnly}
                          onChange={color => setEditData({ ...editData, color })}
                        />
                      </CardBody>

                      <CardBody>
                        <CardTitle className="font-large font-weight-bold">必須スキル設定</CardTitle>
                        <CardText className="py-2">
                          作業の必須スキルを設定しておくことで、対象のスキルを持っていないメンバーの予定に作業が入れられた際に
                          アラートを出します。（アラートは出ますが予定に入れることは可能です。）
                        </CardText>
                        <ItemEdit
                          items={skills.map(s => ({ id: s.skillId, value: s.name }))}
                          selectedItems={editData.requiredSkills.map(s => ({ id: s.skillId, value: s.name }))}
                          label="作業に必須スキルを追加"
                          itemName="スキル"
                          onChange={handleSkillEdit}
                        />
                      </CardBody>

                      <CardBody>
                        <CardTitle className="font-middle font-weight-bold py-1 mt-5">実績入力方法</CardTitle>
                        <InputFormat
                          label="実績入力方法"
                          onChange={() => {}}
                          disabled
                          size="middle"
                          value={getConnectionTypeLabel(editData.connectionType)}
                        />
                        {editData.connectionType !== connectionTypes.None && (
                          <>
                            {editData.connectionType === connectionTypes.Auto && (
                              <>
                                <CardText>抽出条件の設定</CardText>
                                <Card>
                                  <CardBody>
                                    <SelectBoxFormat
                                      label="参照するデータベース※"
                                      placeholder="データベースを選択"
                                      value={editData.targetDatabase}
                                      size="middle"
                                      items={targetDatabases}
                                      disabled={isReadOnly}
                                      onChange={e =>
                                        setEditData({
                                          ...editData,
                                          targetDatabase: e.key?.toString(),
                                          filter: [],
                                        })
                                      }
                                      validations={[Rules.Required]}
                                      onValidate={setTargetDatabaseValidity}
                                    />
                                  </CardBody>
                                  <hr className="m-0"></hr>
                                  <CardBody>
                                    <SelectBoxFormat
                                      label="実績が記載されている列※"
                                      placeholder="列を選択"
                                      value={targetColumns.find(t => t.key === editData.targetColumn)?.key}
                                      size="middle"
                                      items={targetColumns}
                                      disabled={isReadOnly}
                                      onChange={e =>
                                        setEditData({
                                          ...editData,
                                          targetColumn: e.key?.toString(),
                                        })
                                      }
                                      validations={[Rules.Required]}
                                      onValidate={setTargetColumnValidity}
                                    />
                                    <InputFormat
                                      label="実績の単位※"
                                      placeholder="単位を入力"
                                      value={editData.unit}
                                      size="middle"
                                      maxLength={10}
                                      disabled={isReadOnly}
                                      onChange={value => setEditData({ ...editData, unit: value })}
                                      validations={[Rules.Required]}
                                      onValidate={setUnitValidity}
                                    />
                                  </CardBody>

                                  <hr className="m-0"></hr>
                                  <CardBody>
                                    <CardTitle className="font-large font-weight-bold">フィルター</CardTitle>
                                    <div className="pb-3">
                                      伝票種別などに応じて、より詳細にフィルタリングすることができます。
                                    </div>
                                    <FilterEdit
                                      filterColumns={filterColumns}
                                      filter={editData.filter}
                                      disabled={isReadOnly}
                                      onChange={filter => setEditData({ ...editData, filter })}
                                    />
                                  </CardBody>
                                </Card>
                              </>
                            )}
                            {editData.connectionType === connectionTypes.Manual && (
                              <InputFormat
                                className="my-3"
                                label="実績の単位※"
                                placeholder="単位を入力"
                                value={editData.unit}
                                size="middle"
                                maxLength={10}
                                disabled={isReadOnly}
                                onChange={value => setEditData({ ...editData, unit: value })}
                                validations={[Rules.Required]}
                                onValidate={setUnitValidity}
                              />
                            )}
                            <CardTitle className="font-large font-weight-bold">デフォルトの人時生産性設定</CardTitle>
                            <CardText className="py-2">
                              この作業の過去実績がない場合に設定されるデフォルトの人時生産性を設定できます。
                              過去実績がなくても作業者管理で各作業者に手動で人時生産性を設定することもできます。
                            </CardText>
                            <InputGroupFormat
                              label="デフォルト値※"
                              addonText={`${editData.unit || '-'}/時間`}
                              value={editData.defaultPerformanceIndex}
                              disabled={isReadOnly}
                              maxLength={10}
                              onChange={value =>
                                setEditData({
                                  ...editData,
                                  defaultPerformanceIndex: value,
                                })
                              }
                              validations={[Rules.Required, Rules.PositiveInteger]}
                              onValidate={setDefaultPerformanceIndexValidity}
                            />
                          </>
                        )}
                      </CardBody>
                      <CardBody>
                        <CardTitle className="font-large font-weight-bold">作業の削除</CardTitle>
                        <CardText className="py-2">
                          作業を削除すると、作業履歴などの情報はすべて失われ、復旧できません。
                        </CardText>
                        <Button
                          outline
                          color="danger"
                          className="my-3"
                          disabled={isReadOnly}
                          onClick={() => setOpenDelete(true)}
                        >
                          作業を削除
                        </Button>
                      </CardBody>
                    </div>
                    <CardSubmitFooter
                      onCancel={() => updateEditData(selectedScheduleType)}
                      onSubmit={onSubmit}
                      submitDisabled={isReadOnly || disabled || unchanged}
                      cancelDisabled={unchanged}
                      updatedBy={selectedScheduleType?.updatedBy}
                      updatedAt={selectedScheduleType?.updatedAt}
                    />
                  </>
                ) : (
                  <CardBody className="text-center m-5 h-100">
                    <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                    <div className="font-middle font-weight-bold py-4">作業が選択されていません</div>
                    <div>作業を選択して、詳細情報を編集しましょう。</div>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>

          {selectedScheduleType && (
            <ScheduleTypeDelete
              isOpen={openDelete}
              workspaceId={workspaceId}
              scheduleTypeId={selectedScheduleType.scheduleTypeId}
              onSuccess={() => setOpenDelete(false)}
              onCancel={() => setOpenDelete(false)}
            />
          )}
        </div>
      </NavMenu>
    </>
  )
}

export default WorkspaceDetail
