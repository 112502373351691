import * as React from 'react'
import { Card, CardColumns } from 'reactstrap'

export const ColorTypes = {
  Silver: 'silver',
  Twilight: 'twilight',
  Sky: 'sky',
  Emerald: 'emerald',
  Magenta: 'magenta',
  Bengal: 'bengal',
  Mustard: 'mustard',
  Rose: 'rose',
  Violet: 'violet',
  Grass: 'grass',
} as const

export type ColorType = typeof ColorTypes[keyof typeof ColorTypes]
export const COLORS: Array<ColorType> = Object.values(ColorTypes)

type Props = {
  selected: ColorType
  disabled?: boolean
  onChange: (value: ColorType) => void
}

const ColorPicker: React.FC<Props> = props => {
  const { selected, disabled, onChange } = props

  const selectedStyle = (color: ColorType) => {
    const squareStyle = {
      display: 'table',
      width: '20px',
      height: '20px',
      minWidth: '20px',
      minHeight: '20px',
      borderRadius: '0.2rem',
      border: '0.09rem solid #ffffff',
    }
    return selected === color
      ? { ...squareStyle, boxShadow: '0 0 0.1rem 0.1rem #007bff' }
      : { ...squareStyle, cursor: 'pointer' }
  }

  const handleClick = (color: ColorType) => {
    if (!disabled) {
      onChange(color)
    }
  }

  return (
    <Card className="d-block px-3 py-2 overflow-auto">
      <CardColumns className="d-flex">
        {COLORS.map(color => (
          <div
            key={color}
            className={`bg-${color}`}
            style={selectedStyle(color)}
            onClick={() => handleClick(color)}
          ></div>
        ))}
      </CardColumns>
    </Card>
  )
}

export default ColorPicker
