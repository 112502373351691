import * as React from 'react'
import { useSelector } from 'react-redux'
import { selectSpinnerStatus } from 'slices/spinnerSlice'
import styles from './styles.module.scss'

const Spinner: React.FC = () => {
  const { show } = useSelector(selectSpinnerStatus)
  const SpinnerMemo = React.memo(() => (
    <div className={styles.backdrop}>
      <div className={`spinner-border text-primary ${styles.spinner}`} role="status" />
    </div>
  ))
  return show ? <SpinnerMemo /> : null
}

export default Spinner
