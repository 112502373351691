import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTemplateData, clearErrorMessage, selectWorkspacesStatus } from 'slices/workspacesSlice'
import { CustomModal } from 'components/common'

type Props = {
  isOpen: boolean
  workspaceId: number
  templateId: number | undefined
  onSuccess: () => void
  onCancel: () => void
}

const TemplateDelete: React.FC<Props> = ({ isOpen, workspaceId, templateId, onSuccess, onCancel }) => {
  const [submitted, setSubmitted] = React.useState(false)
  const { isRequesting, errorMessage } = useSelector(selectWorkspacesStatus)
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const dispatch = useDispatch()

  const handleDeleteClick = () => {
    if (templateId) {
      setSubmitted(true)
      dispatch(updateTemplateData(workspaceId, { id: templateId, schedules: null }))
    }
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else {
      onCancel()
      setModalErrorMessage('削除できませんでした。')
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, onSuccess, onCancel])

  return (
    <CustomModal
      isOpen={isOpen}
      title="予定テンプレートの削除"
      approveColor="danger"
      approveLabel="削除"
      errorMessage={modalErrorMessage}
      onCancel={onCancel}
      onApprove={handleDeleteClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-4">この予定テンプレートを本当に削除しますか？この操作は取り消せません。</div>
    </CustomModal>
  )
}

export default TemplateDelete
