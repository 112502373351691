export const Required = (value: string | undefined): string => {
  if (typeof value === 'undefined' || value.length > 0) {
    return ''
  }
  return '入力必須項目です'
}

export const MinLength = (n: number) => (value: string | undefined): string => {
  if (typeof value === 'undefined' || value.length >= n) {
    return ''
  }
  return `${n}文字以上入力してください`
}

export const Email = (value: string | undefined): string => {
  if (typeof value === 'undefined' || /^[\x20-\x7e]+@[\x20-\x7e]+$/.test(value)) {
    return ''
  }
  return '正しいメールアドレスを入力してください'
}

export const Password = (value: string | undefined): string => {
  if (typeof value === 'undefined' || /^[\x21-\x7e]{8,32}$/.test(value)) {
    return ''
  }
  return 'パスワードの形式が間違っています'
}

export const PasswordConfirmation = (password: string | undefined) => (value: string | undefined): string => {
  if (typeof value === 'undefined' || value === password) {
    return ''
  }
  return 'パスワードが一致していません'
}

export const DifferentPassword = (old: string | undefined) => (value: string | undefined): string => {
  if (typeof value === 'undefined' || value !== old) {
    return ''
  }
  return '現在設定中のパスワードは利用できません'
}

export const PhoneNumber = (value: string | undefined): string => {
  if (typeof value === 'undefined' || /^[-\d()]*$/.test(value)) {
    return ''
  }
  return '使用可能な文字は半角の数字・ハイフン・カッコのみです'
}

export const PostalCode = (value: string | undefined): string => {
  if (typeof value === 'undefined' || value === '' || /^\d{7}$/.test(value)) {
    return ''
  }
  return '正しい郵便番号を入力してください'
}

export const PositiveInteger = (value: string | undefined): string => {
  if (typeof value === 'undefined' || /^(\d+)$/.test(value)) {
    return ''
  }
  return '0以上の整数を入力してください'
}
