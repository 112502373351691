import * as React from 'react'
import { Input, Label } from 'reactstrap'
import styles from './styles.module.scss'

type Props = {
  id: string
  emptyLabel?: string
  accept?: string
  onChange: (file: File | null) => void
}

const FileInput: React.FC<Props> = ({ id, emptyLabel = 'ファイルは選択されていません', accept, onChange }) => {
  const [file, setFile] = React.useState<File | null>(null)
  const [fileName, setFileName] = React.useState('')

  React.useEffect(() => {
    onChange(file)
  }, [file, onChange])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0]
      setFile(selectedFile)
      setFileName(selectedFile.name)
    } else {
      setFile(null)
      setFileName('')
    }
  }

  return (
    <div className={styles.root}>
      <Input type="file" id={id} accept={accept} className={styles.fileInput} onChange={handleInputChange} />
      <Label for={id} className={'btn btn-outline-secondary ' + styles.button}>
        ファイルを選択
      </Label>
      <span className={file ? styles.msg : styles.msg + ' ' + styles.msgEmpty}>{file ? fileName : emptyLabel}</span>
    </div>
  )
}

export default FileInput
