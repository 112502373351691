import * as React from 'react'
import { CustomModal, FileInput, SelectBoxFormat } from 'components/common'
import { Button } from 'reactstrap'
import { CharacterCodeType, CharacterCodeTypes } from '../common/types'

type Props = {
  isOpen: boolean
  onCancel: () => void
  date: string // yyyy-MM-DD
}
type CharacterCodeItemType = { key: CharacterCodeType; value: string }

const characterCodeItem: CharacterCodeItemType[] = [
  { key: CharacterCodeTypes.shiftJis, value: CharacterCodeTypes.shiftJis },
  { key: CharacterCodeTypes.utf8, value: CharacterCodeTypes.utf8 },
]
const CSVManualInputDialog: React.FC<Props> = ({ isOpen, onCancel, date }) => {
  const [file, setFile] = React.useState<File | null>(null)
  const [characterCode, setCharacterCode] = React.useState(characterCodeItem[0])
  const handleDownloadFormat = () => {
    //   TODO フォーマットダウンロード時の処理
  }

  const handleApprove = () => {
    //   TODO 保存時の処理
  }

  return (
    <CustomModal
      isOpen={isOpen}
      title={`実績CSV一括入力 : ${date.replace(/-/g, '/')}`}
      onCancel={onCancel}
      onApprove={handleApprove}
      approveDisabled={file === null}
      onHideNotification={() => {}}
    >
      <div className="mb-3">
        下記実績時間別集計データフォーマットをダウンロードして、同じフォーマットでCSVアップロードをしてください。
      </div>
      <SelectBoxFormat
        label="文字コード選択"
        value={characterCode.key.toString()}
        size="middle"
        items={characterCodeItem}
        onChange={item => setCharacterCode(item as CharacterCodeItemType)}
        className="mb-3"
      />
      <ul>
        <li>対象の作業は実績入力方法が｢手動アップロード｣の作業のみです。</li>
        <li>
          1列目にはワークスペース名、2列目には作業名を入れてください。登録されているワークスペース名、作業名と完全一致することで、実績の一括入力が可能です。
        </li>
      </ul>
      <Button color="link" className="pl-0 mb-3" style={{ boxShadow: 'none' }} onClick={handleDownloadFormat}>
        実績時間別集計データフォーマットをダウンロード
      </Button>
      <FileInput id="memberFile" accept=".csv" onChange={f => setFile(f)} />
    </CustomModal>
  )
}

export default CSVManualInputDialog
