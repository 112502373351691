import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FormGroup, Label, Col } from 'reactstrap'
import { TenantEditDataType } from 'api/tenants'
import { Role } from 'api/users'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'
import { createTenant, selectTenantsStatus } from 'slices/tenantsSlice'
import { getUserList, selectUsersStatus } from 'slices/usersSlice'
import { getPostcodeList, selectPostcodeStatus } from 'slices/postcodeSlice'
import {
  InputFormat,
  PostalcodeInputForm,
  Notification,
  SelectBoxFormat,
  TimeSelect,
  SubmitFooter,
} from 'components/common'
import { prefectureItems } from 'components/common/utils'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import styles from './TenantCreate.module.scss'

const initialState: TenantEditDataType = {
  name: '',
  salesOfficeName: '',
  postalCode: '',
  prefecture: '',
  municipality: '',
  otherAddress: '',
  phoneNumber: '',
  personInCharge: '',
  businessStartHour: '',
  businessStartMinute: '',
  businessEndHour: '',
  businessEndMinute: '',
}

const TenantCreate: React.FC = () => {
  const [showError, setShowError] = React.useState(false)
  const [nameValidity, setNameValidity] = React.useState(false)
  const [postalCodeValidity, setPostalCodeValidity] = React.useState(false)
  const [prefectureValidity, setPrefectureValidity] = React.useState(false)
  const [municipalityValidity, setMunicipalityValidity] = React.useState(false)
  const [otherAddressValidity, setOtherAddressValidity] = React.useState(false)
  const [phoneNumberValidity, setPhoneNumberValidity] = React.useState(false)
  const [businessTimeValidity, setBusinessTimeValidity] = React.useState(false)
  const [personInChargeValidity, setPersonInChargeValidity] = React.useState(false)
  const [data, setData] = React.useState(initialState)
  const history = useHistory()
  const dispatch = useDispatch()

  React.useEffect(() => {
    const start = data.businessStartHour + data.businessStartMinute
    const end = data.businessEndHour + data.businessEndMinute
    setBusinessTimeValidity(start < end || start + end === '')
  }, [data.businessStartHour, data.businessStartMinute, data.businessEndHour, data.businessEndMinute])

  const disabled = React.useMemo(() => {
    const wrapdata: { [key: string]: string } = data
    const required = Object.keys(wrapdata)
      .filter(key => key !== 'salesOfficeName')
      .every(key => wrapdata[key] !== '')
    return !(
      required &&
      nameValidity &&
      postalCodeValidity &&
      prefectureValidity &&
      municipalityValidity &&
      otherAddressValidity &&
      phoneNumberValidity &&
      businessTimeValidity &&
      personInChargeValidity
    )
  }, [
    data,
    nameValidity,
    postalCodeValidity,
    prefectureValidity,
    municipalityValidity,
    otherAddressValidity,
    phoneNumberValidity,
    businessTimeValidity,
    personInChargeValidity,
  ])

  const unchanged = React.useMemo(() => _.isEqual(data, initialState), [data])

  React.useEffect(() => {
    dispatch(getUserList())
    dispatch(getPostcodeList())
  }, [dispatch])

  const { users } = useSelector(selectUsersStatus)
  const personInChargeItems: { key: string; value: string }[] = React.useMemo(
    () => users.filter(user => user.role === Role.TenantAdmin).map(user => ({ key: user.userId, value: user.name })),
    [users]
  )

  const [submitted, setSubmitted] = React.useState(false)
  const onSubmit = () => {
    setSubmitted(true)
    dispatch(createTenant(data))
  }

  const { postcodeList } = useSelector(selectPostcodeStatus)
  const foundPostalCode = React.useMemo(() => postcodeList.find(p => p.postcode === data.postalCode), [
    data.postalCode,
    postcodeList,
  ])
  const onAddressAutoInput = () => {
    setData({
      ...data,
      prefecture: foundPostalCode?.address1 || '',
      municipality: foundPostalCode?.address2 || '',
      otherAddress: foundPostalCode?.address3 || '',
    })
  }

  const { isRequesting, errorMessage } = useSelector(selectTenantsStatus)
  React.useEffect(() => {
    // 送信前やリクエスト中の場合には抜ける
    if (isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      history.push('/tenants')
    } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
      setShowError(true)
    }
    setSubmitted(false)
  }, [isRequesting, errorMessage, submitted, history])

  return (
    <>
      <div className={styles.container}>
        <div className="sticky-top">
          <div className="font-x-large font-weight-bold text-center border-bottom py-3 bg-white">テナント新規登録</div>
          <Notification errorMessage="保存できませんでした。" error={showError} hide={() => setShowError(false)} />
        </div>
        <div className="w-50 mx-auto mt-3">
          <div className="d-flex justify-content-between py-1">
            <div className="font-middle font-weight-bold">テナントの詳細</div>
            <small>※必須項目</small>
          </div>
          <InputFormat
            label="企業名※"
            placeholder="企業名を入力"
            value={data.name}
            maxLength={200}
            onChange={value => setData({ ...data, name: value })}
            validations={[Rules.Required]}
            onValidate={setNameValidity}
          />
          <InputFormat
            label="事業所名"
            placeholder="事業所名を入力"
            value={data.salesOfficeName}
            maxLength={100}
            onChange={value => setData({ ...data, salesOfficeName: value })}
          />
          <InputFormat
            label="電話番号※"
            placeholder="電話番号を入力"
            value={data.phoneNumber}
            size="short"
            maxLength={20}
            onChange={value => setData({ ...data, phoneNumber: value })}
            validations={[Rules.Required, Rules.PhoneNumber]}
            onValidate={setPhoneNumberValidity}
          />

          <div className="font-middle font-weight-bold py-1">事業所住所</div>
          <PostalcodeInputForm
            label="郵便番号※"
            value={data.postalCode}
            onChange={value => setData({ ...data, postalCode: value })}
            validations={[Rules.Required, Rules.PostalCode]}
            onValidate={setPostalCodeValidity}
            onAddressAutoInput={onAddressAutoInput}
            disabledAddressAutoInput={!foundPostalCode}
          />
          <SelectBoxFormat
            label="都道府県※"
            placeholder="都道府県を選択"
            value={data.prefecture}
            size="short"
            items={prefectureItems}
            onChange={e => setData({ ...data, prefecture: e.value })}
            validations={[Rules.Required]}
            onValidate={setPrefectureValidity}
          />
          <InputFormat
            label="市区町村※"
            placeholder="市区町村を入力"
            value={data.municipality}
            maxLength={100}
            onChange={value => setData({ ...data, municipality: value })}
            validations={[Rules.Required]}
            onValidate={setMunicipalityValidity}
          />
          <InputFormat
            label="それ以降の住所※"
            value={data.otherAddress}
            maxLength={100}
            onChange={value => setData({ ...data, otherAddress: value })}
            validations={[Rules.Required]}
            onValidate={setOtherAddressValidity}
          />

          <div className="font-middle font-weight-bold pt-3 pb-1">営業時間</div>
          <p>
            事業所の営業時間を設定してください。設定した営業時間内で予定を設定する事ができます。早朝の出勤や残業なども考慮して設定していただく事をお勧めします。
          </p>
          <FormGroup row>
            <Label md={4}>営業開始時間※</Label>
            <Col md={8}>
              <TimeSelect
                hour={data.businessStartHour}
                minute={data.businessStartMinute}
                label="から"
                onChange={(hour, minute) => setData({ ...data, businessStartHour: hour, businessStartMinute: minute })}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4}>営業終了時間※</Label>
            <Col md={8}>
              <TimeSelect
                hour={data.businessEndHour}
                minute={data.businessEndMinute}
                label="まで"
                invalid={!businessTimeValidity}
                onChange={(hour, minute) => setData({ ...data, businessEndHour: hour, businessEndMinute: minute })}
              />
              <div className={`invalid-feedback ${!businessTimeValidity && 'd-block'}`}>
                営業終了時間は営業開始時間より後の時間を設定してください
              </div>
            </Col>
          </FormGroup>

          <hr className="my-5"></hr>
          <div className="font-middle font-weight-bold py-1">担当者情報</div>
          <p>担当者は後で変更することもできます。</p>
          <SelectBoxFormat
            label="担当者※"
            placeholder="担当者を選択"
            value={data.personInCharge}
            size="short"
            items={personInChargeItems}
            onChange={e => setData({ ...data, personInCharge: e.key?.toString() || e.value })}
            validations={[Rules.Required]}
            onValidate={setPersonInChargeValidity}
          />
        </div>

        <SubmitFooter
          onCancel={() => history.goBack()}
          onSubmit={onSubmit}
          submitDisabled={disabled || unchanged}
          submitLabel="登録"
        />
      </div>
    </>
  )
}

export default TenantCreate
