import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ModalBody, ModalFooter, Button } from 'reactstrap'
import { logout, selectSessionStatus } from 'slices/sessionSlice'
import { close, selectSessionTimeoutDialogStatus } from 'slices/sessionTimeoutDialogSlice'
import { Modal } from 'components/common'

const SessionTimeoutDialog: React.FC = () => {
  const { show } = useSelector(selectSessionTimeoutDialogStatus)
  const { loggedIn } = useSelector(selectSessionStatus)
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const toLogin = () => {
    dispatch(close())
    dispatch(logout())
  }

  React.useEffect(() => {
    if (!loggedIn && !/^\/(login|signup|activate|password-reset)/.test(pathname)) {
      history.push('/login')
    }
  }, [pathname, loggedIn, history])

  return (
    <Modal isOpen={show} zIndex={3000}>
      <ModalBody className="font-large font-weight-bold">セッションタイムアウト</ModalBody>
      <ModalBody className="pb-4">無操作状態が続いたため、ログアウトしました。再度ログインしてください。</ModalBody>

      <ModalFooter className="d-flex justify-content-end">
        <Button color="primary" onClick={toLogin}>
          ログインページへ
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SessionTimeoutDialog
