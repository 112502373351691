import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { WorkspaceResponse } from 'api/workspaces'
import { getWorkerList } from 'slices/workersSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'
import { getSkillList } from 'slices/skillsSlice'
import { getGroupList } from 'slices/groupsSlice'
import { NavMenu } from 'components/common'
import WorkerList from './WorkerList'
import Shifts from './Shifts'
import Skills from './Skills'

const Workers: React.FC = () => {
  const [selectedWorkspace, setSelectedWorkspace] = React.useState<WorkspaceResponse | undefined>(undefined)
  const [date, setDate] = React.useState(moment().format('YYYY-MM-DD'))

  const { pathname } = useLocation()
  const { workspaces } = useSelector(selectWorkspacesStatus)

  const dispatch = useDispatch()
  const history = useHistory()

  React.useEffect(() => {
    if (selectedWorkspace || isEmpty(workspaces)) {
      return
    }
    if (!pathname.startsWith('/shifts')) {
      const pathWorkspaceId = pathname.split('/')[2]
      const target = workspaces.find(w => w.workspaceId.toString() === pathWorkspaceId)
      if (target) {
        setSelectedWorkspace(target)
        return
      }
    }
    setSelectedWorkspace(prev => {
      if (!prev) {
        return workspaces[0]
      }
    })
  }, [workspaces, selectedWorkspace, pathname])

  React.useEffect(() => {
    if (!pathname.startsWith('/shifts')) {
      dispatch(getSkillList())
    }
    if (!pathname.startsWith('/skills')) {
      dispatch(getWorkerList(false))
    }
    dispatch(getWorkspaceList())
  }, [dispatch, pathname])

  React.useEffect(() => {
    if (!pathname.startsWith('/shifts') || !selectedWorkspace?.workspaceId) {
      return
    }
    dispatch(getGroupList(selectedWorkspace.workspaceId))
  }, [dispatch, selectedWorkspace, pathname])

  const handleNavMenuClick = (type: string) => history.push(type)

  const menuItems = React.useMemo(
    () => [
      {
        type: '/workers',
        label: 'メンバー一覧',
      },
      {
        type: `/shifts/${selectedWorkspace?.workspaceId}/${date}`,
        label: 'シフト管理',
      },
      {
        type: '/skills',
        label: 'スキル管理',
      },
    ],
    [selectedWorkspace, date]
  )

  return (
    <>
      <NavMenu type={pathname} items={menuItems} onNavMenuClick={handleNavMenuClick}>
        <div className="mt-3 mx-3">
          {pathname === '/workers' ? (
            <WorkerList />
          ) : pathname.startsWith('/shifts') ? (
            <Shifts
              selectedWorkspace={selectedWorkspace}
              onWorkspaceChange={setSelectedWorkspace}
              date={date}
              onDateChange={setDate}
            />
          ) : (
            <Skills />
          )}
        </div>
      </NavMenu>
    </>
  )
}

export default Workers
