import React, { FC, ReactNode } from 'react'
import ReactModal from 'react-modal'
import './Modal.css'

type Props = {
  isOpen: boolean
  children: ReactNode
  zIndex?: number
  overflow?: string
}

const Modal: FC<Props> = ({ isOpen, children, zIndex, overflow }) => {
  const modalStyle = React.useMemo<ReactModal.Styles>(
    () => ({
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: zIndex ?? 2000,
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: 800,
        marginRight: '-50%',
        paddingBottom: 0,
        transform: 'translate(-50%, -50%)',
        overflow: overflow,
      },
    }),
    [zIndex, overflow]
  )

  return (
    <ReactModal isOpen={isOpen} style={modalStyle} ariaHideApp={false}>
      {children}
    </ReactModal>
  )
}

export default Modal
