import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showError, showSuccess, showWarning } from 'slices/notificationSlice'
import { clearErrorMessage, selectWorksStatus } from 'slices/worksSlice'

const useWorkNotification = () => {
  const [submitted, setSubmitted] = React.useState(false)
  const dispatch = useDispatch()
  const { isRequesting, errorMessage, scheduleTruncated } = useSelector(selectWorksStatus)
  React.useEffect(() => {
    if (isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      if (scheduleTruncated) {
        dispatch(
          showWarning({
            warningMessage:
              '正常に処理されました。シフト情報が更新されたため、作業計画に変更が加えられている場合があります。',
          })
        )
      } else {
        dispatch(showSuccess())
      }
    } else {
      dispatch(showError())
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [dispatch, errorMessage, isRequesting, submitted, scheduleTruncated])
  return { setSubmitted }
}
export default useWorkNotification
