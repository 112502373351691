import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Row } from 'reactstrap'
import { TenantStatus } from 'api/tenants'
import { getTenantList, selectTenantsStatus } from 'slices/tenantsSlice'
import { getUserList, selectUsersStatus } from 'slices/usersSlice'
import { NavMenu, Pagination, Table } from 'components/common'
import { TableHeaderType, TableCellType } from 'components/common/types'

const PER_PAGE_NUMBER = 20

const header: TableHeaderType[] = [
  { value: 'テナント ID', width: '10%' },
  { value: '企業名', width: '20%', className: 'border-right' },
  { value: '事業所名', width: '30%' },
  { value: 'ご利用状況', width: '10%' },
  { value: '担当者', width: '30%' },
]

const menuItems = [
  {
    type: 'tenantList',
    label: 'テナント一覧',
  },
]

const Tenants: React.FC = () => {
  const [menuType, setMenuType] = React.useState(menuItems[0].type)
  const [page, setPage] = React.useState(1)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getTenantList())
    dispatch(getUserList())
  }, [dispatch])

  const { tenants } = useSelector(selectTenantsStatus)
  const { users } = useSelector(selectUsersStatus)
  const tableData: TableCellType[][] = React.useMemo(() => {
    return tenants.map(tenant => [
      { value: tenant.tenantId.toString() },
      { value: tenant.name, className: 'border-right' },
      { value: tenant.salesOfficeName || '' },
      {
        value: tenant.status === TenantStatus.Active ? '利用中' : '利用停止中',
        className: tenant.status === TenantStatus.Active ? 'text-success' : 'text-danger',
      },
      { value: users.find(user => user.userId === tenant.personInCharge)?.name || '' },
    ])
  }, [tenants, users])
  const tableItems: TableCellType[][] = React.useMemo(() => {
    return tableData.slice((page - 1) * PER_PAGE_NUMBER, page * PER_PAGE_NUMBER)
  }, [page, tableData])
  const totalPages: number = React.useMemo(() => {
    return Math.ceil(tableData.length / PER_PAGE_NUMBER)
  }, [tableData])

  const history = useHistory()
  const onNewTenantClick = () => history.push('/tenant-create')
  const onRowClick = (index: number) => {
    const tenantId = tableItems[index][0].value
    history.push(`/tenants/${tenantId}/detail`)
  }

  return (
    <NavMenu type={menuType} items={menuItems} onNavMenuClick={setMenuType}>
      <div className="mt-3 mx-3">
        <div className="d-flex justify-content-between">
          <div className="font-x-large font-weight-bold align-self-center">テナント一覧</div>
          <div className="bg-white rounded">
            <Button color="primary" className="d-flex align-items-center" onClick={onNewTenantClick}>
              <i className="icf-plus pr-2 font-large" />
              <div className="pl-1">テナント新規登録</div>
            </Button>
          </div>
        </div>
        <Row className="py-3">
          <div className="bg-white">
            <Table header={header} body={tableItems} onRowClick={onRowClick} />
          </div>
          <div className="my-2 mx-auto">
            <Pagination totalPages={totalPages} currentPage={page} onClick={setPage} />
          </div>
        </Row>
      </div>
    </NavMenu>
  )
}

export default Tenants
