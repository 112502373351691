import * as React from 'react'
import moment from 'moment'
import { Card, CardBody } from 'reactstrap'
import Popup from 'reactjs-popup'
import { PopupActions } from 'reactjs-popup/dist/types'
import { SelectedScheduleType } from 'components/common/types'
import styles from './WorkerPopover.module.scss'

type Props = {
  children: JSX.Element
  startAt: string
  duration: number
  scheduleType: SelectedScheduleType
  disabled?: boolean
  offsetY?: number
  onClick: () => void
  onDelete?: () => void
  onOpen?: () => void
  onClose?: () => void
}

const WorkerPopover: React.FC<Props> = props => {
  const { children, startAt, duration, scheduleType, disabled, offsetY, onClick, onDelete, onOpen, onClose } = props

  const time = React.useMemo(() => {
    const start = moment(startAt).local().format('HH:mm')
    const end = moment(startAt).local().add(duration, 'seconds').format('HH:mm')
    return `${start}〜${end}`
  }, [duration, startAt])

  const handleDelete = () => {
    if (typeof onDelete === 'function') {
      onDelete()
    }
    ref.current?.close()
  }

  const onClickHandler = () => {
    !disabled && onClick()
  }

  const ref = React.useRef<PopupActions>(null)
  const trigger = <div className="mh-100">{children}</div>
  return (
    <Popup
      ref={ref}
      trigger={trigger}
      position="bottom center"
      contentStyle={{ width: '12.5rem', zIndex: 95 }}
      onOpen={onOpen}
      onClose={onClose}
      offsetY={offsetY}
    >
      <Card>
        <CardBody className="p-2 small">
          <div className="d-flex justify-content-end pb-2 font-large">
            {!disabled && onDelete && <i className="icf-delete" onClick={() => handleDelete()} />}
            <i className="icf-close pl-1" onClick={() => ref.current?.close()} />
          </div>

          <div onClick={onClickHandler} className="pb-2 d-flex align-items-center ">
            {scheduleType?.color && (
              <div>
                <div className={`${styles.square} bg-${scheduleType.color} mr-1`}></div>
              </div>
            )}
            <span className="flex-grow-1 text-truncate">{scheduleType.name}</span>
            <i className="icf-carot_right font-large" />
          </div>

          <div className="mb-1">{time}</div>
        </CardBody>
      </Card>
    </Popup>
  )
}

export default WorkerPopover
