import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { FormGroup, Label, Col } from 'reactstrap'
import { WorkerEditDataType } from 'api/workers'
import { CONFLICT_ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'
import { getGroupList, selectGroupsStatus } from 'slices/groupsSlice'
import { createWorker, selectWorkersStatus } from 'slices/workersSlice'
import { selectWorkspacesStatus, getWorkspaceList, clearErrorMessage } from 'slices/workspacesSlice'
import { getSkillList, selectSkillsStatus } from 'slices/skillsSlice'
import {
  CheckBoxFormat,
  InputFormat,
  Notification,
  SelectBoxFormat,
  DatePicker,
  SubmitFooter,
  ItemEdit,
} from 'components/common'
import { SuggestionItem } from 'components/common/types'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import useWorker from 'hooks/useWorker'
import styles from './WorkerCreate.module.scss'
import PerformanceIndicesInput from './PerformanceIndicesInput'
const WorkerCreate: React.FC = () => {
  const [groupSelectItems, setGroupSelectItems] = React.useState<Array<{ key: number; value: string }>>([])
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = React.useState(false)
  const history = useHistory()

  const { workspaces } = useSelector(selectWorkspacesStatus)
  const { groups } = useSelector(selectGroupsStatus)
  const { skills } = useSelector(selectSkillsStatus)

  const dispatch = useDispatch()

  const {
    showPerformanceIndices,
    disabled,
    editData,
    setEditData,
    setNameValidity,
    setWmsMemberIdValidity,
  } = useWorker()

  React.useEffect(() => {
    dispatch(getWorkspaceList())
    dispatch(getSkillList())
  }, [dispatch])

  React.useEffect(() => {
    if (workspaces.length === 0) {
      return
    }

    if (!_.isEmpty(editData.performanceIndices)) {
      return
    }
    const performanceIndices = workspaces
      .flatMap(workspace => workspace.scheduleTypes)
      .filter(scheduleType => scheduleType.dataConnection)
      .flatMap(scheduleType => ({
        scheduleTypeId: scheduleType.scheduleTypeId,
      }))
      .reduce((acc, value) => ({ ...acc, [value.scheduleTypeId]: undefined }), {})
    setEditData({ ...editData, performanceIndices })
  }, [setEditData, workspaces, editData])

  React.useEffect(() => setGroupSelectItems(groups.map(group => ({ key: group.groupId, value: group.name }))), [groups])

  React.useEffect(() => {
    if (editData.workspace) {
      dispatch(getGroupList(editData.workspace.key, false))
    } else {
      setGroupSelectItems([])
    }
  }, [dispatch, editData.workspace])

  const workspaceSelectItems = React.useMemo(
    () =>
      workspaces.map(workspace => ({
        key: workspace.workspaceId,
        value: workspace.name,
      })),
    [workspaces]
  )

  const onSubmit = () => {
    setSubmitted(true)

    const performanceIndices = Object.keys(editData.performanceIndices).map(key => ({
      scheduleTypeId: Number(key),
      index: Number(editData.performanceIndices[key]),
    }))

    const data: WorkerEditDataType = {
      name: editData.name || '',
      wmsMemberId: editData.wmsMemberId || '',
      workspaceId: editData.workspace?.key || 0,
      groupId: editData.group?.key || null,
      groupLeader: editData.groupLeader,
      hiredAt: editData.hiredAt || null,
      skillIds: editData.skills.map(skill => skill.skillId),
      performanceIndices,
    }
    dispatch(createWorker(data))
  }

  const { isRequesting, errorMessage } = useSelector(selectWorkersStatus)
  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      history.push('/workers')
    } else {
      if (errorMessage === CONFLICT_ERROR_STATUS_CODE) {
        setModalErrorMessage('ID・識別番号が重複しています。')
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
        setModalErrorMessage('保存できませんでした。')
      }
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, history])

  const handleSkillEdit = (items: SuggestionItem[]) => {
    const skillData = skills.filter(s => items.some(i => i.id === s.skillId))
    setEditData({ ...editData, skills: skillData })
  }

  const unchanged = React.useMemo(() => !editData.name || !editData.wmsMemberId, [editData])

  return (
    <>
      <div className={styles.container}>
        <div className="sticky-top">
          <div className="font-x-large font-weight-bold text-center border-bottom py-3 bg-white">メンバーの登録</div>
          <Notification
            errorMessage={modalErrorMessage}
            error={!!modalErrorMessage}
            hide={() => setModalErrorMessage(undefined)}
          />
        </div>
        <div className="w-50 mx-auto mt-3 pb-3">
          <div className="d-flex justify-content-between py-1">
            <div className="font-middle font-weight-bold">メンバー詳細</div>
            <small>※必須項目</small>
          </div>
          <InputFormat
            label="名前※"
            placeholder="メンバー名を入力"
            value={editData.name}
            size="middle"
            maxLength={100}
            onChange={value => setEditData({ ...editData, name: value.trim() })}
            validations={[Rules.Required]}
            onValidate={setNameValidity}
          />
          <InputFormat
            label="ID・識別番号※"
            placeholder="ID・識別番号を入力"
            formText="WMSにメンバー毎に設定されているIDを入力してください。データ連携の際に必要になります。"
            value={editData.wmsMemberId}
            size="middle"
            maxLength={100}
            onChange={value => setEditData({ ...editData, wmsMemberId: value.trim() })}
            validations={[Rules.Required]}
            onValidate={setWmsMemberIdValidity}
          />
          <FormGroup row>
            <Label for="hiredAt" md={4}>
              入社日
            </Label>
            <Col md={4} className="align-self-center">
              <DatePicker
                value={editData.hiredAt}
                placeholder="入社日を選択"
                onChange={date => setEditData({ ...editData, hiredAt: date })}
              />
            </Col>
          </FormGroup>

          <SelectBoxFormat
            label="所属ワークスペース"
            placeholder="ワークスペースを選択"
            formText="メンバーに予定を設定するためにはワークスペースに所属させてください。"
            value={editData.workspace?.key.toString()}
            size="middle"
            items={workspaceSelectItems}
            onChange={e => {
              const workspace = workspaceSelectItems.find(w => w.key.toString() === e.key?.toString())
              setEditData({ ...editData, workspace, group: undefined, groupLeader: false })
            }}
          />
          {groupSelectItems.length > 0 && (
            <>
              <SelectBoxFormat
                label="所属グループ"
                placeholder="グループを選択"
                value={editData.group?.key.toString()}
                size="middle"
                items={groupSelectItems}
                onChange={e => {
                  const group = groupSelectItems.find(g => g.key.toString() === e.key?.toString())
                  setEditData({ ...editData, group })
                }}
              />
              {editData.group && (
                <CheckBoxFormat
                  label="グループリーダー"
                  checkboxLabel="リーダー"
                  checked={editData.groupLeader}
                  onChange={e => setEditData({ ...editData, groupLeader: e.target.checked })}
                />
              )}
            </>
          )}

          <div className="font-middle font-weight-bold pt-5">スキル設定</div>
          <div className="py-2">
            メンバーにスキルを設定する事ができます。新しいスキルは<Link to="/skills">スキル管理</Link>
            で登録してください。
          </div>
          <ItemEdit
            items={skills.map(s => ({ id: s.skillId, value: s.name }))}
            selectedItems={editData.skills.map(s => ({ id: s.skillId, value: s.name }))}
            label="メンバーにスキルを追加"
            itemName="スキル"
            onChange={handleSkillEdit}
          />
          {showPerformanceIndices && (
            <div>
              <div className="font-middle font-weight-bold pt-5">人時生産性設定</div>
              <div className="py-2">
                全てのワークスペースに登録されている作業に対する人時生産性を設定する事ができます。所属ワークスペース以外の作業についても人時生産性を設定しておく事でワークスペース間を移動して作業した際にも人時生産性を計算する事ができます。
              </div>
              <PerformanceIndicesInput
                performanceIndices={editData.performanceIndices}
                onChange={value => {
                  setEditData({ ...editData, performanceIndices: value })
                }}
              />
            </div>
          )}
        </div>
      </div>
      <SubmitFooter onCancel={() => history.goBack()} onSubmit={onSubmit} submitDisabled={disabled || unchanged} />
    </>
  )
}

export default WorkerCreate
