import moment from 'moment'
import * as React from 'react'
import { ModalBody, ModalFooter, Button, CardBody, CardText, FormGroup, Label, Col } from 'reactstrap'
import { Modal, TimeSelect } from 'components/common'
import { SHIFT_SCHEDULE_TYPE_ID } from 'components/common/utils'
import { EditGroupsType, WorkPlanSchedulesType } from '../types'

export const deleteWorkerSchedules = (startAt: string, duration: number, schedules: WorkPlanSchedulesType[]) => {
  const deleteStart = moment(startAt)
  const deleteEnd = moment(startAt).add(duration, 'seconds')

  const shifts = schedules.filter(s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID)
  const updatedSchedules = schedules
    .filter(s => s.scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID)
    .reduce<WorkPlanSchedulesType[]>((acc, cur) => {
      const curstart = moment(cur.startAt)
      const curend = moment(cur.startAt).add(cur.duration, 'seconds')

      if (
        deleteStart.isBetween(curstart, curend, 'minute', '()') &&
        deleteEnd.isBetween(curstart, curend, 'minute', '()')
      ) {
        // current schedule の中に delete 時間が全て含まれているとき
        // current schedule を分割
        acc.push({ ...cur, duration: cur.duration - (curend.unix() - deleteStart.unix()) })
        acc.push({
          ...cur,
          scheduleId: Math.random(),
          startAt: deleteEnd.format(),
          duration: cur.duration - (deleteEnd.unix() - curstart.unix()),
        })
      } else if (deleteStart.isBetween(curstart, curend, 'minute', '(]')) {
        // current schedule の中に delete の開始時間のみが含まれているとき
        // current schedule の後半部分を削除
        const diff = curend.unix() - deleteStart.unix()
        acc.push({ ...cur, duration: cur.duration - diff })
      } else if (deleteEnd.isBetween(curstart, curend, 'minute', '[)')) {
        // current schedule の中に delete の終了時間のみが含まれているとき
        // current schedule の前半部分を削除
        const diff = deleteEnd.unix() - curstart.unix()
        acc.push({ ...cur, startAt: deleteEnd.format(), duration: cur.duration - diff })
      } else if (
        curstart.isBetween(deleteStart, deleteEnd, 'minute', '[]') &&
        curend.isBetween(deleteStart, deleteEnd, 'minute', '[]')
      ) {
        // delete 時間の中に current schedule が含まれているとき
        // current schedule は全て削除
      } else {
        // current schedule と delete 時間の重複がない場合はそのまま返す
        acc.push(cur)
      }
      return acc
    }, shifts)

  return updatedSchedules
}

type Props = {
  isOpen: boolean
  workerIds: number[]
  editGroups: EditGroupsType[]
  workDate?: string
  setEditGroups: (items: EditGroupsType[]) => void
  onCancel: () => void
}

const DeleteSchedules: React.FC<Props> = props => {
  const { isOpen, workerIds, editGroups, workDate, setEditGroups, onCancel } = props
  const [validTime, setValidTime] = React.useState(true)
  // 直後の予定開始時刻を設定
  const [startHour, setStartHour] = React.useState('00')
  const [startMinute, setStartMinute] = React.useState('00')
  // 直後の予定開始時刻の１時間後を設定
  const [endHour, setEndHour] = React.useState('00')
  const [endMinute, setEndMinute] = React.useState('00')

  const getRoundedTime = () => {
    const currentTime = moment().format('HH:mm')
    const minutes = moment(currentTime, 'HH:mm').minutes()
    if (0 <= minutes && minutes < 15) {
      return moment(currentTime, 'HH:mm').minutes(15).format('HH:mm')
    } else if (15 <= minutes && minutes < 30) {
      return moment(currentTime, 'HH:mm').minutes(30).format('HH:mm')
    } else if (30 <= minutes && minutes < 45) {
      return moment(currentTime, 'HH:mm').minutes(45).format('HH:mm')
    }
    return moment(currentTime, 'HH:mm').add(1, 'h').minutes(0).format('HH:mm')
  }

  React.useEffect(() => {
    const roundedTime = getRoundedTime()
    setValidTime(true)
    setStartHour(('00' + moment(roundedTime, 'HH:mm').hours()).slice(-2))
    setStartMinute(('00' + moment(roundedTime, 'HH:mm').minutes()).slice(-2))
    setEndHour(('00' + moment(roundedTime, 'HH:mm').add(1, 'hours').hours()).slice(-2))
    setEndMinute(('00' + moment(roundedTime, 'HH:mm').add(1, 'hours').minutes()).slice(-2))
  }, [isOpen])

  const onDeleteClick = () => {
    // 予定を削除ボタンを押した時の処理
    // 開始時間が終了時関より前
    const valid = moment(`${startHour}:${startMinute}`, 'HH:mm').isBefore(moment(`${endHour}:${endMinute}`, 'HH:mm'))
    setValidTime(valid)

    if (valid) {
      // 時刻判定が valid だった時該当予定を削除する
      const startAt = moment(`${workDate} ${startHour}:${startMinute}`, 'YYYY-MM-DD HH:mm').utc().format()
      const duration =
        moment(`${workDate} ${endHour}:${endMinute}`, 'YYYY-MM-DD HH:mm').utc().unix() - moment(startAt).unix()
      const newEditschedules = editGroups.map<EditGroupsType>(group => {
        const workers = group.workers.map(groupWorker => {
          // 選択されてないworkerの場合は除外
          if (!workerIds.includes(groupWorker.workerId)) {
            return groupWorker
          }
          const schedules = deleteWorkerSchedules(startAt, duration, groupWorker.schedules)
          return { ...groupWorker, schedules }
        })
        return { ...group, schedules: group.schedules, workers }
      })
      setEditGroups(newEditschedules)
      onCancel()
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalBody className="font-large font-weight-bold">予定の一括削除</ModalBody>
      <ModalBody className="font-small">
        <CardBody>
          <CardText>
            選択されているメンバーに入力されている予定を一括削除します。保存するまで反映はされません。
          </CardText>
        </CardBody>
        <CardBody className="py-0">
          <FormGroup row>
            <Label md={3}>予定一括削除対象時間</Label>
            <Col md={8}>
              <TimeSelect
                hour={startHour}
                minute={startMinute}
                label="から"
                onChange={(hour, minute) => {
                  setStartHour(hour)
                  setStartMinute(minute)
                }}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3}></Label>
            <Col md={8}>
              <TimeSelect
                hour={endHour}
                minute={endMinute}
                label="まで"
                onChange={(hour, minute) => {
                  setEndHour(hour)
                  setEndMinute(minute)
                }}
              />
            </Col>
          </FormGroup>
          {!validTime && <CardText className="text-danger">終了時刻は開始時刻より後の時刻を設定してください</CardText>}
        </CardBody>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-between">
        <Button outline onClick={onCancel}>
          キャンセル
        </Button>
        <Button color="primary" className="px-4" onClick={onDeleteClick}>
          予定を削除
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteSchedules
