import _ from 'lodash'
import React from 'react'
import { CustomInput, Button } from 'reactstrap'
import moment from 'moment/moment'
import { useDispatch, useSelector } from 'react-redux'
import { ExportDataType, ExportDataTypes } from 'api/tenants'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'
import { getExportDataUrl, resetExportDataUrl, selectTenantsStatus } from 'slices/tenantsSlice'
import { showError } from 'slices/notificationSlice'
import { CustomModal, SingleDateRangePicker, ItemEdit } from 'components/common'
import { SuggestionItem } from 'components/common/types'
import SelectBox from 'components/common/FormFormat/SelectBox'
import { downloadByURL } from 'components/common/utils'

type Props = {
  open: boolean
  setOpen: (prop: boolean) => void
  currentWorkspaceId: number | undefined
}

type ExportDataItemType = { key: ExportDataType; value: string }

const DATE_PICKER_MAX_DAYS = 31

const exportDataItem: ExportDataItemType[] = [
  { key: ExportDataTypes.PlanAndRecord, value: '作業計画 + 実績' },
  { key: ExportDataTypes.PlanDetail, value: '作業計画 (詳細)' },
]

const onDetailClick = () => window.open('https://help.smileboard.jp/export-to-csv', '_blank')

const CsvExportDialog: React.FC<Props> = ({ open, setOpen, currentWorkspaceId }) => {
  const [containProductivityAdjustment, setContainProductivityAdjustment] = React.useState(true)
  const [openRangeDatePicker, setOpenRangeDatePicker] = React.useState(false)
  const [selectedWorkspaces, setSelectedWorkspaces] = React.useState<SuggestionItem[]>([])
  const [period, setPeriod] = React.useState<{ start: Date; end: Date }>()
  const [exportDataType, setExportDataType] = React.useState(exportDataItem[0])
  const dispatch = useDispatch()
  const { workspaces } = useSelector(selectWorkspacesStatus)
  const { downloadUrl } = useSelector(selectTenantsStatus)

  React.useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  const initExportSettings = React.useCallback(() => {
    if (!currentWorkspaceId) {
      return
    }
    const target = workspaces.find(workspace => workspace.workspaceId === currentWorkspaceId)
    if (target) {
      setSelectedWorkspaces([{ id: currentWorkspaceId, value: target.name }])
    }
    setPeriod(undefined)
    setContainProductivityAdjustment(true)
    setExportDataType(exportDataItem[0])
  }, [workspaces, currentWorkspaceId])

  // ダイアログを開くたびに、設定を初期化する
  React.useEffect(() => {
    initExportSettings()
  }, [initExportSettings, open])

  const onApprove = () => {
    if (!period || _.isEmpty(selectedWorkspaces)) {
      return
    }
    const targetWorkspaces: number[] = selectedWorkspaces.map(w => (_.isNumber(w.id) ? w.id : Number(w.id)))
    dispatch(
      getExportDataUrl({
        startDate: moment(period.start).format('YYYY-MM-DD'),
        endDate: moment(period.end).format('YYYY-MM-DD'),
        targetWorkspaces,
        includeProductivityValue: containProductivityAdjustment,
        exportDataType: exportDataType.key,
      })
    )
  }

  const downloadCsv = React.useCallback(
    async (url: string, filename: string) => {
      try {
        await downloadByURL(url, filename)
      } catch {
        dispatch(showError())
      } finally {
        setOpen(false)
        dispatch(resetExportDataUrl())
      }
    },
    [setOpen, dispatch]
  )

  React.useEffect(() => {
    if (!downloadUrl) {
      return
    }

    const filename = `archive-${moment().format('YYYY-MM-DD-HHmm')}.csv`
    downloadCsv(downloadUrl, filename)
  }, [downloadUrl, downloadCsv])

  const disabled = React.useMemo(() => !period || _.isEmpty(selectedWorkspaces), [period, selectedWorkspaces])

  return (
    <div>
      <CustomModal
        isOpen={open}
        title="CSVエクスポート"
        onCancel={() => setOpen(false)}
        onHideNotification={() => {}}
        approveLabel="CSVエクスポート"
        onApprove={onApprove}
        approveDisabled={disabled}
        submitName="csv-export-dialog-submit"
      >
        <div className="ml-2">
          <div className="mb-4">
            <div className="my-2 d-flex justify-content-between align-items-center">
              <div className="font-weight-bold">エクスポートデータ選択</div>
              <Button color="link" onClick={onDetailClick}>
                エクスポートデータについて
              </Button>
            </div>
            <SelectBox
              id="export-data-type"
              value={exportDataType.value}
              items={exportDataItem}
              onSelect={item => setExportDataType(item as ExportDataItemType)}
              className="w-50 mb-4"
            />
            <div className="my-2 font-weight-bold">ワークスペース選択</div>
            <div className="mb-2">他ワークスペースもまとめてエクスポートできます｡ワークスペースを選択してください｡</div>
            <ItemEdit
              items={workspaces.map(w => ({ id: w.workspaceId, value: w.name }))}
              selectedItems={selectedWorkspaces}
              label="ワークスペースを追加"
              itemName="ワークスペース"
              onChange={setSelectedWorkspaces}
            />
          </div>
          <div className="mb-4">
            <div className="my-2 font-weight-bold">エクスポート期間設定</div>
            <div className="mb-2">最大31日分エクスポートが可能です｡</div>
            <div className="d-flex">
              <Button outline onClick={() => setOpenRangeDatePicker(true)}>
                {period
                  ? `${moment(period.start).format('YYYY/MM/DD')} - ${moment(period.end).format('YYYY/MM/DD')}`
                  : '開始日と終了日を決定'}
              </Button>
              <SingleDateRangePicker
                isOpen={openRangeDatePicker}
                from={period && period.start}
                to={period && period.end}
                maxRange={DATE_PICKER_MAX_DAYS}
                onCancel={() => setOpenRangeDatePicker(false)}
                onChange={(start, end) => {
                  setPeriod({ start, end })
                  setOpenRangeDatePicker(false)
                }}
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="my-2 font-weight-bold">その他の設定</div>
            <CustomInput
              id="contain-productivity-adjustment"
              label="計画からの予測に生産性調整を含む"
              className="mt-2"
              checked={containProductivityAdjustment}
              type="checkbox"
              onChange={e => setContainProductivityAdjustment(e.target.checked)}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

export default CsvExportDialog
