import _ from 'lodash'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { WorkResultsType } from 'api/work_results'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'
import { TimeScale } from 'components/common'
import ManualInputRow from './ManualInputRow'
import styles from './ManualInputDialog.module.scss'

type Props = {
  value: WorkResultsType[]
  onChange: React.Dispatch<React.SetStateAction<WorkResultsType[]>>
  menuType: string
}

const UNCHANGED_VALUE = -1

const ManualInputEditTable: React.FC<Props> = ({ value, onChange, menuType }) => {
  const [open, setOpen] = React.useState<number[]>([])
  const { workspaces } = useSelector(selectWorkspacesStatus)

  React.useEffect(() => {
    if (menuType !== 'summary') {
      setOpen([Number(menuType)])
    }
  }, [menuType])

  const data = React.useMemo(
    () =>
      workspaces
        .map(w => {
          const scheduleTypes = w.scheduleTypes
            .filter(s => value.some(v => v.scheduleTypeId === s.scheduleTypeId))
            .map(s => {
              const target = value?.find(wr => wr.scheduleTypeId === s.scheduleTypeId)
              const hourlyValue = target?.hourlyValue || []
              const sumHourlyValue = hourlyValue.reduce((acc, cur) => (cur === UNCHANGED_VALUE ? acc : acc + cur), 0)
              return { ...s, hourlyValue, sumHourlyValue }
            })
          return {
            ...w,
            scheduleTypes,
          }
        })
        .filter(w => !_.isEmpty(w.scheduleTypes)),
    [workspaces, value]
  )

  const handleWorkspaceRowClick = (workspaceId: number) => {
    setOpen(prev => {
      if (prev.includes(workspaceId)) {
        return prev.filter(id => id !== workspaceId)
      }
      return prev.concat(workspaceId)
    })
  }

  const handleManualInputChange = (newValue: number[], scheduleTypeId: number) => {
    onChange(prev => [...prev?.map(p => (p.scheduleTypeId === scheduleTypeId ? { ...p, hourlyValue: newValue } : p))])
  }

  return (
    <table className="border-bottom border-secondary-pale">
      <thead>
        <tr className="sticky-top">
          <td className={`pl-4 ${styles.header}`}>作業</td>
          <td>
            <TimeScale round />
          </td>
          <td className={styles.sumHeader}>合計</td>
        </tr>
      </thead>

      {data.map(({ workspaceId, name, scheduleTypes }, index) => (
        <tbody key={`group-${workspaceId}-${index}`}>
          <tr className={styles.workspaceRow}>
            <td className={styles.workspaceCol}>
              <div className="d-flex align-items-center px-4" onClick={() => handleWorkspaceRowClick(workspaceId)}>
                <i className={`icf-carot_${open.includes(workspaceId) ? 'down' : 'right'} mr-1`} />
                <div className="text-truncate">{name}</div>
              </div>
            </td>
          </tr>
          {open.includes(workspaceId) &&
            _.sortBy(scheduleTypes, 'scheduleTypeId').map((scheduleType, i) => (
              <tr key={`worker-${scheduleType.scheduleTypeId}-${i}`} className={styles.scheduleRow}>
                <td className={styles.scheduleCol}>
                  <div className="d-flex align-items-center">
                    <div className="text-truncate mr-auto">{scheduleType.name}</div>
                  </div>
                </td>

                <td className={styles.scheduleEdit}>
                  <ManualInputRow
                    id={scheduleType.scheduleTypeId.toString()}
                    items={scheduleType.hourlyValue}
                    onChange={val => handleManualInputChange(val, scheduleType.scheduleTypeId)}
                  />
                </td>
                <td className={styles.scheduleSumCol}>{scheduleType.sumHourlyValue}</td>
              </tr>
            ))}
        </tbody>
      ))}
    </table>
  )
}

export default ManualInputEditTable
