import * as React from 'react'
import _ from 'lodash'
import moment from 'moment/moment'
import { ModalBody, ModalFooter, Button, CardBody, CardText, CardLink } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getTemplateData, getTemplateList, selectWorkspacesStatus } from 'slices/workspacesSlice'
import { EditGroupsType, WorkPlanSchedulesType } from 'components/Schedules/types'
import { Modal, SelectBoxFormat } from 'components/common'
import { SHIFT_SCHEDULE_TYPE_ID } from 'components/common/utils'
import styles from './ImportTemplateDialog.module.scss'

type Props = {
  isOpen: boolean
  workspaceId: number
  editGroups: EditGroupsType[]
  workerIds: number[]
  setEditGroups: (items: EditGroupsType[]) => void
  onSuccess: () => void
  onCancel: () => void
}

const convertWorkSchedulesToTemplateSchedule = (schedules: WorkPlanSchedulesType[], isShift: boolean) =>
  _.chain(schedules)
    .filter(s => isShift || s.scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID)
    .sortBy(o => moment(o.startAt).unix())
    .reverse() // 作業が重なっている場合､開始時間が早い作業を優先する(遅い時間から処理する)
    .reduce((acc: (WorkPlanSchedulesType | null)[], cur) => {
      const startIndex = (moment(cur.startAt).local().unix() - moment().startOf('day').unix()) / 900
      const endIndex = cur.duration / 900 + startIndex
      return _.fill(acc, cur, startIndex, endIndex)
    }, Array(96).fill(null))
    .valueOf()

const mergeSchedules = (
  workerSchedules: WorkPlanSchedulesType[],
  templateSchedules: (number | null)[]
): WorkPlanSchedulesType[] => {
  const shiftSchedules = workerSchedules.filter(s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID)
  if (_.isEmpty(shiftSchedules)) {
    return workerSchedules
  }
  const convertedWorkShiftSchedules = convertWorkSchedulesToTemplateSchedule(shiftSchedules, true)
  const targetDate = moment(_.head(shiftSchedules)!.startAt).startOf('day')
  const convertedWorkSchedules = convertWorkSchedulesToTemplateSchedule(workerSchedules, false)
  // テンプレートスケジュールとワーカースケジュールを96の配列にマージする
  const mergedWorkSchedules = convertedWorkSchedules.map((schedule, index) => {
    if (schedule !== null) {
      return schedule
    }
    const target = templateSchedules[index]
    if (convertedWorkShiftSchedules[index] === null || target === null) {
      return null
    }

    return {
      scheduleId: Math.random(),
      scheduleTypeId: target,
      supportWorkspaceId: null,
      supportWorkspaceName: null,
      startAt: '',
      duration: 0,
      editable: true,
    }
  })

  const workPlanSchedules = mergedWorkSchedules
    // マージされたスケジュールをWorkPlanSchedulesTypeに変換する
    .reduce<(WorkPlanSchedulesType | null)[]>((acc, cur, index) => {
      const prev = _.last(acc)

      if (cur === null) {
        return acc.concat(null)
      }
      if (!prev || prev.scheduleTypeId !== cur.scheduleTypeId) {
        return acc.concat({
          ...cur,
          duration: 900,
          startAt: targetDate
            .clone()
            .add(index * 900, 's')
            .format(),
        })
      }
      acc.splice(acc.length - 1, 1, { ...prev, duration: prev.duration + 900 })
      return acc
    }, [])
    .filter(wp => wp !== null) as WorkPlanSchedulesType[]

  return workPlanSchedules.concat(shiftSchedules)
}

const ImportTemplateDialog: React.FC<Props> = props => {
  const { isOpen, workspaceId, workerIds, editGroups, setEditGroups, onCancel, onSuccess } = props
  const [selectedTemplateId, setSelectedTemplateId] = React.useState<number>()
  const [submitted, setSubmitted] = React.useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const { templateList, templateData, isRequesting } = useSelector(selectWorkspacesStatus)

  React.useEffect(() => {
    isOpen && dispatch(getTemplateList(workspaceId))
  }, [dispatch, workspaceId, isOpen])

  React.useEffect(() => {
    setSelectedTemplateId(undefined)
  }, [isOpen])

  const items = React.useMemo(
    () =>
      templateList?.templates.map(template => ({
        key: template.id,
        value: template.name,
      })) || [],
    [templateList]
  )

  const isEmpty = React.useMemo(() => _.isEmpty(templateList?.templates), [templateList])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    const newEditGroups = editGroups.map<EditGroupsType>(edit => {
      const workers = edit.workers.map(worker => {
        if (!workerIds.includes(worker.workerId) || !templateData) {
          return worker
        }
        const schedules = mergeSchedules(worker.schedules, templateData.schedules)
        return { ...worker, schedules }
      })
      return { ...edit, workers }
    })

    setEditGroups(newEditGroups)
    onSuccess()
    setSubmitted(false)
  }, [templateData, submitted, isRequesting, onSuccess, setEditGroups, editGroups, workerIds])

  return (
    <Modal isOpen={isOpen}>
      <ModalBody className="font-large font-weight-bold">予定テンプレートを入力</ModalBody>
      <ModalBody className="font-small">
        <CardBody>
          <CardText>
            選択されているメンバーに登録されている予定テンプレートを入力します。既に入力されている予定がある場合は､既に入力されている予定が優先されます｡
          </CardText>
        </CardBody>
        <CardBody className="py-0">
          <SelectBoxFormat
            label="予定テンプレート"
            value={selectedTemplateId?.toString()}
            size="middle"
            items={items}
            onChange={event => {
              setSelectedTemplateId(Number(event.key))
              dispatch(getTemplateData(workspaceId, event.key as number))
            }}
            disabled={isEmpty}
          />
        </CardBody>
        {isEmpty && (
          <div>
            <CardBody>
              <CardText className="text-danger">予定テンプレートが登録されていません｡</CardText>
              <CardText>
                予定テンプレートはワークスペース管理&gt;該当ワークスペース&gt;
                <CardLink className={styles.link} onClick={() => history.push(`/workspaces/${workspaceId}/templates`)}>
                  予定テンプレート管理
                </CardLink>
                から登録できます｡
              </CardText>
            </CardBody>
          </div>
        )}
      </ModalBody>

      <ModalFooter className="d-flex justify-content-between">
        <Button outline onClick={onCancel}>
          キャンセル
        </Button>
        <Button color="primary" className="px-4" onClick={() => setSubmitted(true)} disabled={isEmpty}>
          予定テンプレートを入力
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ImportTemplateDialog
