import axios from 'axios'
import { CommonParams } from 'slices/utils'
import { ColorType } from 'components/common/types'

export type TenantSummaryResponse = {
  workspaceData: TenantWorkspaceData[]
  hourlyWorkData: TenantHourlyWorkData[]
}

export type TenantWorkspaceData = {
  workspaceId: number
  workspaceName: string
  data: TenantScheduleTypeRow[]
}

export type TenantScheduleTypeRow = {
  scheduleTypeId: number
  scheduleTypeName: string
  unit: string
  targetCount: number | null
  planCount: number | null
  planCountQuarterHourAgo: number | null
  planningHour: number | null
  todayPlanningHour: number | null
  recordCount: number | null
  recordCountQuarterHourAgo: number | null
  todayPlanCount: number | null
  lastImportedAt: string
}

export type TenantHourlyWorkData = {
  workspaceName: string
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  unit: string
  lastImportedAt: string
  data: TenantHourlyWorkRow[]
}

export type TenantHourlyWorkRow = {
  time: string
  planCount: number
  recordCount: number | null
  cumulativePlanCount: number
  cumulativeRecordCount: number | null
}

export type WorkspaceSummaryResponse = {
  workspaceData: WorkspaceSummaryData
  relatedWorkspaceData: WorkspaceSummaryData[]
}

export type WorkspaceSummaryData = {
  workspaceId: number
  workspaceName: string
  targets: WorkspaceSummaryTarget[]
  groups: WorkspaceSummaryGroup[]
}

export type WorkspaceSummaryTarget = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  unit: string
  targetCount: number | null
}

export type WorkspaceSummaryGroup = {
  groupId: number | null
  groupName: string | null
  workers: WorkspaceSummaryWorker[]
}

export type WorkspaceSummaryWorker = {
  workerId: number
  workerName: string
  workerWmsMemberId: string
  hourlyWorkerData: WorkspaceSummaryHourlyWorkerData[]
}

export type WorkspaceSummaryHourlyWorkerData = {
  scheduleTypeId: number
  scheduleTypeName: string
  scheduleTypeColor: ColorType
  unit: string
  lastImportedAt: string
  data: HourlyWorkerRow[]
}

export type HourlyWorkerRow = {
  time: string
  planCount: number | null
  recordCount: number | null
}

export type TenantSummaryFilteringData = {
  dashboardFilter?: boolean
  workspaceId?: number
  scheduleTypeId?: number
}

export const getTenantSummary = (
  params: CommonParams,
  date: string,
  data: TenantSummaryFilteringData
): Promise<TenantSummaryResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/work-date/${date}/summary`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: data })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getWorkspaceSummary = (params: CommonParams, workspaceId: number, date: string): Promise<WorkspaceSummaryResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/work-date/${date}/workspace-summary`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
