import * as React from 'react'
import styles from './styles.module.scss'

type Props = {
  successMessage?: string
  errorMessage?: string
  warningMessage?: string
  success?: boolean
  error?: boolean
  warning?: boolean
  className?: string
  hide: () => void
}

const Notification: React.FC<Props> = ({
  success = false,
  error = false,
  warning = false,
  successMessage = '正常に処理されました。',
  errorMessage = '正常に処理できませんでした。',
  warningMessage = '正常に処理できませんでした。',
  className = '',
  hide,
}) => {
  const [visible, setVisible] = React.useState(false)
  const [notificationType, setNotificationType] = React.useState<'success' | 'error' | 'warning'>('success')

  React.useEffect(() => {
    if (success || error || warning) {
      setVisible(true)
      const timeoutId1 = setTimeout(() => hide(), 5000)
      // transition-duration経過後にinvisibleにする
      const timeoutId2 = setTimeout(() => setVisible(false), 5500)
      return () => {
        clearTimeout(timeoutId1)
        clearTimeout(timeoutId2)
      }
    }
  }, [success, error, warning, hide])

  React.useEffect(() => {
    if (success) {
      setNotificationType('success')
    }
  }, [success])
  React.useEffect(() => {
    if (error) {
      setNotificationType('error')
    }
  }, [error])
  React.useEffect(() => {
    if (warning) {
      setNotificationType('warning')
    }
  }, [warning])

  const notification = React.useMemo(() => {
    switch (notificationType) {
      case 'success':
        return (
          <div className={[styles.container, className].join(' ')}>
            <div className={styles.success} data-show={success}>
              <i className="icf-check mr-2 font-large" />
              {successMessage}
            </div>
          </div>
        )
      case 'error':
        return (
          <div className={[styles.container, className].join(' ')}>
            <div className={styles.error} data-show={error}>
              <i className="icf-alert mr-2 font-large" />
              {errorMessage}
            </div>
          </div>
        )
      case 'warning':
        return (
          <div className={[styles.container, className].join(' ')}>
            <div className={styles.warning} data-show={warning}>
              <i className="icf-alert mr-2 font-large" />
              {warningMessage}
            </div>
          </div>
        )
    }
  }, [notificationType, successMessage, errorMessage, warningMessage, success, error, warning, className])

  return <div className={`${styles.notification} ${visible ? 'visible' : 'invisible'}`}>{notification}</div>
}
export default Notification
