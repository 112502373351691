import * as React from 'react'
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap'
import { Notification } from 'components/common'
import styles from './styles.module.scss'
import logo from './logo.svg'

type Props = {
  showTitle?: boolean
  subtitle?: string
  image?: string
  title?: string
  errorMessage?: string
  onHideNotification?: () => void
  children: React.ReactNode
}

const SinglePageCard: React.FC<Props> = (props: Props) => {
  return (
    <>
      <Notification
        className={styles.notification}
        errorMessage={props.errorMessage}
        error={!!props.errorMessage}
        hide={() => props.onHideNotification?.()}
      />

      <div className="d-flex vh-100 align-items-center justify-content-center">
        <div className={styles.card}>
          <Card>
            {props.showTitle ? (
              <CardBody className="text-center">
                <CardTitle>
                  <img src={logo} alt="Smile Board Connect" className={styles.logoImage} />
                </CardTitle>
              </CardBody>
            ) : null}
            {props.image ? <img className={styles.image} src={props.image} alt="" /> : null}
            {props.title ? <div className={styles.svgBackground}>{props.title}</div> : null}
            {props.subtitle ? (
              <CardBody className="text-center">
                <CardSubtitle tag="h2" className="font-weight-bold">
                  {props.subtitle}
                </CardSubtitle>
              </CardBody>
            ) : null}
            <CardBody>{props.children}</CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

SinglePageCard.defaultProps = {
  showTitle: true,
}

export default SinglePageCard
