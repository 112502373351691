import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Col, Row, Button } from 'reactstrap'
import { SinglePageCard } from 'components/common'
import styles from './styles.module.scss'

const PasswordResetComplete: React.FC = () => {
  const history = useHistory()

  return (
    <SinglePageCard subtitle="パスワードの再設定に成功しました">
      <Container>
        <Row>
          <Col className="text-center">
            スマイルボードアカウントのパスワードが正常に再設定されました。
          </Col>
        </Row>

        <Row>
          <Col className="text-center mt-3">
            <Button color="primary" className={styles.button} onClick={ () => history.push('/login') }>
              ログインページへ
            </Button>
          </Col>
        </Row>
      </Container>
    </SinglePageCard>
  )
}

export default PasswordResetComplete
