import * as React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import styles from './WorkerFilter.module.scss'
import { FilterItem } from 'components/common/types'
import { CheckboxGroup } from 'components/common'

type Props = {
  filterItems: FilterItem[]
  onChange: (filterItems: FilterItem[]) => void
}

const WorkerFilter: React.FC<Props> = ({ filterItems, onChange }) => {
  const [openFilter, setOpenFilter] = React.useState(false)
  const [popupStyle, setPopupStyle] = React.useState({ display: 'none' })

  React.useEffect(() => {
    if (openFilter) {
      setPopupStyle({ display: 'block' })
    } else {
      setPopupStyle({ display: 'none' })
    }
  }, [openFilter])

  return (
    <OutsideClickHandler onOutsideClick={() => setOpenFilter(false)} disabled={!openFilter}>
      <div
        className={[
          styles.filterToggle,
          'position-relative',
          'd-flex',
          'align-items-center',
          'justify-content-center',
          'border',
          'border-secondary',
          'text-secondary',
          'rounded',
        ].join(' ')}
        role="button"
        onClick={() => setOpenFilter(!openFilter)}
      >
        <i className="icf-filter font-large" />

        <div
          className={[
            styles.popup,
            'position-absolute',
            'border',
            'border-light-gray',
            'text-black',
            'bg-white',
            'rounded',
          ].join(' ')}
          style={popupStyle}
          onClick={e => {
            e.stopPropagation() // 上にclickイベントが伝播してpopupが閉じるのを防ぐ
          }}
        >
          <CheckboxGroup filterItems={filterItems} onChange={onChange} />
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default WorkerFilter
