import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { Badge, Button, DropdownItem } from 'reactstrap'
import { TenantStatus } from 'api/tenants'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'
import { showError, showSuccess } from 'slices/notificationSlice'
import { getTenant, updateTenantStatus, selectTenantsStatus, clearErrorMessage } from 'slices/tenantsSlice'
import { DropdownList, NavMenu, PivotItem, PivotOuterIndex } from 'components/common'
import TenantInformation from 'components/Tenants/TenantInformation'
import TenantUsers from 'components/Tenants/TenantUsers'
import TenantServiceCooperation from 'components/Tenants/TenantServiceCooperation/TenantServiceCooperation'
import UserInvitation from 'components/Users/UserInvitation'
import TenantSuspend from './TenantSuspend'
import TenantApiState from './TenantApiState'
import { ParamProps } from './types'

const menuItems = [
  {
    type: 'tenantList',
    label: 'テナント一覧',
  },
]
const PivotRoutes = {
  detail: 'detail',
  users: 'users',
  statistics: 'statistics',
  services: 'services',
}
const pivotItems = [
  { header: 'テナント情報', route: PivotRoutes.detail },
  { header: '登録ユーザー情報', route: PivotRoutes.users },
  { header: 'APIご利用状況', route: PivotRoutes.statistics },
  { header: '各種サービス連携', route: PivotRoutes.services },
]

const TenantDetail: React.FC = () => {
  const [dropdown, setDropdown] = React.useState(false)
  const [stopped, setStopped] = React.useState(false)
  const [openSuspend, setOpenSuspend] = React.useState(false)
  const [openInvite, setOpenInvite] = React.useState(false)
  const [modalErrorMessage, setModalErrorMessage] = React.useState('')
  const params = useParams<ParamProps>()
  const tenantId = Number(params.tenantId)
  const dispatch = useDispatch()
  const history = useHistory()

  React.useEffect(() => {
    dispatch(getTenant(tenantId))
  }, [dispatch, tenantId])

  const { tenants, errorMessage } = useSelector(selectTenantsStatus)
  const tenant = React.useMemo(() => tenants.find(t => t.tenantId === tenantId), [tenants, tenantId])

  const isActive = React.useMemo(() => tenant?.status === TenantStatus.Active, [tenant])

  const dropdownItems = React.useMemo(() => {
    const color = isActive ? 'danger' : 'body'
    const text = isActive ? '利用停止' : '利用再開'
    const onClick = () => {
      if (isActive) {
        setOpenSuspend(true)
      } else {
        setStopped(true)
        dispatch(updateTenantStatus(tenantId, TenantStatus.Active))
      }
      setDropdown(false)
    }
    return (
      <DropdownItem className={`text-${color}`} onClick={onClick}>
        {text}
      </DropdownItem>
    )
  }, [dispatch, isActive, tenantId])

  const toggleContent = <i className="icf-others text-secondary font-x-large" role="button" />

  const [pivotIndex, setPivotIndex] = React.useState(0)
  const { pathname } = useLocation()
  React.useEffect(() => {
    const index = pivotItems.findIndex(p => new RegExp(`^/tenants/\\d+/${p.route}$`).test(pathname))
    setPivotIndex(index < 0 ? 0 : index)
  }, [pathname])
  const onPivotChange = (index: number) => {
    // pivotの選択状態が変化したらpivotIndexを直接変更するのではなく、url遷移の副作用として変更する。
    const nextPath = `/tenants/${tenantId}/${pivotItems[index].route}`
    if (pathname !== nextPath) {
      history.push(nextPath)
    }
  }

  const onNavMenuClick = () => {
    history.push(`/tenants`)
  }

  const onEditTenantClick = () => history.push(`/tenants/${tenantId}/edit`)

  const onSuspendSubmit = () => {
    dispatch(updateTenantStatus(tenantId, TenantStatus.Stopped))
  }

  const onSuspendCancel = () => {
    dispatch(clearErrorMessage())
    setModalErrorMessage('')
    setOpenSuspend(false)
  }

  React.useEffect(() => {
    if (openSuspend && tenant?.status === TenantStatus.Stopped) {
      setOpenSuspend(false)
      dispatch(showSuccess())
    } else if (openSuspend && errorMessage !== '') {
      setModalErrorMessage('保存できませんでした。')
    } else if (stopped && tenant?.status === TenantStatus.Active) {
      setStopped(false)
      dispatch(showSuccess())
    } else if (stopped && errorMessage !== '' && !ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      dispatch(showError())
    }
  }, [openSuspend, errorMessage, tenant?.status, stopped, dispatch])

  return (
    <>
      <NavMenu type="tenantList" items={menuItems} onNavMenuClick={onNavMenuClick}>
        <div className="mt-3 mx-3">
          <Link to="/tenants" className="d-flex align-items-center text-secondary mb-1">
            <i className="icf-chevron_left" />
            <span style={{ marginTop: '0.2rem' }}>テナント一覧へ</span>
          </Link>
          <div className="d-flex justify-content-between align-items-center mb-3 mx-0 row">
            <div className="font-x-large font-weight-bold mw-100">
              <div className="text-truncate">{tenant?.name}</div>
              <div className="text-truncate">{tenant?.salesOfficeName}</div>
            </div>

            <div className="d-flex align-items-center">
              <Badge className="px-4 py-2" color={isActive ? 'success' : 'secondary'} pill>
                {isActive ? '利用中' : '利用停止中'}
              </Badge>

              <div className="bg-white rounded mx-3">
                <Button outline className="d-flex align-items-center" onClick={() => setOpenInvite(true)}>
                  <i className="icf-plus pr-2 font-large" />
                  <div className="pl-1">テナント内ユーザーの追加</div>
                </Button>
              </div>
              <div className="bg-white rounded mr-3">
                <Button outline className="d-flex align-items-center" onClick={onEditTenantClick}>
                  <i className="icf-edit pr-2 font-large" />
                  <div className="pl-1">テナント情報編集</div>
                </Button>
              </div>

              <DropdownList
                open={dropdown}
                setOpen={() => setDropdown(!dropdown)}
                content={toggleContent}
                direction="left"
              >
                {dropdownItems}
              </DropdownList>
            </div>
          </div>

          <PivotOuterIndex selectedIndex={pivotIndex} onChange={onPivotChange}>
            {pivotItems.map(({ header, route }) => (
              <PivotItem headerText={header} key={route}>
                {route === PivotRoutes.detail ? (
                  <TenantInformation tenant={tenant} status={tenant?.status} />
                ) : route === PivotRoutes.users ? (
                  <TenantUsers showSuccess={() => dispatch(showSuccess())} />
                ) : route === PivotRoutes.statistics ? (
                  <TenantApiState tenantId={tenantId} />
                ) : route === PivotRoutes.services ? (
                  <TenantServiceCooperation />
                ) : (
                  <div>{header}のコンテンツ</div>
                )}
              </PivotItem>
            ))}
          </PivotOuterIndex>
        </div>
      </NavMenu>

      <TenantSuspend
        isOpen={openSuspend}
        errorMessage={modalErrorMessage}
        errorMessageHide={() => setModalErrorMessage('')}
        onSubmit={onSuspendSubmit}
        onCancel={onSuspendCancel}
      />
      <UserInvitation
        isOpen={openInvite}
        tenantId={tenantId}
        onClose={() => setOpenInvite(false)}
        onSuccess={() => dispatch(showSuccess())}
      />
    </>
  )
}

export default TenantDetail
