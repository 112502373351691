import * as React from 'react'
import { CustomInput } from 'reactstrap'

type Props = {
  id: string
  checked: boolean
  onClick: (checked: boolean) => void
  indeterminate?: boolean
  disabled?: boolean
}

export const TableCheckbox: React.FC<Props> = ({ id, checked, onClick, indeterminate = false, disabled }) => {
  const checkboxRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (checkboxRef?.current) {
      checkboxRef.current.indeterminate = indeterminate
    }
  }, [checkboxRef, indeterminate])

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    onClick(event.target.checked)
  }

  return (
    <CustomInput
      id={id}
      innerRef={checkboxRef}
      type="checkbox"
      checked={checked}
      onChange={handleClick}
      disabled={disabled}
    />
  )
}
export default TableCheckbox
