import * as React from 'react'
import useBusinessTime from 'hooks/useBusinessTime'
import { CELL_WIDTH } from 'components/common/utils'
import styles from './TableScale.module.scss'

type Props = {
  items: Map<number, number> | Map<number, JSX.Element>
}
const TableScale: React.FC<Props> = ({ items }) => {
  const { businessHourBlocks } = useBusinessTime()
  const width = CELL_WIDTH * 4

  const count = React.useCallback((item: number | JSX.Element | undefined) => {
    if (typeof item === 'number') {
      return item.toFixed(2)
    }
    if (!item) {
      return '0.00'
    }
    return item
  }, [])

  return (
    <table>
      <tbody>
        <tr className={styles.container} style={{ width: width * businessHourBlocks.length }}>
          {businessHourBlocks.map(time => (
            <td key={time} className={styles.box} style={{ width }}>
              {count(items.get(time))}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  )
}

export default TableScale
