import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { WorkspaceSummaryWorker, WorkspaceSummaryHourlyWorkerData } from 'api/dashboard'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getWorkspaceSummary, selectTenantsStatus } from 'slices/tenantsSlice'
import { BadgeLabel, IndicatorProgressBar } from 'components/common'
import TeamProgressTable from './TeamProgressTable'
import styles from './TeamProgress.module.scss'

type PlanRecordCount = {
  planCount: number
  recordCount: number
}
const initPlanRecordCount = {
  planCount: 0,
  recordCount: 0,
}
const appendPlanRecordCount = (counts: PlanRecordCount[]) => {
  return counts.reduce(
    (acc, cur) => ({
      planCount: acc.planCount + cur.planCount,
      recordCount: acc.recordCount + cur.recordCount,
    }),
    initPlanRecordCount
  )
}

const TeamProgress: React.FC = () => {
  const {
    team: { workspaceId, groupId, groupName, groupColor },
  } = useSelector(selectSessionStatus)

  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(getWorkspaceSummary(workspaceId, moment().format('YYYY-MM-DD')))
  }, [dispatch, workspaceId])
  const { workspaceSummary } = useSelector(selectTenantsStatus)
  const workspaceSummaryData = workspaceSummary?.workspaceData

  const { diffPlanCount, achievementRate, planRate, warning } = React.useMemo(() => {
    const record = workspaceSummaryData?.groups
      .find(d => d.groupId === groupId)
      ?.workers.reduce((acc, cur) => {
        const count = cur.hourlyWorkerData.map(hourly => {
          const hourlyCount = hourly.data
            .filter(d => d.recordCount !== null)
            .map(d => ({ planCount: d.planCount, recordCount: d.recordCount } as PlanRecordCount))
          return appendPlanRecordCount(hourlyCount)
        })
        const workerCount = appendPlanRecordCount(count)
        return {
          planCount: acc.planCount + workerCount.planCount,
          recordCount: acc.recordCount + workerCount.recordCount,
        }
      }, initPlanRecordCount)

    if (_.isObject(record) && !_.isEqual(record, initPlanRecordCount)) {
      // eslint-disable-next-line no-shadow
      const achievementRate = record.planCount === 0 ? 0 : Math.floor((record.recordCount / record.planCount) * 100)
      // eslint-disable-next-line no-shadow
      const diffPlanCount = Math.floor(record.recordCount - record.planCount)
      return {
        diffPlanCount,
        achievementRate,
        planRate: 80,
        warning: Math.abs(100 - achievementRate) >= 10,
      }
    }
    return { diffPlanCount: 0, achievementRate: 0, planRate: 0, warning: true }
  }, [groupId, workspaceSummaryData?.groups])

  const lastImportedAt = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return undefined
    }
    const data = _.chain(workspaceSummaryData.groups)
      .filter(['groupId', groupId])
      .flatMap<WorkspaceSummaryWorker>('workers')
      .flatMap<WorkspaceSummaryHourlyWorkerData>('hourlyWorkerData')
      .filter('lastImportedAt')
      .maxBy('lastImportedAt')
      .value()
    return data ? moment(data.lastImportedAt).format('YYYY/MM/DD HH:mm:ss') : undefined
  }, [workspaceSummaryData, groupId])

  return (
    <div className={`${styles.container} d-flex flex-column`}>
      <Card className={`${styles.summary} m-4`}>
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex w-25">
              <BadgeLabel label={groupName} className={styles.labelMaxWidth} />
            </div>

            <div className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <span className={`${styles.label} text-muted`}>達成率</span>
                <span className={`${styles.value} font-weight-bold`}>{achievementRate}%</span>
              </div>

              <div className="d-flex flex-column ml-4">
                <span className={`${styles.label} text-muted`}>計画との差</span>
                <span className={`${styles.value} ${warning ? 'text-danger' : ''} font-weight-bold`}>
                  {diffPlanCount.toLocaleString()}
                </span>
              </div>

              <div className="d-flex flex-column ml-5">
                <div className={`${styles.progressBar} mt-3 mx-3`}>
                  <IndicatorProgressBar achievementRate={achievementRate - 20} planRate={planRate} warning={warning} />
                </div>

                <div className="d-flex text-muted align-self-end">
                  {lastImportedAt && <i className="icf-updated align-self-center pr-1" />}
                  <small className={styles.date}>{lastImportedAt}</small>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <div className={`${styles.tableWrapper} mx-4 mb-4`}>
        {workspaceSummaryData && (
          <TeamProgressTable
            groupId={groupId}
            groupName={groupName}
            workspaceSummaryData={workspaceSummaryData}
            groupColor={groupColor}
          />
        )}
      </div>
    </div>
  )
}

export default TeamProgress
