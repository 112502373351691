import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Container, Row, Col, Card, CardText } from 'reactstrap'
import { logout, setTeamWorkspace } from 'slices/sessionSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'
import styles from './styles.module.scss'

const TeamSelectWorkspaces: React.FC = () => {
  const dispatch = useDispatch()
  const workspacesStatus = useSelector(selectWorkspacesStatus)
  const workspaces = _.sortBy(workspacesStatus.workspaces, 'name')

  React.useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  const history = useHistory()
  const handleClick = (workspaceId: number, workspaceName: string) => {
    dispatch(setTeamWorkspace({ workspaceId, workspaceName }))
    history.push('/team-select-groups')
  }

  return (
    <>
      <Container fluid className={styles.header}>
        <Row>
          <Col></Col>
          <Col className="font-x-large font-weight-bold text-center">ワークスペースを選択</Col>
          <Col className="text-right" onClick={() => dispatch(logout())}>
            ログアウト
          </Col>
        </Row>
      </Container>
      <div className={`position-sticky ${styles.list}`}>
        {workspaces.map(workspace => (
          <Card
            className={`${styles.singleTextCard} p-3 mb-2`}
            key={workspace.workspaceId}
            onClick={() => handleClick(workspace.workspaceId, workspace.name)}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="overflow-hidden">
                <CardText className="m-0 text-truncate">{workspace.name}</CardText>
              </div>
              <i className="icf-chevron_right font-large" />
            </div>
          </Card>
        ))}
      </div>
    </>
  )
}

export default TeamSelectWorkspaces
