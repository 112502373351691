import * as React from 'react'
import styles from './styles.module.scss'

export type Props = {
  label: string
  color?: string
  className?: string
}

const BadgeLabel: React.FC<Props> = ({ label, color, className }) => {
  return (
    <div className={`d-flex mw-100 user-select-none ${styles.toggle_button_disabled} ${className}`}>
      {color && <div className={`${styles.square} bg-${color} mr-1 align-self-center`}></div>}
      <div className="text-truncate">{label}</div>
    </div>
  )
}

export default BadgeLabel
