import moment from 'moment'
import { chain } from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, CustomInput, FormGroup, Label } from 'reactstrap'
import { PlanType, V2AttendanceType } from 'api/optimization'
import { createDataAt, selectOptimizationStatus, clearError, getLocationConfig } from 'slices/optimizationSlice'
import { selectWorksStatus } from 'slices/worksSlice'
import { BadgeButton, CustomModal, SelectBoxFormat, TimeSelect } from 'components/common'
import { BadgeItem } from 'components/common/types'
import { SHIFT_SCHEDULE_TYPE_ID, COLORS, roundedMoment } from 'components/common/utils'
import { EditGroupsType, WorkPlanSchedulesType } from '../types'

const SELECT_DATA = {
  ALL: 'all',
  SELECT: 'select',
}

const groupItems = [
  { key: SELECT_DATA.ALL, value: 'すべてのメンバー' },
  { key: SELECT_DATA.SELECT, value: '特定のメンバー' },
]
const initialInputData: SelectDataType = { hour: '09', minute: '00', work: SELECT_DATA.ALL, group: SELECT_DATA.ALL }

type HourlyTimeRecordData = {
  scheduleTypeId: number
  planValue: number | null
  actualValue: number | null
  ratio: number
}
type SelectDataType = {
  hour: string
  minute: string
  work?: string
  group?: string
}
type Props = {
  apiKey: string
  magiQannealTenant: string
  magiQannealLocation: string
  isOpen: boolean
  workspaceId: number
  workId: number
  editGroups: EditGroupsType[]
  onCancel: () => void
  workDate?: string
}

const OptimizationDialog: React.FC<Props> = props => {
  const { apiKey, magiQannealTenant, magiQannealLocation, isOpen, workId, editGroups, onCancel, workDate } = props

  const dispatch = useDispatch()
  const { data, optimizationError, isRequesting } = useSelector(selectOptimizationStatus)
  const { works } = useSelector(selectWorksStatus)

  const [modalErrorMessage, setModalErrorMessage] = React.useState<string>()
  const [inputData, setInputData] = React.useState<SelectDataType>(initialInputData)
  const [selectedGroups, setSelectedGroups] = React.useState<number[]>([])
  const [submit, setSubmit] = React.useState(false)
  const [priority, setPriority] = React.useState(true)

  const targetWorks = React.useMemo(() => data?.works.map(w => w.workId) ?? [], [data])
  const work = React.useMemo(() => works.find(w => w.workId === workId), [works, workId])

  const approveDisabled = React.useMemo(() => !work || targetWorks.length === 0 || selectedGroups.length === 0, [
    selectedGroups.length,
    targetWorks.length,
    work,
  ])

  const groupBadgeItems = React.useMemo<BadgeItem[]>(
    () =>
      work?.groups.map(group => ({
        color: COLORS[0],
        key: group.groupId, // group.groupId は null のまま扱う
        label: group.name ?? '未所属', // group.name が null の場合には未所属と表示する
      })) || [],
    [work?.groups]
  )

  const planned = React.useMemo<PlanType[]>(
    () =>
      targetWorks.map<PlanType>(targetWorkId => {
        const target = work?.workPlan.find(wp => targetWorkId === wp.scheduleTypeId)
        return {
          workId: targetWorkId,
          quota: target?.targetValue ?? 0,
        }
      }),
    [targetWorks, work?.workPlan]
  )

  const processed = React.useMemo<PlanType[]>(() => {
    const body = targetWorks.map<PlanType>(targetWorkId => ({ workId: targetWorkId, quota: 0 }))
    if (!work?.hourlyRecords) {
      return body
    }

    return work.hourlyRecords
      .flatMap(hourlyRecord => hourlyRecord.data)
      .reduce((acc, cur) => {
        const target = acc.find(a => a.workId === cur.scheduleTypeId)
        if (target) {
          target.quota += cur.actualValue ?? 0
        }
        return acc
      }, body)
  }, [targetWorks, work?.hourlyRecords])

  const predicted = React.useMemo<PlanType[]>(() => {
    const body = targetWorks.map<PlanType>(targetWorkId => ({ workId: targetWorkId, quota: 0 }))
    if (!work?.hourlyRecords) {
      return body
    }
    // predicted の集計に使う終了時刻(inputData.minute が 15, 30, 45 だったときのために 1 時間分多く集めておく)
    const lastHourStart = new Date(`${work.date} ${inputData.hour}:00:00`)

    return (
      work.hourlyRecords
        // inputData.minute が 15, 30, 45 の場合には inputData.minute/60 だけ planValue を加えるために ratio を作る
        .flatMap<HourlyTimeRecordData>(rec =>
          rec.data.map(d => {
            const time = new Date(rec.time)
            if (time < lastHourStart) {
              return { ...d, ratio: 1 }
            }
            if (lastHourStart < time) {
              return { ...d, ratio: 0 }
            }
            return { ...d, ratio: Number(inputData.minute) / 60 }
          })
        )
        .reduce((acc, cur) => {
          const target = acc.find(a => a.workId === cur.scheduleTypeId)
          if (target) {
            target.quota += Math.round((cur.planValue ?? 0) * cur.ratio)
          }
          return acc
        }, body)
    )
  }, [work, inputData, targetWorks])

  const getWorkingSlotsSchedule = React.useCallback(
    (schedules: WorkPlanSchedulesType[]) => {
      return chain(schedules)
        .sortBy('scheduleTypeId')
        .reduce((acc, cur) => {
          const isShift = cur.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID
          const isSelectedWork = targetWorks.includes(cur.scheduleTypeId)

          // 優先にチェックがない場合かつ、シフト作業ではない場合はそのまま返す
          if (!priority && !isShift) {
            return acc
          }

          const baseDate = moment(cur.startAt).startOf('day')
          const start = moment(cur.startAt)
          const end = moment(cur.startAt).add(cur.duration, 'seconds')
          // シフト作業と最適配置対象作業は'1',それ以外は'0'
          return [...Array(24 * 4)]
            .map((_, index) => {
              const isActiveTime = baseDate.add(15, 'minutes').isBetween(start, end, 'minutes', '(]')
              if (isActiveTime && (isSelectedWork || isShift)) {
                return '1'
              }
              return isActiveTime ? '0' : acc.charAt(index)
            })
            .join('')
        }, ''.padStart(96, '0'))
        .value()
    },
    [priority, targetWorks]
  )

  const attendances = React.useMemo<V2AttendanceType[]>(
    () =>
      editGroups
        .filter(group => group.groupId && selectedGroups.includes(group.groupId))
        .flatMap(group => group.workers)
        .map(workers => ({ workerId: workers.wmsMemberId, schedule: getWorkingSlotsSchedule(workers.schedules) })) ??
      [],
    [editGroups, getWorkingSlotsSchedule, selectedGroups]
  )

  const datetime = React.useMemo(() => {
    if (!work) {
      return ''
    }
    return moment(`${work.date} ${inputData.hour}:${inputData.minute}`, 'YYYY-MM-DD HH:mm').format()
  }, [work, inputData])
  const handleSubmitClick = () => {
    if (!work) {
      return
    }
    setSubmit(true)
    dispatch(
      createDataAt(apiKey, magiQannealTenant, magiQannealLocation, datetime, planned, processed, predicted, attendances)
    )
  }

  const onGroupBadgeClick = (groupIds: number[]) => {
    setSelectedGroups(groupIds)
  }

  React.useEffect(() => {
    if (!isOpen || isRequesting || !submit) {
      return
    }
    if (optimizationError) {
      // ここで NetworkErrorDialog が表示される
      dispatch(clearError())
    } else if (submit) {
      window.open(
        `${
          process.env.REACT_APP_OPTIMIZATION_SERVER
        }/${magiQannealTenant}/${magiQannealLocation}/board?datetime=${encodeURIComponent(datetime)}`,
        '_blank'
      )
    }
    setSubmit(false)
    onCancel()
  }, [
    dispatch,
    isOpen,
    isRequesting,
    onCancel,
    optimizationError,
    submit,
    datetime,
    magiQannealTenant,
    magiQannealLocation,
  ])

  React.useEffect(() => {
    if (!isOpen) {
      return
    }
    dispatch(
      getLocationConfig(apiKey, magiQannealTenant, magiQannealLocation, moment(workDate).startOf('day').format())
    )
    const now = roundedMoment()
    setInputData({ ...initialInputData, ...now })
    setPriority(true)
  }, [apiKey, magiQannealTenant, magiQannealLocation, dispatch, isOpen, workDate])

  React.useEffect(() => setSelectedGroups(inputData.group === SELECT_DATA.ALL ? groupBadgeItems.map(g => g.key) : []), [
    inputData.group,
    groupBadgeItems,
  ])

  return (
    <CustomModal
      isOpen={isOpen}
      title="最適配置設定"
      approveLabel="設定をmagiQannealと連携"
      errorMessage={modalErrorMessage}
      onCancel={onCancel}
      onApprove={handleSubmitClick}
      approveDisabled={approveDisabled}
      onHideNotification={() => setModalErrorMessage(undefined)}
      overflow="visible"
    >
      <div className="mb-3">AI&amp;量子コンピュータが最適な配置を提案します。最適配置の設定を行ってください。</div>

      <FormGroup row>
        <Label md={4}>開始時間</Label>
        <Col md={8}>
          <TimeSelect
            hour={inputData.hour}
            minute={inputData.minute}
            label=""
            onChange={(hour, minute) => setInputData({ ...inputData, hour, minute })}
            menuZIndex={2} // customInputがzIndex:1のため、それ以上の値を設定
          />
        </Col>
      </FormGroup>

      <SelectBoxFormat
        label="最適配置対象メンバー"
        value={inputData.group}
        size="middle"
        items={groupItems}
        onChange={event => setInputData({ ...inputData, group: event.key?.toString() })}
      />
      {inputData.group === SELECT_DATA.SELECT && (
        <>
          <div>最適配置対象としたいメンバーを選択してください。</div>
          <div className="d-flex row py-3 pr-2 ml-n2 mx-0">
            <BadgeButton items={groupBadgeItems} selected={selectedGroups} onChange={onGroupBadgeClick} hideColor />
          </div>
        </>
      )}
      <CustomInput
        id="optimization-priority"
        label="既に入力されている予定を優先する"
        checked={priority}
        type="checkbox"
        onChange={e => setPriority(e.target.checked)}
      />
    </CustomModal>
  )
}

export default OptimizationDialog
