import * as React from 'react'
import { Row, Col } from 'reactstrap'
import { ScheduleTypeResponse } from 'api/schedule_types'
import { WorkspaceResponse } from 'api/workspaces'
import { ColorType } from 'components/common/types'
import styles from './WorksSelector.module.scss'

export type SelectedScheduleType = {
  scheduleTypeId: number
  name: string
  color?: ColorType
}

type Props = {
  open: boolean
  scheduleTypes: ScheduleTypeResponse[]
  workspaces: WorkspaceResponse[]
  currentScheduleType?: SelectedScheduleType
  workspaceId: number
  showWorkspace?: boolean
  onClose: () => void
  onWorkClick: (scheduleTypeId: number, name: string, color?: ColorType) => void
}

const WorksSelector: React.FC<Props> = props => {
  const {
    open,
    scheduleTypes,
    workspaces,
    currentScheduleType,
    workspaceId,
    showWorkspace = true,
    onClose,
    onWorkClick,
  } = props

  const viewScheduleTypes = React.useMemo(
    () =>
      scheduleTypes.filter(
        s => s.scheduleTypeId !== currentScheduleType?.scheduleTypeId && s.name !== currentScheduleType?.name
      ),
    [scheduleTypes, currentScheduleType]
  )

  const viewWorkspaces = React.useMemo(
    () =>
      showWorkspace
        ? workspaces.filter(
            w =>
              w.workspaceId !== workspaceId &&
              w.workspaceId !== currentScheduleType?.scheduleTypeId &&
              w.name !== currentScheduleType?.name
          )
        : [],
    [showWorkspace, workspaces, workspaceId, currentScheduleType]
  )

  return (
    <>
      <div className={open ? `${styles.backdrop}` : ``}></div>
      <div className={open ? `${styles.sidebarOpen}` : `${styles.sidebar}`}>
        <Row className="p-2 pb-3">
          <Col>作業内容を選択してください</Col>
          <Col sm={{ size: 'auto', offset: 1 }}>
            <i className="icf-close" onClick={onClose} />
          </Col>
        </Row>
        {viewScheduleTypes.map((scheduleType, index) => (
          <Row
            key={`schedule-type-${index}`}
            className="p-2"
            onClick={() => onWorkClick(scheduleType.scheduleTypeId, scheduleType.name, scheduleType.color)}
          >
            <Col className="d-flex align-items-center">
              <div>
                <div className={`${styles.square} bg-${scheduleType.color} mr-2`}></div>
              </div>
              <span className="flex-grow-1 text-truncate">{scheduleType.name}</span>
              <i className="icf-chevron_right font-large text-secondary" />
            </Col>
          </Row>
        ))}
        {viewScheduleTypes.length > 0 && viewWorkspaces.length > 0 && <hr className="my-2"></hr>}
        {viewWorkspaces.map((workspace, index) => (
          <Row
            key={`workspace-${index}`}
            className="p-2"
            onClick={() => onWorkClick(workspace.workspaceId, workspace.name)}
          >
            <Col className="d-flex align-items-center">
              <span className="flex-grow-1 text-truncate">{workspace.name}</span>
              <i className="icf-chevron_right font-large text-secondary" />
            </Col>
          </Row>
        ))}
      </div>
    </>
  )
}
export default WorksSelector
