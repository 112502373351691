import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from 'store'
import * as API from 'api/work_results'
import { makeErrorMessage, UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'
import { CommonParams, commonParams, sleep } from 'slices/utils'
import * as Spinner from 'slices/spinnerSlice'
import { validateToken } from 'slices/sessionSlice'
import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'

type WorkResultsState = {
  isRequesting: boolean
  errorMessage: string
  workResults?: API.GetManualInputResponse
}

const initialState: WorkResultsState = {
  isRequesting: false,
  errorMessage: '',
  workResults: undefined,
}

export const workResultsSlice = createSlice({
  name: 'workResults',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getWorkResultsSuccess: (state, action: PayloadAction<API.GetManualInputResponse>) => {
      state.isRequesting = false
      state.workResults = action.payload
    },
    updateWorkResultsSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getWorkResultsSuccess,
  updateWorkResultsSuccess,
} = workResultsSlice.actions

export const getWorkResults = (workDate: string): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  try {
    const res = await API.getWorkResults(commonParams(getState), workDate)
    dispatch(getWorkResultsSuccess(res))
  } catch (res) {
    const errorCode = makeErrorMessage(res)
    if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
      dispatch(SessionTimeoutDialog.open())
    } else {
      dispatch(NetworkErrorDialog.open({ code: errorCode }))
    }
    dispatch(apiFailure({ errorMessage: errorCode }))
  } finally {
    dispatch(Spinner.stop())
  }
}

const checkUpdateStatus = async (params: CommonParams, workDate: string, revision: number) => {
  const { retryInterval, result } = await API.getAggregateStatus(params, workDate, revision)
  if (result) {
    return
  }
  retryInterval > 0 && (await sleep(retryInterval))
  await checkUpdateStatus(params, workDate, revision)
}
export const updateWorkResults = (workDate: string, workResults: API.PutManualInputType): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  try {
    const { revision } = await API.putWorkResults(commonParams(getState), workDate, workResults)
    await checkUpdateStatus(commonParams(getState), workDate, revision)
    dispatch(updateWorkResultsSuccess())
  } catch (res) {
    const errorCode = makeErrorMessage(res)
    if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
      dispatch(SessionTimeoutDialog.open())
    } else {
      dispatch(NetworkErrorDialog.open({ code: errorCode }))
    }
    dispatch(apiFailure({ errorMessage: errorCode }))
  } finally {
    dispatch(Spinner.stop())
  }
}

export const selectWorkResultsStatus = (state: RootState) => ({ ...state.workResults })

export default workResultsSlice.reducer
