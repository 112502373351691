import * as React from 'react'
import { Input } from 'reactstrap'
import OutsideClickHandler from 'react-outside-click-handler'
import { CELL_WIDTH, Rules } from 'components/common/utils'
import styles from './ManualInput.module.scss'

type Props = {
  value: number
  onChange: (value: number) => void
}
const BLANK_VALUE = -1
const ManualInputCell: React.FC<Props> = ({ value, onChange }) => {
  const [isInit, setIsInit] = React.useState(false)
  const [initValue, setInitValue] = React.useState(value)
  const [clicked, setClicked] = React.useState(false)
  const width = CELL_WIDTH * 4
  const formattedValue = value === BLANK_VALUE ? '' : value

  React.useEffect(() => {
    if (isInit) {
      return
    }
    setInitValue(value)
  }, [isInit, value])
  const handleKeyPress = (pressedKey: string) => {
    if (pressedKey === 'Enter') {
      setClicked(false)
    }
  }

  const handleChange = (val: string) => {
    setIsInit(true)
    if (val === '') {
      if (initValue > 0) {
        // すでに実績が保存されている場合は未入力にならないように0を設定
        onChange(0)
        return
      }
      // 実績が未入力の場合は未入力値を設定
      onChange(BLANK_VALUE)
      return
    }
    if (!Rules.PositiveInteger(val)) {
      onChange(Number(val))
    }
  }

  return (
    <td className={styles.box} style={{ width }} onClick={() => setClicked(true)}>
      <OutsideClickHandler onOutsideClick={() => setClicked(false)}>
        {clicked ? (
          <Input
            value={formattedValue}
            className="text-right"
            autoFocus
            maxLength={8}
            onChange={e => handleChange(e.target.value)}
            onKeyPress={e => handleKeyPress(e.key)}
          />
        ) : (
          formattedValue
        )}
      </OutsideClickHandler>
    </td>
  )
}

export default ManualInputCell
