import moment from 'moment'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, Card, CardBody } from 'reactstrap'
import { TenantResponse, TenantStatus, TenantStatusType } from 'api/tenants'
import { Role } from 'api/users'
import { getTenantUserList, getTenantWorkerList, selectTenantsStatus } from 'slices/tenantsSlice'
import { getUserList, selectUsersStatus } from 'slices/usersSlice'
import { VerticalTable } from 'components/common'
import { VerticalItem } from 'components/common/types'

type Props = {
  tenant: TenantResponse | undefined
  status: TenantStatusType | undefined
}

const TenantInformation: React.FC<Props> = ({ tenant, status }) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getUserList())
  }, [dispatch])
  React.useEffect(() => {
    if (tenant) {
      dispatch(getTenantUserList(tenant.tenantId))
      dispatch(getTenantWorkerList(tenant.tenantId))
    }
  }, [dispatch, tenant])
  const { users, workers } = useSelector(selectTenantsStatus)
  const userStatus = useSelector(selectUsersStatus)
  const accountNumber = React.useMemo(() => users.filter(user => user.role !== Role.TenantAdmin).length, [users])
  const workerNumber = React.useMemo(() => workers.length, [workers])

  const tenantItems: VerticalItem[] = React.useMemo(() => {
    return [
      { title: 'テナントID', data: tenant?.tenantId },
      { title: '企業名', data: tenant?.name },
      { title: '営業所名', data: tenant?.salesOfficeName },
    ]
  }, [tenant])

  const officeItems: VerticalItem[] = React.useMemo(() => {
    return [
      { title: '郵便番号', data: tenant?.postalCode },
      { title: '都道府県', data: tenant?.prefecture },
      { title: '市区町村', data: tenant?.municipality },
      { title: 'それ以降の住所', data: tenant?.otherAddress },
      { title: '電話番号', data: tenant?.phoneNumber },
      {
        title: '営業時間',
        data: tenant
          ? moment(tenant.businessStartTime, 'HH:mm').format('H:mm') +
            ' 〜 ' +
            moment(tenant.businessEndTime, 'HH:mm').format('H:mm')
          : '-',
      },
    ]
  }, [tenant])

  const staffItems: VerticalItem[] = React.useMemo(() => {
    return [
      { title: '担当者', data: userStatus.users.find(user => user.userId === tenant?.personInCharge)?.name || '' },
    ]
  }, [tenant, userStatus.users])

  const stateItems: VerticalItem[] = React.useMemo(() => {
    return [
      {
        title: '登録日',
        data: tenant && moment(tenant?.createdAt).format('YYYY/MM/DD'),
      },
      {
        title: 'ご利用開始日',
        data: tenant?.startedAt ? moment(tenant?.startedAt).format('YYYY/MM/DD') : '-',
      },
      {
        title: 'ご利用停止日',
        data: status === TenantStatus.Stopped && tenant?.endedAt ? moment(tenant?.endedAt).format('YYYY/MM/DD') : '-',
      },
      {
        title: 'ステータス',
        data: status ? (status === TenantStatus.Active ? '利用中' : '利用停止中') : '',
      },
      { title: 'ご利用アカウント数', data: accountNumber },
      { title: 'メンバー数', data: workerNumber },
    ]
  }, [tenant, status, accountNumber, workerNumber])

  return (
    <Card>
      <CardBody>
        <div className="font-weight-bold">テナント</div>
        <Row className="my-2 mx-0">
          <Col md={6} className="border-top border-right border-left">
            <VerticalTable items={tenantItems} titleColumn={3} />
          </Col>
        </Row>

        <div className="font-weight-bold pt-3">営業所情報</div>
        <Row className="my-2 mx-0">
          <Col md={6} className="border-top border-right border-left">
            <VerticalTable items={officeItems} titleColumn={3} />
          </Col>
        </Row>

        <div className="font-weight-bold pt-3">担当者情報</div>
        <Row className="my-2 mx-0">
          <Col md={6} className="border-top border-right border-left">
            <VerticalTable items={staffItems} titleColumn={3} />
          </Col>
        </Row>

        <div className="font-weight-bold pt-3">ご利用状況</div>
        <Row className="my-2 mx-0">
          <Col md={6} className="border-top border-right border-left">
            <VerticalTable items={stateItems} titleColumn={3} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default TenantInformation
