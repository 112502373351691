import moment from 'moment'
import * as React from 'react'
import { DropdownItem, Button } from 'reactstrap'
import { DropdownList, SingleDateRangePicker } from 'components/common'

// DatePickerに設定可能な最大日数
const DATE_PICKER_MAX_DAYS = 90

export const PERIOD_LIST = {
  USER_SETTING: 0,
  DAYS_7: 7,
  DAYS_15: 15,
  DAYS_30: 30,
  DAYS_45: 45,
  DAYS_90: 90,
}
const periodList = [
  { label: '過去7日間', period: PERIOD_LIST.DAYS_7, id: '7days' },
  { label: '過去15日間', period: PERIOD_LIST.DAYS_15, id: '15days' },
  { label: '過去30日間', period: PERIOD_LIST.DAYS_30, id: '30days' },
  { label: '過去45日間', period: PERIOD_LIST.DAYS_45, id: '45days' },
  { label: '過去90日間', period: PERIOD_LIST.DAYS_90, id: '90days' },
  { label: '開始日と終了日を設定', period: PERIOD_LIST.USER_SETTING, id: 'user_setting' },
]

export type Props = {
  period: { start: Date; end: Date }
  onChange: (start: Date, end: Date) => void
}

const PeriodSelect: React.FC<Props> = ({ period, onChange }) => {
  const [openPeriod, setOpenPeriod] = React.useState(false)
  const [periodLabel, setPeriodLabel] = React.useState('')
  const [openRangeDatePicker, setOpenRangeDatePicker] = React.useState(false)

  React.useEffect(() => {
    const start = moment(period.start)
    const end = moment(period.end)

    const diffday = Math.abs(start.diff(period.end, 'days')) + 1
    const label =
      periodList.find(p => p.period === diffday)?.label || `${start.format('YYYY/MM/DD')} - ${end.format('YYYY/MM/DD')}`

    setPeriodLabel(label)
  }, [period])

  const onPeriodClick = (selectedPeriod: number) => {
    if (selectedPeriod === PERIOD_LIST.USER_SETTING) {
      // 開始日と終了日を設定する場合はperiod選択状態を変更せず、date pickerを表示する
      setOpenRangeDatePicker(true)
      return
    }

    const start = moment().subtract(selectedPeriod, 'days').toDate()
    const end = moment().subtract(1, 'days').toDate()
    onChange(start, end)
  }

  const onRangeDatePickerChange = (from: Date, to: Date) => {
    setOpenRangeDatePicker(false)

    if (from && to) {
      onChange(from, to)
    }
  }

  const toggleContent = (
    <div className="bg-white rounded">
      <Button outline className="d-flex align-items-center">
        <div>{periodLabel}</div>
        <i className="icf-carot_down pl-2 font-large" />
      </Button>
    </div>
  )

  return (
    <div>
      <DropdownList
        open={openPeriod}
        setOpen={() => setOpenPeriod(!openPeriod)}
        content={toggleContent}
        direction="left"
      >
        {periodList.map((m, index) => (
          <React.Fragment key={m.id}>
            <DropdownItem onClick={() => onPeriodClick(m.period)}>{m.label}</DropdownItem>
            {index === periodList.length - 2 && <DropdownItem divider />}
          </React.Fragment>
        ))}
      </DropdownList>

      <SingleDateRangePicker
        isOpen={openRangeDatePicker}
        from={period.start}
        to={period.end}
        maxRange={DATE_PICKER_MAX_DAYS}
        minDate={moment().subtract(DATE_PICKER_MAX_DAYS, 'days').toDate()}
        onCancel={() => setOpenRangeDatePicker(false)}
        onChange={onRangeDatePickerChange}
      />
    </div>
  )
}

export default PeriodSelect
