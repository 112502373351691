import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap'
import { TenantStatus } from 'api/tenants'
import { Role } from 'api/users'
import { showSuccess } from 'slices/notificationSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getTenantUserList, selectTenantsStatus } from 'slices/tenantsSlice'
import { getUserList, selectUsersStatus } from 'slices/usersSlice'
import { NavMenu, List, VerticalTable, Table } from 'components/common'
import { ListItem, TableHeaderType, TableCellType, VerticalItem } from 'components/common/types'
import { accountTypeName } from 'components/common/utils'
import AccountTypeChange from 'components/AccountTypeChange/AccountTypeChange'
import UserDelete from './UserDelete'
import UserInvitation from './UserInvitation'
import styles from './Users.module.scss'

const menuItems = [
  {
    type: 'userList',
    label: 'ユーザー一覧',
  },
]

const Users: React.FC = () => {
  const [openDelete, setOpenDelete] = React.useState(false)
  const [openInvite, setOpenInvite] = React.useState(false)
  const [openChange, setOpenChange] = React.useState(false)
  const [selectedId, setSelectedId] = React.useState('')
  const [menuType, setMenuType] = React.useState('userList')

  const {
    user: { userId, role, tenants },
  } = useSelector(selectSessionStatus)
  const isTenantAdmin = React.useMemo(() => role === Role.TenantAdmin, [role])
  const isProcessAdmin = React.useMemo(() => role === Role.ProcessAdmin, [role])

  const dispatch = useDispatch()
  React.useEffect(() => {
    if (isTenantAdmin) {
      dispatch(getUserList())
    } else if (tenants[0]?.tenantId) {
      dispatch(getTenantUserList(tenants[0].tenantId))
    }
  }, [dispatch, tenants, isTenantAdmin])

  const allUsers = useSelector(selectUsersStatus)
  const tenantUsers = useSelector(selectTenantsStatus)
  const users = React.useMemo(() => (isTenantAdmin ? allUsers.users : tenantUsers.users), [
    isTenantAdmin,
    allUsers,
    tenantUsers,
  ])

  const listItems: ListItem[] = React.useMemo(() => {
    if (isTenantAdmin) {
      return users.filter(user => user.role === Role.TenantAdmin).map(user => ({ id: user.userId, title: user.name }))
    }

    return users
      .filter(user => user.role === Role.Admin || user.role === Role.ProcessAdmin)
      .map(user => ({ id: user.userId, title: user.name, data: accountTypeName(user.role) }))
  }, [users, isTenantAdmin])

  React.useEffect(() => setSelectedId(listItems[0]?.id as string), [listItems])

  const selectedUser = React.useMemo(() => users.find(user => selectedId === user.userId), [selectedId, users])

  const handleDelete = () => {
    setOpenDelete(false)
    dispatch(showSuccess())
    if (isTenantAdmin) {
      dispatch(getUserList())
    } else {
      dispatch(getTenantUserList(tenants[0]!.tenantId))
    }
  }

  const userInformationItems: VerticalItem[] = React.useMemo(() => {
    const items = [
      { title: '名前', data: selectedUser?.name },
      { title: 'メールアドレス', data: selectedUser?.email },
    ]
    if (!isTenantAdmin) {
      items.push({ title: 'アカウントタイプ', data: accountTypeName(selectedUser?.role) })
    }
    return items
  }, [isTenantAdmin, selectedUser])

  const header: TableHeaderType[] = [
    { value: 'テナント ID', width: '20%' },
    { value: '企業名', width: '30%' },
    { value: '事業所名', width: '40%' },
    { value: 'ご利用状況', width: '10%' },
  ]

  const tableItems: TableCellType[][] = React.useMemo(
    () =>
      selectedUser?.tenants.map(tenant => [
        { value: tenant.tenantId },
        { value: tenant.name },
        { value: tenant.salesOfficeName || '-' },
        {
          value: tenant.status === TenantStatus.Active ? '利用中' : '停止中',
          className: tenant.status === TenantStatus.Active ? 'text-success' : 'text-danger',
        },
      ]) || [],
    [selectedUser]
  )

  const history = useHistory()
  const handleRowClick = (index: number) => {
    const tenantId = tableItems[index][0].value
    history.push(`/tenants/${tenantId}/detail`)
  }

  return (
    <>
      <NavMenu type={menuType} items={menuItems} onNavMenuClick={setMenuType}>
        <div className="mt-3 mx-3">
          <div className="d-flex justify-content-between">
            <div className="font-x-large font-weight-bold align-self-center">ユーザー一覧</div>
            {!isProcessAdmin && (
              <div className="bg-white rounded">
                <Button color="primary" className="d-flex align-items-center" onClick={() => setOpenInvite(true)}>
                  <i className="icf-plus pr-2 font-large" />
                  <div className="pl-1">ユーザーの追加</div>
                </Button>
              </div>
            )}
          </div>
          <Row className="py-3">
            <Col md={4}>
              <Card className={`position-sticky ${styles.list}`}>
                <List items={listItems} selectedId={selectedId} onAction={(id: string) => setSelectedId(id)} />
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                <CardBody>
                  <CardTitle className="font-large font-weight-bold">ユーザー詳細</CardTitle>
                  <Container className="border-top border-right border-left">
                    <VerticalTable items={userInformationItems} titleColumn={3} />
                    {!isTenantAdmin && !isProcessAdmin && (
                      <Row className="border-bottom">
                        <Col
                          className="text-center text-primary py-3 bg-bgcolor"
                          role="button"
                          onClick={() => setOpenChange(true)}
                        >
                          アカウントタイプの変更
                        </Col>
                      </Row>
                    )}
                  </Container>
                </CardBody>

                {isTenantAdmin && tableItems.length > 0 && (
                  <CardBody>
                    <CardTitle className="font-large font-weight-bold">担当テナント</CardTitle>
                    <Container className="px-0">
                      <Table header={header} body={tableItems} onRowClick={handleRowClick} />
                    </Container>
                  </CardBody>
                )}

                {userId !== selectedId && !isProcessAdmin && (
                  <CardBody>
                    <CardTitle className="font-large font-weight-bold">ユーザーアカウント削除</CardTitle>
                    <CardText className="py-2">
                      ユーザーアカウントを削除すると、アカウント情報などはすべて失われ、復旧できません。
                    </CardText>

                    <Button outline color="danger" className="my-3" onClick={() => setOpenDelete(true)}>
                      このユーザーを削除
                    </Button>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </NavMenu>

      {selectedUser && (
        <AccountTypeChange
          isOpen={openChange}
          userId={selectedUser.userId}
          role={selectedUser.role}
          onSuccess={() => setOpenChange(false)}
          onCancel={() => setOpenChange(false)}
        />
      )}

      <UserDelete
        isOpen={openDelete}
        userId={selectedId}
        onSuccess={handleDelete}
        onCancel={() => setOpenDelete(false)}
      />
      <UserInvitation
        isOpen={openInvite}
        onClose={() => setOpenInvite(false)}
        onSuccess={() => dispatch(showSuccess())}
      />
    </>
  )
}

export default Users
