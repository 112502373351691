import moment from 'moment'
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { WorkspaceSummaryData } from 'api/dashboard'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getTenant } from 'slices/tenantsSlice'
import { TimeScale, WorkerIcon } from 'components/common'
import { timeDataReducer } from 'components/common/utils'
import { TimeReducerType, ColorType } from 'components/common/types'
import TableScale from 'components/common/AssignedNumberTable/TableScale'
import useBusinessTime from 'hooks/useBusinessTime'
import styles from './TeamProgressTable.module.scss'

export type Props = {
  groupId: number
  groupName: string
  groupColor: ColorType
  workspaceSummaryData: WorkspaceSummaryData
}

const TeamProgressTable: React.FC<Props> = ({ groupId, groupName, groupColor, workspaceSummaryData }) => {
  const [openGroup, setOpenGroup] = React.useState(true)
  const dispatch = useDispatch()

  const { user } = useSelector(selectSessionStatus)

  React.useEffect(() => {
    dispatch(getTenant(user.tenants[0].tenantId))
  }, [dispatch, user])

  const { businessHourBlocks } = useBusinessTime()

  const workerDataList = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return []
    }

    return workspaceSummaryData.groups
      .filter(group => group.groupId === groupId)
      .flatMap(group =>
        group.workers.map(worker => {
          // worker内のスケジュール毎のデータを整形して1つの配列にまとめる
          const timeDataList = worker.hourlyWorkerData.flatMap(hourlyData =>
            hourlyData.data.map(
              d =>
                ({
                  time: moment(d.time).format('H:mm'),
                  planCount: d.planCount,
                  recordCount: d.recordCount,
                  rate: null,
                } as TimeReducerType)
            )
          )

          // 同じtimeのデータを集計
          const workerData: { [key: string]: TimeReducerType } = timeDataList.reduce(timeDataReducer, {})

          return {
            workerId: worker.workerId,
            workerName: worker.workerName,
            workerData,
          }
        })
      )
  }, [workspaceSummaryData, groupId])

  const groupData: { [key: string]: TimeReducerType } = React.useMemo(
    () => workerDataList.flatMap(d => Object.values(d.workerData)).reduce(timeDataReducer, {}),
    [workerDataList]
  )
  const groupCounts = React.useMemo(
    () =>
      businessHourBlocks.reduce((acc, cur) => {
        const rate = groupData[`${cur}:00`]?.rate || null
        const warning = rate !== null && Math.abs(100 - rate) >= 10
        acc.set(cur, <div className={warning ? 'text-danger' : ''}>{rate ? `${rate}%` : ''}</div>)
        return acc
      }, new Map()),
    [businessHourBlocks, groupData]
  )
  const workerCounts = React.useCallback(
    worker =>
      businessHourBlocks.reduce((acc, cur) => {
        const rate = worker.workerData[`${cur}:00`]?.rate || null
        const warning = rate !== null && Math.abs(100 - rate) >= 10
        acc.set(cur, <div className={warning ? 'text-danger' : ''}>{rate ? `${rate}%` : ''}</div>)
        return acc
      }, new Map()),
    [businessHourBlocks]
  )

  return (
    <table>
      <thead>
        <tr className={styles.header}>
          <th className={styles.nameHeader}>名前</th>
          <TimeScale round />
        </tr>
      </thead>

      <tbody>
        <tr className={styles.groupRow}>
          <td className={styles.groupLabel}>
            <div className="pl-2 d-flex align-items-center" role="button" onClick={() => setOpenGroup(!openGroup)}>
              <i className={`icf-carot_${openGroup ? 'down' : 'right'} mr-1`} />
              <div className="text-truncate">{groupName}</div>
            </div>
          </td>

          <TableScale items={groupCounts} />
        </tr>

        {openGroup &&
          workerDataList.map(worker => (
            <tr key={worker.workerId} className={styles.workerRow}>
              <td className={styles.workerLabel}>
                <div className="px-4 py-2 d-flex align-items-center">
                  <WorkerIcon name={worker.workerName} groupColor={groupColor} />
                  <div className="ml-2 text-truncate font-weight-normal">{worker.workerName}</div>
                </div>
              </td>
              <td>
                <TableScale items={workerCounts(worker)} />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}

export default TeamProgressTable
