import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteSkill, selectSkillsStatus } from 'slices/skillsSlice'
import { CustomModal } from 'components/common'

type Props = {
  isOpen: boolean
  skillId: number
  onSuccess: () => void
  onCancel: () => void
}

const SkillDelete: React.FC<Props> = ({ isOpen, skillId, onSuccess, onCancel }) => {
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = React.useState(false)
  const dispatch = useDispatch()
  const { isRequesting, errorMessage } = useSelector(selectSkillsStatus)

  const handleDeleteClick = () => {
    setSubmitted(true)
    dispatch(deleteSkill(skillId))
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else {
      setModalErrorMessage('削除できませんでした。')
    }
    setSubmitted(false)
  }, [submitted, errorMessage, isRequesting, onSuccess])

  return (
    <CustomModal
      isOpen={isOpen}
      title="スキルの削除"
      approveColor="danger"
      approveLabel="削除"
      errorMessage={modalErrorMessage}
      onCancel={onCancel}
      onApprove={handleDeleteClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-4">このスキルを本当に削除しますか？この操作は取り消せません。</div>
    </CustomModal>
  )
}

export default SkillDelete
