import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { FormGroup, Label, Col } from 'reactstrap'
import { TenantEditDataType, TenantResponse } from 'api/tenants'
import { Role } from 'api/users'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'
import { getTenant, updateTenant, selectTenantsStatus } from 'slices/tenantsSlice'
import { getUserList, selectUsersStatus } from 'slices/usersSlice'
import { getPostcodeList, selectPostcodeStatus } from 'slices/postcodeSlice'
import {
  InputFormat,
  PostalcodeInputForm,
  SelectBoxFormat,
  Notification,
  TimeSelect,
  SubmitFooter,
} from 'components/common'
import { prefectureItems } from 'components/common/utils'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import { ParamProps } from './types'
import styles from './TenantEdit.module.scss'

const TenantEdit: React.FC = () => {
  const [showError, setShowError] = React.useState(false)
  const [initEditData, setInitEditData] = React.useState<TenantEditDataType>({
    name: '',
    salesOfficeName: '',
    postalCode: '',
    prefecture: '',
    municipality: '',
    otherAddress: '',
    phoneNumber: '',
    personInCharge: '',
    businessStartHour: '08',
    businessStartMinute: '00',
    businessEndHour: '18',
    businessEndMinute: '00',
  })
  const [editData, setEditData] = React.useState<TenantEditDataType>(initEditData)
  const [submitted, setSubmitted] = React.useState(false)
  const [nameValidity, setNameValidity] = React.useState(false)
  const [postalCodeValidity, setPostalCodeValidity] = React.useState(false)
  const [prefectureValidity, setPrefectureValidity] = React.useState(false)
  const [municipalityValidity, setMunicipalityValidity] = React.useState(false)
  const [otherAddressValidity, setOtherAddressValidity] = React.useState(false)
  const [phoneNumberValidity, setPhoneNumberValidity] = React.useState(false)
  const [businessTimeValidity, setBusinessTimeValidity] = React.useState(false)
  const [personInChargeValidity, setPersonInChargeValidity] = React.useState(false)

  const params = useParams<ParamProps>()
  const tenantId = Number(params.tenantId)
  const history = useHistory()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getTenant(tenantId))
    dispatch(getUserList())
    dispatch(getPostcodeList())
  }, [dispatch, tenantId])

  const { tenants, isRequesting, errorMessage } = useSelector(selectTenantsStatus)
  const tenant: TenantResponse | undefined = React.useMemo(() => tenants.find(t => t.tenantId === tenantId), [
    tenantId,
    tenants,
  ])

  React.useEffect(() => {
    const businessStartTime = tenant?.businessStartTime.split(':')
    const businessEndTime = tenant?.businessEndTime.split(':')
    const data = {
      name: tenant?.name || '',
      salesOfficeName: tenant?.salesOfficeName || '',
      postalCode: tenant?.postalCode || '',
      prefecture: tenant?.prefecture || '',
      municipality: tenant?.municipality || '',
      otherAddress: tenant?.otherAddress || '',
      phoneNumber: tenant?.phoneNumber || '',
      personInCharge: tenant?.personInCharge || '',
      businessStartHour: businessStartTime?.[0] || '08',
      businessStartMinute: businessStartTime?.[1] || '00',
      businessEndHour: businessEndTime?.[0] || '18',
      businessEndMinute: businessEndTime?.[1] || '00',
    }

    setInitEditData(data)
    setEditData(data)
  }, [tenant])

  React.useEffect(() => {
    const start = editData.businessStartHour + editData.businessStartMinute
    const end = editData.businessEndHour + editData.businessEndMinute
    setBusinessTimeValidity(start < end)
  }, [editData.businessStartHour, editData.businessStartMinute, editData.businessEndHour, editData.businessEndMinute])

  const { users } = useSelector(selectUsersStatus)
  const personInChargeItems: { key: string; value: string }[] = React.useMemo(
    () => users.filter(user => user.role === Role.TenantAdmin).map(user => ({ key: user.userId, value: user.name })),
    [users]
  )

  const disabled = React.useMemo(
    () =>
      !(
        nameValidity &&
        postalCodeValidity &&
        prefectureValidity &&
        municipalityValidity &&
        otherAddressValidity &&
        phoneNumberValidity &&
        businessTimeValidity &&
        personInChargeValidity
      ),
    [
      nameValidity,
      postalCodeValidity,
      prefectureValidity,
      municipalityValidity,
      otherAddressValidity,
      phoneNumberValidity,
      businessTimeValidity,
      personInChargeValidity,
    ]
  )

  const unchanged = React.useMemo(() => _.isEqual(editData, initEditData), [editData, initEditData])

  const onSubmit = () => {
    setSubmitted(true)
    dispatch(updateTenant(tenantId, editData))
  }

  const { postcodeList } = useSelector(selectPostcodeStatus)
  const foundPostalCode = React.useMemo(() => postcodeList.find(p => p.postcode === editData.postalCode), [
    editData.postalCode,
    postcodeList,
  ])
  const onAddressAutoInput = () => {
    setEditData({
      ...editData,
      prefecture: foundPostalCode?.address1 || '',
      municipality: foundPostalCode?.address2 || '',
      otherAddress: foundPostalCode?.address3 || '',
    })
  }

  React.useEffect(() => {
    // 送信前やリクエスト中の場合には抜ける
    if (!submitted || isRequesting) {
      return
    }

    if (errorMessage === '') {
      history.push(`/tenants/${tenantId}/detail`)
    } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
      setShowError(true)
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, history, tenantId])

  const onCancel = () => {
    history.push(`/tenants/${tenantId}/detail`)
  }

  return (
    <>
      <div className={styles.container}>
        <div className="sticky-top">
          <div className="font-x-large font-weight-bold text-center border-bottom py-3 bg-white">
            テナント情報の編集
          </div>
          <Notification errorMessage="保存できませんでした。" error={showError} hide={() => setShowError(false)} />
        </div>
        <div className="w-50 mx-auto mt-3 pb-3">
          <div className="d-flex justify-content-between py-1">
            <div className="font-middle font-weight-bold">テナントの詳細</div>
            <small>※必須項目</small>
          </div>
          <InputFormat
            label="企業名※"
            placeholder="企業名を入力"
            maxLength={200}
            value={editData.name}
            onChange={value => setEditData({ ...editData, name: value })}
            validations={[Rules.Required]}
            onValidate={setNameValidity}
          />
          <InputFormat
            label="事業所名"
            placeholder="事業所名を入力"
            value={editData.salesOfficeName}
            maxLength={100}
            onChange={value => setEditData({ ...editData, salesOfficeName: value })}
          />
          <InputFormat
            label="電話番号※"
            placeholder="電話番号を入力"
            value={editData.phoneNumber}
            size="short"
            maxLength={20}
            onChange={value => setEditData({ ...editData, phoneNumber: value })}
            validations={[Rules.Required, Rules.PhoneNumber]}
            onValidate={setPhoneNumberValidity}
          />

          <div className="font-middle font-weight-bold py-1">事業所住所</div>
          <PostalcodeInputForm
            label="郵便番号※"
            value={editData.postalCode}
            onChange={value => setEditData({ ...editData, postalCode: value })}
            validations={[Rules.Required, Rules.PostalCode]}
            onValidate={setPostalCodeValidity}
            onAddressAutoInput={onAddressAutoInput}
            disabledAddressAutoInput={!foundPostalCode}
          />
          <SelectBoxFormat
            label="都道府県※"
            placeholder="都道府県を選択"
            value={editData.prefecture}
            size="short"
            items={prefectureItems}
            onChange={e => setEditData({ ...editData, prefecture: e.value })}
            validations={[Rules.Required]}
            onValidate={setPrefectureValidity}
          />
          <InputFormat
            label="市区町村※"
            placeholder="市区町村を入力"
            value={editData.municipality}
            onChange={value => setEditData({ ...editData, municipality: value })}
            validations={[Rules.Required]}
            onValidate={setMunicipalityValidity}
          />
          <InputFormat
            label="それ以降の住所※"
            value={editData.otherAddress}
            onChange={value => setEditData({ ...editData, otherAddress: value })}
            validations={[Rules.Required]}
            onValidate={setOtherAddressValidity}
          />

          <div className="font-middle font-weight-bold pt-3 pb-1">営業時間</div>
          <p>
            事業所の営業時間を設定してください。設定した営業時間内で予定を設定する事ができます。早朝の出勤や残業なども考慮して設定していただく事をお勧めします。
          </p>
          <FormGroup row>
            <Label md={4}>営業開始時間※</Label>
            <Col md={8}>
              <TimeSelect
                hour={editData.businessStartHour}
                minute={editData.businessStartMinute}
                label="から"
                onChange={(hour, minute) =>
                  setEditData({ ...editData, businessStartHour: hour, businessStartMinute: minute })
                }
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4}>営業終了時間※</Label>
            <Col md={8}>
              <TimeSelect
                hour={editData.businessEndHour}
                minute={editData.businessEndMinute}
                label="まで"
                invalid={!businessTimeValidity}
                onChange={(hour, minute) =>
                  setEditData({ ...editData, businessEndHour: hour, businessEndMinute: minute })
                }
              />
              <div className={`invalid-feedback ${!businessTimeValidity && 'd-block'}`}>
                営業終了時間は営業開始時間より後の時間を設定してください
              </div>
            </Col>
          </FormGroup>

          <hr className="my-5"></hr>
          <div className="font-middle font-weight-bold py-1">担当者情報</div>
          <p>担当者は後で変更することもできます。</p>
          <SelectBoxFormat
            label="担当者※"
            placeholder="担当者を選択"
            value={editData.personInCharge}
            size="short"
            items={personInChargeItems}
            onChange={e => setEditData({ ...editData, personInCharge: e.key?.toString() || e.value })}
            validations={[Rules.Required]}
            onValidate={setPersonInChargeValidity}
          />
        </div>
      </div>
      <SubmitFooter onCancel={onCancel} onSubmit={onSubmit} submitDisabled={disabled || unchanged} />
    </>
  )
}

export default TenantEdit
