import * as React from 'react'
import { DraggableData, Position, ResizableDelta, Rnd } from 'react-rnd'
import { ResizeDirection } from 're-resizable'
import { DraggableEvent } from 'react-draggable'
import { CELL_WIDTH } from './ShiftBar'
import styles from './RndItem.module.scss'

export type OnChangeProps = {
  x: number
  width: number
}

type Props = {
  children: React.ReactNode
  onChange: (p: OnChangeProps) => void
  activeRange: Array<[number, number]>
  initialX?: number
  initialWidth?: number
  color?: string
  invertedColor?: boolean
  disabled?: boolean
  isTeam?: boolean
}

const RndItem: React.FC<Props> = ({
  children,
  onChange,
  activeRange,
  initialX = 0,
  initialWidth = 1,
  color = 'secondary-dark',
  invertedColor = false,
  disabled = false,
  isTeam = false,
}) => {
  const [item, setItem] = React.useState<OnChangeProps>({ x: initialX, width: initialWidth })
  const [ref, setRef] = React.useState<Rnd | null>(null)

  const currentRange = React.useMemo(() => {
    const start = item.x
    const end = item.width + item.x - 1
    return activeRange.find(range => (range[0] <= start && start <= range[1]) || (range[0] <= end && end <= range[1]))
  }, [activeRange, item])

  const baseActiveRange = (start: number, end: number) => {
    // 右端or左端が含まれるactiveRange
    const rangeA = activeRange.find(
      range => (range[0] <= start && start <= range[1]) || (range[0] <= end && end <= range[1])
    )
    // 右端or左端の間にactiveRangeがある場合
    const rangeB = activeRange.find(range => start < range[0] && range[1] <= end)
    // 左右端がどのrangeにも含まれない場合
    const rangeC = activeRange
      .filter(range => (start < item.x ? end < range[0] : range[1] < start))
      .sort((a, b) => Math.abs(a[1] - start) - Math.abs(b[1] - start))

    return rangeA || rangeB || rangeC[0]
  }

  const handleResizeStop = (
    _e: MouseEvent | TouchEvent,
    _dir: ResizeDirection,
    element: HTMLElement,
    _delta: ResizableDelta,
    position: Position
  ) => {
    const x = Math.round(position.x) / CELL_WIDTH
    const width = parseInt(element.style.width) / CELL_WIDTH
    const end = x + width

    // activeRangeは開始位置or終了位置で入力可能域を判定する
    const base = currentRange ?? baseActiveRange(x, end)
    if (x < base[0]) {
      const resize = width - (base[0] - x)
      setItem({ x: base[0], width: resize })
      onChange({ x: base[0], width: resize })
      ref?.updatePosition({ x: CELL_WIDTH * base[0], y: 0 })
      ref?.updateSize({ width: CELL_WIDTH * resize, height: '100%' })
    } else if (x + width > base[1] + 1) {
      const resize = x === base[0] ? base[1] - base[0] : base[1] - x
      setItem({ x, width: resize + 1 })
      onChange({ x, width: resize + 1 })
      ref?.updatePosition({ x: CELL_WIDTH * x, y: 0 })
      ref?.updateSize({ width: CELL_WIDTH * (resize + 1), height: '100%' })
    } else {
      setItem({ x, width })
      onChange({ x, width })
    }
  }

  const handleDragStop = (_e: DraggableEvent, data: DraggableData) => {
    const startX = data.lastX / CELL_WIDTH
    const endX = item.width + startX - 1

    // activeRangeは開始位置or終了位置で入力可能域を判定する
    const base = baseActiveRange(startX, endX)
    const baseWidth = Math.min(base[1] - base[0] + 1, item.width)

    if (startX < base[0]) {
      const reposition = base[0]
      const width = base[1] < endX || endX < base[0] ? baseWidth : endX - reposition + 1
      setItem({ x: reposition, width })
      onChange({ x: reposition, width })
      ref?.updatePosition({ x: CELL_WIDTH * reposition, y: 0 })
      ref?.updateSize({ width: CELL_WIDTH * width, height: '100%' })
    } else if (base[1] < endX) {
      const reposition = base[1] < startX ? base[1] - baseWidth + 1 : startX
      const width = base[1] < startX ? baseWidth : base[1] - reposition + 1
      setItem({ x: reposition, width })
      onChange({ x: reposition, width })
      ref?.updatePosition({ x: CELL_WIDTH * reposition, y: 0 })
      ref?.updateSize({ width: CELL_WIDTH * width, height: '100%' })
    } else {
      setItem({ ...item, x: startX })
      onChange({ ...item, x: startX })
    }
  }

  React.useEffect(() => {
    setItem({ x: initialX, width: initialWidth })
    ref?.updatePosition({ x: CELL_WIDTH * initialX, y: 0 })
    ref?.updateSize({ width: CELL_WIDTH * initialWidth, height: '100%' })
  }, [initialX, initialWidth, ref])

  const itemStyle = {
    color: invertedColor ? `var(--${color})` : '#ffffff',
    backgroundColor: invertedColor ? '#ffffff' : `var(--${color})`,
    boxShadow: invertedColor ? '3px 2.5px 5px var(--secondary-middle)' : '',
    border: `2px var(--${color}) solid`,
    opacity: disabled ? 0.4 : 0.8,
  }

  return (
    <Rnd
      className={styles.itemContainer}
      style={itemStyle}
      ref={rnd => setRef(rnd)}
      default={{
        x: CELL_WIDTH * initialX,
        y: 0,
        width: CELL_WIDTH * initialWidth,
        height: '100%',
      }}
      dragAxis="x"
      disableDragging={disabled || isTeam}
      enableResizing={{
        top: false,
        right: !disabled && !isTeam,
        bottom: false,
        left: !disabled && !isTeam,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      bounds="parent"
      resizeGrid={[CELL_WIDTH, 0]}
      dragGrid={[CELL_WIDTH, 0]}
      minWidth={CELL_WIDTH}
      onResizeStop={handleResizeStop}
      onDragStop={handleDragStop}
    >
      <div className={styles.innerBox}>{children}</div>
    </Rnd>
  )
}

export default RndItem
