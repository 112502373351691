import * as React from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { WorkspaceSummaryData } from 'api/dashboard'
import { PivotOuterIndex, PivotItem } from 'components/common'
import GroupPerformanceGraph from './GroupPerformanceGraph'
import WorkerPerformanceGraph from './WorkerPerformanceGraph'

type ParamProps = {
  groupId: string | undefined
  workerId: string | undefined
}

export type Props = {
  workspaceId: number
  workspaceSummaryData: WorkspaceSummaryData | undefined
  businessStartTime: string
  businessEndTime: string
}

const PerformanceGraph: React.FC<Props> = props => {
  const { workspaceId, workspaceSummaryData, businessStartTime, businessEndTime } = props
  const params = useParams<ParamProps>()
  const groupId = params.groupId ? Number(params.groupId) : undefined
  const workerId = params.workerId ? Number(params.workerId) : undefined
  const history = useHistory()

  const [pivotIndex, setPivotIndex] = React.useState(0)
  const { pathname } = useLocation()
  React.useEffect(() => {
    const nextIndex = /^\/dashboard\/\d+\/performance-graph\/workers/.test(pathname) ? 1 : 0
    setPivotIndex(nextIndex)
  }, [pathname])
  const onPivotChange = (index: number) => {
    // pivotの選択状態が変化したらpivotIndexを直接変更するのではなく、url遷移の副作用として変更する。
    const nextPath =
      index === 1
        ? `/dashboard/${workspaceId}/performance-graph/workers`
        : `/dashboard/${workspaceId}/performance-graph/workspace`
    if (pathname !== nextPath) {
      history.push(nextPath)
    }
  }

  return (
    <Card className="mt-3">
      <CardBody className="font-weight-bold">グループ/メンバー別パフォーマンスグラフ</CardBody>
      <CardBody className="pt-0">
        <PivotOuterIndex selectedIndex={pivotIndex} onChange={onPivotChange}>
          {[
            { header: 'ワークスペース/グループ', key: 'groups' },
            { header: 'メンバー', key: 'workers' },
          ].map(({ header, key }) => (
            <PivotItem headerText={header} key={key}>
              {key === 'groups' ? (
                <GroupPerformanceGraph
                  workspaceId={workspaceId}
                  groupId={groupId}
                  workspaceSummaryData={workspaceSummaryData}
                  businessStartTime={businessStartTime}
                  businessEndTime={businessEndTime}
                />
              ) : key === 'workers' ? (
                <WorkerPerformanceGraph
                  workspaceId={workspaceId}
                  workerId={workerId}
                  workspaceSummaryData={workspaceSummaryData}
                  businessStartTime={businessStartTime}
                  businessEndTime={businessEndTime}
                />
              ) : null}
            </PivotItem>
          ))}
        </PivotOuterIndex>
      </CardBody>
    </Card>
  )
}

export default PerformanceGraph
