import { AxiosError } from 'axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from 'store'
import * as API from 'api/workspaces'
import {
  makeErrorMessage,
  UNREACHABLE_ERROR_STATUS_CODE,
  UNAUTHORIZED_ERROR_STATUS_CODE,
  NOT_FOUND_ERROR_STATUS_CODE,
  CONFLICT_ERROR_STATUS_CODE,
  BAD_REQUEST_ERROR_STATUS_CODE,
} from 'api/utils'
import { commonParams } from 'slices/utils'
import * as Spinner from 'slices/spinnerSlice'
import { validateToken } from 'slices/sessionSlice'
import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'

type WorkspacesState = API.WorkspaceListResponse & {
  isRequesting: boolean
  errorMessage: string
  workerCounts: API.WorkerCountsResponse[]
  workspaceId: number | null
  templateData?: API.TemplateDataResponse
  templateList?: API.TemplateListResponse
}

const initialState: WorkspacesState = {
  isRequesting: false,
  errorMessage: '',
  workspaces: [],
  workerCounts: [],
  workspaceId: null,
}

export const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getWorkspaceListSuccess: (state, action: PayloadAction<API.WorkspaceListResponse>) => {
      state.isRequesting = false
      state.workspaces = action.payload.workspaces
      state.workspaceId = null
    },
    getWorkspaceSuccess: (state, action: PayloadAction<API.WorkspaceResponse>) => {
      state.isRequesting = false
      const index = state.workspaces.findIndex(workspace => workspace.workspaceId === action.payload.workspaceId)
      state.workspaces.splice(index, 1, action.payload)
      state.workspaceId = action.payload.workspaceId
    },
    getWorkerCountsSuccess: (state, action: PayloadAction<API.WorkerCountsResponse[]>) => {
      state.isRequesting = false
      state.workerCounts = action.payload
    },
    createWorkspaceSuccess: (state, action: PayloadAction<API.WorkspaceResponse>) => {
      state.isRequesting = false
      state.workspaces.push(action.payload)
    },
    updateWorkspaceSuccess: (state, action: PayloadAction<API.WorkspaceResponse>) => {
      state.isRequesting = false
      state.workspaces = state.workspaces.map(workspace =>
        workspace.workspaceId === action.payload.workspaceId ? action.payload : workspace
      )
    },
    deleteWorkspaceSuccess: (state, action: PayloadAction<number>) => {
      state.isRequesting = false
      state.workspaces = state.workspaces.filter(workspace => workspace.workspaceId !== action.payload)
    },
    getTemplateListSuccess: (state, action: PayloadAction<API.TemplateListResponse>) => {
      state.isRequesting = false
      state.templateList = action.payload
    },
    getTemplateDataSuccess: (state, action: PayloadAction<API.TemplateDataResponse>) => {
      state.isRequesting = false
      state.templateData = action.payload
    },
    updateTemplateDataSuccess: (state, action: PayloadAction<API.TemplateDataResponse>) => {
      state.isRequesting = false
      state.templateData = action.payload
    },
    clearTemplateList: state => {
      state.templateList = undefined
    },
    clearWorkspaces: state => {
      state.workspaces = []
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getWorkspaceListSuccess,
  getWorkspaceSuccess,
  getWorkerCountsSuccess,
  createWorkspaceSuccess,
  updateWorkspaceSuccess,
  deleteWorkspaceSuccess,
  getTemplateListSuccess,
  getTemplateDataSuccess,
  updateTemplateDataSuccess,
  clearTemplateList,
  clearWorkspaces,
} = workspacesSlice.actions

export const getWorkspaceList = (tenantId?: number): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getWorkspaceList(commonParams(getState), tenantId)
    .then((res: API.WorkspaceListResponse) => dispatch(getWorkspaceListSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const getWorkspace = (workspaceId: number): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getWorkspace(commonParams(getState), workspaceId)
    .then((res: API.WorkspaceResponse) => dispatch(getWorkspaceSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const getWorkerCounts = (workspaceIds: number[], date: string): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  const promises: Promise<API.WorkerCountsResponse>[] = workspaceIds.map(workspaceId =>
    API.getWorkerCounts(commonParams(getState), workspaceId, date)
  )

  await Promise.all(promises)
    .then((res: API.WorkerCountsResponse[]) => dispatch(getWorkerCountsSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const createWorkspace = (data: API.EditWorkspaceProps): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.createWorkspace(commonParams(getState), data)
    .then((res: API.WorkspaceResponse) => dispatch(createWorkspaceSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const updateWorkspace = (workspaceId: number, data: API.EditWorkspaceProps): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.updateWorkspace(commonParams(getState), workspaceId, data)
    .then((res: API.WorkspaceResponse) => dispatch(updateWorkspaceSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const deleteWorkspace = (workspaceId: number): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.deleteWorkspace(commonParams(getState), workspaceId)
    .then(() => dispatch(deleteWorkspaceSuccess(workspaceId)))
    .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
    .finally(() => dispatch(Spinner.stop()))
}
export const getTemplateList = (workspaceId: number): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getTemplateList(commonParams(getState), workspaceId)
    .then((res: API.TemplateListResponse) => dispatch(getTemplateListSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}
export const getTemplateData = (workspaceId: number, templateId: number): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getTemplateData(commonParams(getState), workspaceId, templateId)
    .then((res: API.TemplateDataResponse) => dispatch(getTemplateDataSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === NOT_FOUND_ERROR_STATUS_CODE) {
        return dispatch(apiFailure({ errorMessage: '存在しないテンプレートです｡' }))
      }
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

const SAME_TEMPLATE_NAME_ERROR = 'this name is already taken'
const TEMPLATE_NAME_LENGTH_ERROR = 'this name is incorrect'
const TEMPLATE_LIMIT_ERROR = 'upper limit of 20 has been reached'

export const updateTemplateData = (workspaceId: number, data: API.EditTemplateProps): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.updateTemplateData(commonParams(getState), workspaceId, data)
    .then((res: API.TemplateDataResponse) => dispatch(updateTemplateDataSuccess(res)))
    .catch((res: AxiosError) => {
      const errorMessage = res.response?.data?.message
      const errorCode = makeErrorMessage(res)

      if (errorCode === CONFLICT_ERROR_STATUS_CODE && errorMessage === TEMPLATE_LIMIT_ERROR) {
        return dispatch(apiFailure({ errorMessage: '登録可能なテンプレート数を超えているため､登録できませんでした｡' }))
      }
      if (errorCode === CONFLICT_ERROR_STATUS_CODE && errorMessage === SAME_TEMPLATE_NAME_ERROR) {
        return dispatch(
          apiFailure({
            errorMessage: 'すでにこの名前のテンプレートは存在しています。',
          })
        )
      }
      if (errorCode === BAD_REQUEST_ERROR_STATUS_CODE && errorMessage === TEMPLATE_NAME_LENGTH_ERROR) {
        return dispatch(
          apiFailure({ errorMessage: 'テンプレート名の文字数が不正です｡1文字以上､20文字以下で登録してください｡' })
        )
      }
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const selectWorkspacesStatus = (state: RootState) => ({ ...state.workspaces })

export default workspacesSlice.reducer
