import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'
import { PlanAccuracyRow } from 'api/reports'
import { getReportPlanAccuracy, selectReportsStatus } from 'slices/reportsSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getTenant } from 'slices/tenantsSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'
import { NavMenu, BadgeButton, Chart, ScrollToTopOnMount } from 'components/common'
import { Series } from 'components/common/types'
import { createLineChartOptions } from 'components/common/utils'
import { createXAxis, colorTypeToCode } from 'components/Dashboard/utils'
import useBusinessTime from 'hooks/useBusinessTime'

const PlanAccuracyDetail: React.FC = () => {
  const params = useParams<{ workspaceId: string; date: string }>()
  const workspaceId = Number(params.workspaceId)
  const { date } = params

  const [selectedBadgeKeys, setSelectedBadgeKeys] = React.useState<number[]>([])
  const formatDate = moment(date).format('YYYY/MM/DD（dddd）')
  const history = useHistory()
  const dispatch = useDispatch()

  const { user } = useSelector(selectSessionStatus)
  React.useEffect(() => {
    dispatch(getTenant(user.tenants[0].tenantId))
  }, [dispatch, user])
  const { businessStartTime, businessEndTime } = useBusinessTime()

  React.useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])
  const { workspaces } = useSelector(selectWorkspacesStatus)

  React.useEffect(() => {
    if (!workspaceId) {
      return
    }
    const from = moment(date).format('YYYY-MM-DD')
    const to = moment(date).format('YYYY-MM-DD')
    dispatch(getReportPlanAccuracy(workspaceId, from, to))
  }, [dispatch, workspaceId, date])
  const { planAccuracies } = useSelector(selectReportsStatus)

  const menuItems = React.useMemo(
    () =>
      _.chain(workspaces)
        .map(w => ({ type: w.workspaceId, label: w.name }))
        .sortBy('label')
        .value(),
    [workspaces]
  )

  const badgeItems = React.useMemo(() => {
    return _.chain(planAccuracies)
      .map(planAccuracy => ({
        color: planAccuracy.scheduleTypeColor,
        key: planAccuracy.scheduleTypeId,
        label: planAccuracy.scheduleTypeName,
      }))
      .uniqBy('key')
      .sortBy('key')
      .value()
  }, [planAccuracies])

  React.useEffect(() => {
    // badgeItemsが更新されたら全選択
    setSelectedBadgeKeys(badgeItems.map(badgeItem => badgeItem.key))
  }, [badgeItems])

  const formatter: Highcharts.TooltipFormatterCallbackFunction = function () {
    const planAccuracyRows: PlanAccuracyRow[] = this.series.options.custom?.planAccuracyRows
    const unit = this.series.options.custom?.unit
    const row = planAccuracyRows.find(r => moment(r.time).isSame(this.x))
    const lastPlan = row?.lastPlanCount ? row.lastPlanCount.toLocaleString() : '-'
    const archivePlan = row?.archivePlanCount ? row.archivePlanCount.toLocaleString() : '-'
    return `<div style="display: flex; flex-direction: column; align-items: center;">
              <div>${moment(this.x).format('HH:mm')}</div>
              <div>最終計画：${lastPlan} ${unit}</div>
              <div>アーカイブ：${archivePlan} ${unit}</div>
            </div>`
  }

  const chartOptions = React.useMemo(() => {
    const selectedBadges = badgeItems.filter(badge => selectedBadgeKeys.includes(badge.key))

    // 20:45のようなケースでは20:00に集計されるため、分を切り捨ててxAxisを作成する
    const fixBusinessEndTime = moment(businessEndTime, 'HH:mm').startOf('hour').format('HH:mm')
    const xAxisData = createXAxis(businessStartTime, fixBusinessEndTime, false, date)

    const yAxis: Series[] = []
    selectedBadges.forEach(graphBadge => {
      const colorCode = colorTypeToCode(graphBadge.color)

      const unit = planAccuracies.find(planAccuracy => planAccuracy.scheduleTypeId === graphBadge.key)?.unit || ''
      const planAccuracyRows = planAccuracies
        .filter(planAccuracy => planAccuracy.scheduleTypeId === graphBadge.key)
        .flatMap(planAccuracy => planAccuracy.data)

      // areaの上にlineを表示するためにareaを先にしておく
      const archivePlanData = xAxisData.map(time => {
        // パフォーマンス改善のために moment でなく new Date を使う
        // row.time も time も UTC なので文字列として比較しても大丈夫そうだが、安全のため new Date を通す
        const target = planAccuracyRows.find(row => new Date(row.time).getTime() === new Date(time).getTime())
        return target ? target.archivePlanCount : null
      })
      yAxis.push({
        type: 'area',
        color: colorCode,
        data: archivePlanData,
        name: graphBadge.label,
        custom: { planAccuracyRows, unit }, // tooltipに最終計画とアーカイブのペアを表示するため、customに格納
      })

      const lastPlanData = xAxisData.map(time => {
        // パフォーマンス改善のために moment でなく new Date を使う
        const target = planAccuracyRows.find(row => new Date(row.time).getTime() === new Date(time).getTime())
        return target ? target.lastPlanCount : null
      })
      yAxis.push({
        type: 'line',
        color: colorCode,
        data: lastPlanData,
        name: graphBadge.label,
        custom: { planAccuracyRows, unit }, // tooltipに最終計画とアーカイブのペアを表示するため、customに格納
      })
    })

    const options = createLineChartOptions({
      xAxis: {
        data: xAxisData,
      },
      yAxis,
    })
    _.merge(options, {
      xAxis: {
        labels: {
          step: 2,
        },
        tickInterval: 1,
      },
      tooltip: { useHTML: true, formatter },
    })
    options?.series?.forEach(option => {
      if (option.type === 'area') {
        _.merge(option, { type: 'line' })
      }
    })
    return options
  }, [planAccuracies, badgeItems, selectedBadgeKeys, date, businessStartTime, businessEndTime])

  const onNavMenuClick = (menuType: number) => {
    if (menuType !== workspaceId) {
      history.push(`/reports/${menuType}/${date}`)
    }
  }

  return (
    <>
      <ScrollToTopOnMount />

      <NavMenu type={workspaceId} items={menuItems} onNavMenuClick={onNavMenuClick}>
        <div className="mt-3 mx-3">
          <Link to="/reports" className="d-flex align-items-center text-secondary pb-2">
            <i className="icf-chevron_left" />
            戻る
          </Link>
          <div className="d-flex mb-3">
            <div className="font-x-large font-weight-bold mr-2">{formatDate}の計画変更詳細</div>
          </div>

          <Card className="mb-3">
            <CardBody className="font-weight-bold">{formatDate}の詳細</CardBody>
            <CardBody>
              <Chart options={chartOptions} />
            </CardBody>
            <CardBody className="d-flex row my-2">
              <BadgeButton
                items={badgeItems}
                selected={selectedBadgeKeys}
                onChange={selected => setSelectedBadgeKeys(selected)}
              />
            </CardBody>
          </Card>
        </div>
      </NavMenu>
    </>
  )
}

export default PlanAccuracyDetail
