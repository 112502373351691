import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import sessionReducer from 'slices/sessionSlice'
import skillsReducer from 'slices/skillsSlice'
import usersReducer from 'slices/usersSlice'
import tenantsReducer from 'slices/tenantsSlice'
import workersReducer from 'slices/workersSlice'
import workspacesReducer from 'slices/workspacesSlice'
import worksReducer from 'slices/worksSlice'
import worksLiteReducer from 'slices/worksLiteSlice'
import scheduleTypesReducer from 'slices/scheduleTypesSlice'
import groupsReducer from 'slices/groupsSlice'
import shiftReducer from 'slices/shiftSlice'
import reportsReducer from 'slices/reportsSlice'
import spinnerReducer from 'slices/spinnerSlice'
import networkErrorDialogReducer from 'slices/networkErrorDialogSlice'
import sessionTimeoutDialogReducer from 'slices/sessionTimeoutDialogSlice'
import notificationReducer from 'slices/notificationSlice'
import postcodeReducer from 'slices/postcodeSlice'
import optimizationReducer from 'slices/optimizationSlice'
import workResultsReducer from 'slices/workResultsSlice'
export const store = configureStore({
  reducer: {
    session: sessionReducer,
    skills: skillsReducer,
    tenants: tenantsReducer,
    workers: workersReducer,
    workspaces: workspacesReducer,
    works: worksReducer,
    worksLite: worksLiteReducer,
    scheduleTypes: scheduleTypesReducer,
    groups: groupsReducer,
    users: usersReducer,
    shift: shiftReducer,
    reports: reportsReducer,
    spinner: spinnerReducer,
    networkErrorDialog: networkErrorDialogReducer,
    sessionTimeoutDialog: sessionTimeoutDialogReducer,
    notification: notificationReducer,
    postcode: postcodeReducer,
    optimization: optimizationReducer,
    workResults: workResultsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
