export type { BadgeItem } from './BadgeButton/BadgeButton'
export type { CardRadioButtonItem } from './CardRadioButton/CardRadioButton'
export type { ListItem } from './List/List'
export type { ShiftBarItemType } from './ShiftBar/ShiftBar'
export type { SingleBarItem } from './SingleBar/SingleBar'
export type { SuggestionItem } from './FormFormat/Suggestion'
export type { TableHeaderType, TableCellType } from './Table/Table'
export type { VerticalItem } from './VerticalTable/VerticalTable'
export type { Series } from './Chart/Chart'
export type { ColorType } from './ColorPicker/ColorPicker'
export type { MultipleFooterItem } from './Team/MultipleFooter'
export type { SelectedScheduleType } from './Team/WorksSelector'
export type { TableItemType, ItemScheduleType } from './AssignedNumberTable/AssignedNumberTable'
export type { FilterItem } from './CheckboxGroup/CheckboxGroup'

export type EditSchedule = {
  scheduleId: number | null
  scheduleTypeId: number
  supportWorkspaceId: number | null
  supportWorkspaceName: string | null
  startAt: string
  duration: number
}

export const CharacterCodeTypes = {
  shiftJis: 'SHIFT_JIS',
  utf8: 'UTF-8',
} as const

export type TimeReducerType = {
  time: string
  planCount: number | null
  recordCount: number | null
  rate: number | null
}

export type CharacterCodeType = typeof CharacterCodeTypes[keyof typeof CharacterCodeTypes]
