import _ from 'lodash'
import * as React from 'react'
import { Button, Card, CardBody, CardText } from 'reactstrap'
import { InputGroupFormat } from 'components/common/FormFormat/FormFormat'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import usePerformanceIndicesInput from 'hooks/usePerformanceIndicesInput'

export type PerformanceIndicesInputType = {
  [scheduleTypeId: string]: string | undefined
}

export type Props = {
  workerId?: number
  forecastColorScheduleTypeIds?: number[]
  onFocus?: (forecastColorScheduleTypeIds: number[]) => void
  performanceIndices: PerformanceIndicesInputType
  onChange: (performanceIndices: PerformanceIndicesInputType) => void
  initialPerformanceIndices?: PerformanceIndicesInputType
}
const PerformanceIndicesInput: React.FC<Props> = props => {
  const {
    performanceList,
    forecastPerformanceIndices,
    connectionTypeAutoExistsInTenant,
    forecastPerformanceIndicesDisabled,
    getBackgroundColor,
    onSetForecastPerformanceIndices,
    handleFocus,
    handleChange,
  } = usePerformanceIndicesInput(props)

  return (
    <Card>
      {connectionTypeAutoExistsInTenant && !forecastPerformanceIndicesDisabled && (
        <CardBody className="d-flex">
          <div className="flex-grow-1 align-self-center">過去実績最大30日の平均値を自動入力</div>
          <Button outline onClick={onSetForecastPerformanceIndices} disabled={_.isEmpty(forecastPerformanceIndices)}>
            過去実績から自動入力
          </Button>
        </CardBody>
      )}

      {performanceList.map(w => (
        <div key={w.workspaceId}>
          <hr className="m-0" />
          <CardBody>
            <CardText className="font-weight-bold">{w.name}</CardText>
            {w.scheduleTypes.map(s => (
              <InputGroupFormat
                key={s.scheduleTypeId}
                value={props.performanceIndices[s.scheduleTypeId] || ''}
                maxLength={8}
                label={s.name}
                addonText={`${s.unit || '-'}/時間`}
                onChange={value => handleChange(value, s)}
                backgroundColor={getBackgroundColor(s)}
                onFocus={() => handleFocus(s.scheduleTypeId)}
                validations={[Rules.PositiveInteger]}
              />
            ))}
          </CardBody>
        </div>
      ))}
    </Card>
  )
}

export default PerformanceIndicesInput
