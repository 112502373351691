import moment from 'moment'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CONFLICT_ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'
import { EditWorkspaceProps } from 'api/workspaces'
import { clearErrorMessage, createWorkspace, selectWorkspacesStatus } from 'slices/workspacesSlice'
import { InputFormat, Notification, SelectBoxFormat, SubmitFooter } from 'components/common'
import { SuggestionItem } from 'components/common/FormFormat/Suggestion'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import WorkspaceAdminEdit from './WorkspaceAdminEdit'
import RelatedWorkspaceEdit from './RelatedWorkspaceEdit'
import styles from './WorkspaceCreate.module.scss'

type WorkspaceCreateType = {
  name: string | undefined
  admins: SuggestionItem[]
  members: SuggestionItem[]
  relatedWorkspaces: { id: number; value: string }[]
  autoArchiveTime: string
}

const initData: WorkspaceCreateType = {
  name: undefined,
  admins: [],
  members: [],
  relatedWorkspaces: [],
  autoArchiveTime: '08:00',
}

const WorkspaceCreate: React.FC = () => {
  const [editData, setEditData] = React.useState<WorkspaceCreateType>(initData)
  const [nameValidity, setNameValidity] = React.useState(false)
  const [notificationErrorMessage, setNotificationErrorMessage] = React.useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = React.useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const { isRequesting, errorMessage } = useSelector(selectWorkspacesStatus)

  const disabled = React.useMemo(() => !(editData.name && nameValidity), [editData, nameValidity])

  const archiveTimeItems = [...Array(48)].map((_, i) => {
    const h = moment()
      .startOf('day')
      .add(i * 30, 'minutes')
      .format('HH:mm')
    return { value: h }
  })

  const onSubmit = () => {
    setSubmitted(true)

    const data: EditWorkspaceProps = {
      name: editData.name || '',
      memberIds: editData.admins.concat(editData.members).map(member => member.id.toString()),
      relatedWorkspaceIds: editData.relatedWorkspaces.map(workspace => workspace.id),
      autoArchiveTime: editData.autoArchiveTime,
    }
    dispatch(createWorkspace(data))
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }

    setSubmitted(false)

    // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
    if (ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      return
    }

    if (errorMessage === '') {
      history.push('/workspaces')
    } else {
      if (errorMessage === CONFLICT_ERROR_STATUS_CODE) {
        setNotificationErrorMessage('すでにこの名前のワークスペースは存在しています。')
      } else {
        setNotificationErrorMessage('保存できませんでした。')
      }
      dispatch(clearErrorMessage())
    }
  }, [submitted, isRequesting, errorMessage, dispatch, history])

  return (
    <>
      <div className={styles.container}>
        <div className="sticky-top">
          <div className="font-x-large font-weight-bold text-center border-bottom py-3 bg-white">
            ワークスペースの登録
          </div>
          <Notification
            errorMessage={notificationErrorMessage}
            error={!!notificationErrorMessage}
            hide={() => setNotificationErrorMessage(undefined)}
          />
        </div>
        <div className="w-50 mx-auto mt-3 pb-1">
          <div className="d-flex justify-content-between py-1">
            <div className="font-middle font-weight-bold">ワークスペースの詳細</div>
            <small>※必須項目</small>
          </div>
          <InputFormat
            label="名称※"
            placeholder="ワークスペース名を入力"
            value={editData.name}
            maxLength={100}
            onChange={value => setEditData({ ...editData, name: value })}
            validations={[Rules.Required]}
            onValidate={setNameValidity}
          />

          <div className="font-middle font-weight-bold py-1 mt-5">ワークスペース管理者の登録</div>
          <div className="mt-2 mb-1">
            ワークスペースの管理者を登録します。オーナーは初期状態で管理者として登録されています。オーナーを管理者から除外することはできません。
          </div>
          <WorkspaceAdminEdit
            admins={editData.admins}
            members={editData.members}
            onChange={(admins, members) => setEditData({ ...editData, admins, members })}
          />

          <div className="font-middle font-weight-bold py-1 mt-5">連携ワークスペースの設定</div>
          <div className="mt-2 mb-1">
            このワークスペースの前工程となるワークスペース、後工程となるワークスペースを設定しておくことで、ダッシュボードから設定したワークスペースの進捗を確認することができます。
          </div>
          <div className="mt-3">連携するワークスペース</div>
          <RelatedWorkspaceEdit
            relatedWorkspaces={editData.relatedWorkspaces}
            onChange={relatedWorkspaces => setEditData({ ...editData, relatedWorkspaces })}
          />

          <div className="font-middle font-weight-bold py-1 mt-5">作業計画の自動アーカイブ</div>
          <div className="mt-2 mb-1">
            作業計画を自動アーカイブすることで、アーカイブされた作業計画と最終的な作業計画の変更割合を計画変更率として算出します。計画変更率は「レポート」で確認できます。デフォルトでは8:00が設定されています。
          </div>
          <div className="mt-3">
            <SelectBoxFormat
              label="自動アーカイブ時刻"
              value={editData.autoArchiveTime}
              size="short"
              items={archiveTimeItems}
              onChange={e => setEditData({ ...editData, autoArchiveTime: e.value })}
            />
          </div>
        </div>
      </div>
      <SubmitFooter onCancel={() => history.goBack()} onSubmit={onSubmit} submitDisabled={disabled} />
    </>
  )
}

export default WorkspaceCreate
