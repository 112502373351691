import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { Button } from 'reactstrap'
import { UpdateWorkSchedule } from 'api/works'
import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getTenant } from 'slices/tenantsSlice'
import { getWorkByDate, updateWork, selectWorksStatus } from 'slices/worksSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'
import {
  TimeScale,
  WorkerIcon,
  MultipleFooter,
  WorksSelector,
  WorkerPopover,
  ShiftBar,
  SubmitFooter,
} from 'components/common'
import { ColorTypes } from 'components/common/utils'
import { MultipleFooterItem, EditSchedule, ColorType, SelectedScheduleType } from 'components/common/types'
import {
  getShiftBarWidthByDuration,
  getEditSchedules,
  getOriginalSchedulesFromWork,
  getUpdateWorkerSchedules,
  toFitDuringShiftTime,
  SHIFT_SCHEDULE_TYPE_ID,
  SUPPORT_SCHEDULE_TYPE_ID,
} from 'components/common/utils'
import EditChangesDiscardDialog from 'components/EditChangesDiscardDialog/EditChangesDiscardDialog'
import SupportConfirm from 'components/SupportConfirm/SupportConfirm'
import useWorkNotification from 'hooks/useWorkNotification'
import useBusinessTime from 'hooks/useBusinessTime'
import TeamWorkPlanCard, { TeamWorkPlanCardItem } from './TeamWorkPlanCard'
import ShiftInputScale, { ScheduleType, InputScheduleType } from './ShiftInputScale'
import styles from './TeamWorkPlan.module.scss'

export type WorkPlanSchedulesType = EditSchedule & {
  editable: boolean
}

type ParamProps = {
  date: string
}

type CurrentSchedule = {
  workerId: number
  schedule: WorkPlanSchedulesType
  scheduleType: SelectedScheduleType
}

type EditWorkerSchedule = {
  workerId: number
  name: string
  schedules: WorkPlanSchedulesType[]
  selected: boolean
}

const TeamWorkPlan: React.FC = () => {
  const [viewState, setViewState] = React.useState(false)
  const [inputMode, setInputMode] = React.useState(false)
  const [selectorOpen, setSelectorOpen] = React.useState(false)
  const [editChangesDiscardDialogOpen, setEditChangesDiscardDialogOpen] = React.useState(false)
  const [currentSchedule, setCurrentSchedule] = React.useState<CurrentSchedule | undefined>()
  const [selectedScheduleType, setSelectedScheduleType] = React.useState<ScheduleType | undefined>()
  const [workers, setWorkers] = React.useState<EditWorkerSchedule[]>([])
  const [initWorkers, setInitWorkers] = React.useState<EditWorkerSchedule[]>([])
  const [inputSchedule, setInputSchedule] = React.useState<InputScheduleType | undefined>()
  const [openSupportConfirm, setOpenSupportConfirm] = React.useState(false)
  const [onApprove, setOnApprove] = React.useState<Function>(() => {})

  const {
    user,
    team: { workspaceId, groupId },
  } = useSelector(selectSessionStatus)
  const { works } = useSelector(selectWorksStatus)
  const { scheduleTypes } = useSelector(selectScheduleTypesStatus)
  const { workspaces } = useSelector(selectWorkspacesStatus)

  const { date } = useParams<ParamProps>()
  const history = useHistory()
  const dispatch = useDispatch()

  const { setSubmitted } = useWorkNotification()

  const work = React.useMemo(() => works.find(w => w.date === date), [date, works])

  const {
    businessStartTime,
    businessEndTime,
    businessDuration,
    getTimesByShiftBarX,
    getShiftBarXbyStartTime,
  } = useBusinessTime()

  React.useEffect(() => {
    dispatch(getWorkspaceList())
    dispatch(getWorkByDate(workspaceId, date, false))
    dispatch(getScheduleTypeList(workspaceId))
  }, [dispatch, workspaceId, date])

  React.useEffect(() => {
    dispatch(getTenant(user.tenants[0].tenantId))
  }, [dispatch, user])

  React.useEffect(() => {
    const newWorkers =
      work?.groups
        .find(g => g.groupId === groupId)
        ?.workers.map(w => {
          const schedules = getEditSchedules(w.schedules).map(s => ({
            ...s,
            editable: true,
          }))
          return {
            workerId: w.workerId,
            name: w.name,
            schedules,
            selected: false,
          }
        }) || []
    setInitWorkers(newWorkers)
    setWorkers(newWorkers)
  }, [groupId, work])

  const shifts = React.useMemo(
    () =>
      works
        .find(w => w.date === date)
        ?.groups.flatMap(g =>
          g.workers.flatMap(w => w.schedules.filter(s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID))
        ),
    [date, works]
  )

  const workPlanCardItems = React.useMemo(() => {
    return scheduleTypes.reduce((acc: TeamWorkPlanCardItem[], cur) => {
      const workPlan = works.find(w => w.date === date)?.workPlan.find(w => w.scheduleTypeId === cur.scheduleTypeId)
      if (cur.dataConnection) {
        acc.push({
          label: cur.name,
          color: cur.color,
          targetValue: workPlan?.targetValue || 0,
          planValue: workPlan?.planValue || 0,
        })
      }
      return acc
    }, [])
  }, [scheduleTypes, works, date])

  const title = React.useMemo(() => `${moment(date).format('YYYY/MM/DD（dddd）')}の作業計画`, [date])

  const isSupport = React.useMemo(() => selectedScheduleType?.color === 'secondary', [selectedScheduleType?.color])
  const inputStartAt = React.useMemo(() => {
    if (inputSchedule) {
      const { hours, minutes } = getTimesByShiftBarX(inputSchedule.startX)
      return moment(`${date} ${hours}:${minutes}`, 'YYYY-MM-DD HH:mm').utc().format()
    }
    return ''
  }, [getTimesByShiftBarX, date, inputSchedule])
  const inputDuration = React.useMemo(
    () => (inputSchedule ? (inputSchedule.endX - inputSchedule.startX + 1) * 900 : 0),
    [inputSchedule]
  )
  const start = React.useMemo(() => {
    if (inputMode) {
      return moment(inputStartAt).format('HH:mm')
    }
    return moment(currentSchedule?.schedule.startAt).format('HH:mm')
  }, [inputStartAt, currentSchedule, inputMode])
  const end = React.useMemo(() => {
    if (inputMode) {
      return moment(inputStartAt).add(inputDuration, 'seconds').format('HH:mm')
    }
    return moment(currentSchedule?.schedule.startAt).add(currentSchedule?.schedule.duration, 'seconds').format('HH:mm')
  }, [inputDuration, inputStartAt, currentSchedule, inputMode])

  const updateWorkerSchedules = React.useCallback(
    (
      workerId: number,
      scheduleTypeId: number,
      supportWorkspaceId: number | null,
      supportWorkspaceName: string | null,
      schedules: WorkPlanSchedulesType[]
    ) => {
      const shiftData = _.chain(shifts).filter({ workerId }).sortBy(['startAt']).value()
      const { startAt, duration } = toFitDuringShiftTime(inputStartAt, inputDuration, shiftData, false)

      if (duration <= 0) {
        return schedules
      }

      return getUpdateWorkerSchedules(
        startAt,
        duration,
        scheduleTypeId,
        supportWorkspaceId,
        supportWorkspaceName,
        schedules
      ).map(s => ({
        ...s,
        editable: true,
      }))
    },
    [shifts, inputStartAt, inputDuration]
  )

  const addSchedule = React.useCallback(() => {
    if (!selectedScheduleType) {
      return
    }

    const scheduleTypeId = isSupport ? SUPPORT_SCHEDULE_TYPE_ID : selectedScheduleType.scheduleTypeId
    const supportWorkspaceId = isSupport ? selectedScheduleType.scheduleTypeId : null
    const supportWorkspaceName = isSupport ? selectedScheduleType.name : null

    const newWorkers = workers.map(w => {
      if (w.selected) {
        return {
          ...w,
          selected: false,
          schedules: updateWorkerSchedules(
            w.workerId,
            scheduleTypeId,
            supportWorkspaceId,
            supportWorkspaceName,
            w.schedules
          ),
        }
      }
      return w
    })

    setWorkers(newWorkers)
    setInputMode(false)
    setSelectedScheduleType(undefined)
    setInputSchedule(undefined)
  }, [isSupport, selectedScheduleType, workers, updateWorkerSchedules])

  const deleteWorkerSchedule = React.useCallback(
    (workerId: number, scheduleId: number | null) => {
      const newWorkers = workers.map(w => ({
        ...w,
        schedules: w.schedules.filter(s => w.workerId !== workerId || s.scheduleId !== scheduleId),
      }))
      setWorkers(newWorkers)
    },
    [workers]
  )

  const handleOpenSupportConfirm = (func: Function, support: boolean) => {
    setOnApprove(() => func)
    if (support) {
      setOpenSupportConfirm(true)
    } else {
      func()
    }
  }

  const footer = React.useMemo((): MultipleFooterItem => {
    const disabled = workers.every(w => !w.selected) || !!selectedScheduleType
    if (selectorOpen) {
      return {
        disabled,
        stepNumber: 2,
        stepText: '作業内容を選択',
        decisionButtonClick: () => null,
      }
    }
    if (!selectorOpen && !!selectedScheduleType) {
      return {
        disabled: !inputSchedule,
        stepNumber: 3,
        stepText: '時間帯を選択',
        decisionButtonClick: () =>
          handleOpenSupportConfirm(() => {
            addSchedule()
          }, isSupport),
      }
    }
    return {
      disabled,
      stepNumber: 1,
      stepText: 'スケジュールを変更したいユーザーを選択',
      decisionButtonClick: () => setSelectorOpen(true),
    }
  }, [addSchedule, inputSchedule, selectedScheduleType, selectorOpen, workers, isSupport])

  const workerActiveRange = (schedules: WorkPlanSchedulesType[]) => {
    const sortedShifts = _.chain(schedules)
      .filter({ scheduleTypeId: SHIFT_SCHEDULE_TYPE_ID })
      .sortBy(['startAt'])
      .value()
    if (sortedShifts.length === 0) {
      return undefined
    }
    const range: Array<[number, number]> = sortedShifts.map(s => {
      const startX = getShiftBarXbyStartTime(s.startAt)
      const endX = getShiftBarWidthByDuration(s.duration) + startX - 1
      return [startX, endX]
    })
    return range
  }

  const WorkerPopoverClickHandler = (
    workerId: number,
    schedule: WorkPlanSchedulesType,
    scheduleType: SelectedScheduleType
  ) => {
    setCurrentSchedule({ workerId, schedule, scheduleType })
    setSelectorOpen(true)
  }

  const getShiftBarItems = (schedules: WorkPlanSchedulesType[], workerId: number) => {
    return schedules
      .filter(d => d.scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID)
      .map((d, index) => {
        const x = getShiftBarXbyStartTime(d.startAt)
        const width = getShiftBarWidthByDuration(d.duration)

        const support = d.scheduleTypeId === SUPPORT_SCHEDULE_TYPE_ID
        const scheduleType = support
          ? { scheduleTypeId: d.supportWorkspaceId || 0, name: d.supportWorkspaceName || '', color: undefined }
          : _.chain(scheduleTypes)
              .find(s => s.scheduleTypeId === d.scheduleTypeId)
              .pick(['scheduleTypeId', 'name', 'color'])
              .value()

        return {
          id: `item-${groupId}-${index}`,
          content: (
            <WorkerPopover
              startAt={d.startAt}
              duration={d.duration}
              scheduleType={scheduleType}
              disabled={inputMode || !d.editable}
              onClick={() => WorkerPopoverClickHandler(workerId, d, scheduleType)}
              onDelete={() => deleteWorkerSchedule(workerId, d.scheduleId)}
            >
              <div className="mh-100">{scheduleType.name}</div>
            </WorkerPopover>
          ),
          x,
          width,
          color: scheduleType?.color,
          disabled: !d.editable,
        }
      })
  }

  const selectWorker = (workerId: number) => {
    if (!inputMode || footer.stepNumber !== 1) {
      return
    }
    const newWorkers = workers.map(w => (w.workerId === workerId ? { ...w, selected: !w.selected } : w))
    setWorkers(newWorkers)
  }

  const moveToWorkerDetail = (workerId: number) => {
    if (inputMode) {
      return
    }

    history.push(`/team-worker/${workerId}`)
  }

  const cancelInputMode = () => {
    setSelectorOpen(false)
    setInputMode(false)
    setCurrentSchedule(undefined)
    setSelectedScheduleType(undefined)
    setInputSchedule(undefined)
    setWorkers(workers.map(d => ({ ...d, selected: false })))
  }

  const selectorWorkClick = (id: number, name: string, color?: ColorType) => {
    const workspace = !color ? workspaces.find(w => w.workspaceId === id) : undefined
    const scheduleTypeId = !color ? SUPPORT_SCHEDULE_TYPE_ID : id
    const supportWorkspaceId = !color ? id : null
    const supportWorkspaceName = workspace?.name || null

    if (inputMode) {
      if (!color) {
        setSelectedScheduleType(
          workspace ? { scheduleTypeId: workspace.workspaceId, name: workspace.name, color: 'secondary' } : workspace
        )
      } else {
        setSelectedScheduleType(
          _.chain(scheduleTypes)
            .find(s => s.scheduleTypeId === id)
            .pick(['scheduleTypeId', 'name', 'color'])
            .value()
        )
      }
    } else {
      const newWorkers = workers.map(cur => {
        if (cur.workerId === currentSchedule?.workerId) {
          return {
            ...cur,
            schedules: cur.schedules.map(s =>
              _.isEqual(s, currentSchedule.schedule)
                ? { ...s, scheduleTypeId, supportWorkspaceId, supportWorkspaceName }
                : s
            ),
          }
        }
        return cur
      })
      if (!color) {
        setSelectedScheduleType(
          workspace ? { scheduleTypeId: workspace.workspaceId, name: workspace.name, color: 'secondary' } : workspace
        )
      }
      handleOpenSupportConfirm(() => {
        setWorkers(newWorkers)
        setCurrentSchedule(undefined)
        setSelectedScheduleType(undefined)
      }, !color)
    }
    setSelectorOpen(false)
  }

  const shiftInputScaleClick = (workerId: number, x: number) => {
    if (!inputSchedule || inputSchedule.workerId !== workerId || inputSchedule.startX !== inputSchedule.endX) {
      setInputSchedule({ workerId, startX: x, endX: x })
    } else {
      setInputSchedule({ ...inputSchedule, endX: x })
    }
  }

  const unchanged = React.useMemo(() => _.isEqual(workers, initWorkers), [initWorkers, workers])
  const onCancel = () => {
    setWorkers(initWorkers)
    setEditChangesDiscardDialogOpen(false)
  }
  const onSubmit = () => {
    if (!work) {
      return
    }
    const schedules = workers.flatMap(cur => {
      const initWorkersSchedules = initWorkers.find(init => init.workerId === cur.workerId)?.schedules || []

      const newSchedules: UpdateWorkSchedule[] = cur.schedules
        .filter(s => s.scheduleId === null)
        .map(s => ({
          scheduleId: null,
          schedule: {
            scheduleTypeId: s.scheduleTypeId,
            supportWorkspaceId: s.supportWorkspaceId,
            startAt: s.startAt,
            duration: s.duration,
            workerId: cur.workerId,
            groupId: null,
          },
        }))

      const deleteSchedule: UpdateWorkSchedule[] = initWorkersSchedules
        .filter(init => !cur.schedules.some(edit => edit.scheduleId === init.scheduleId))
        .map(init => ({ scheduleId: init.scheduleId, schedule: null }))

      const updateSchedules: UpdateWorkSchedule[] = cur.schedules
        .filter(s => s.scheduleId !== null && initWorkersSchedules.every(init => !_.isEqual(init, s)))
        .map(s => ({
          scheduleId: s.scheduleId,
          schedule: {
            scheduleTypeId: s.scheduleTypeId,
            supportWorkspaceId: s.supportWorkspaceId,
            startAt: s.startAt,
            duration: s.duration,
            workerId: cur.workerId,
            groupId: null,
          },
        }))

      return newSchedules.concat(deleteSchedule, updateSchedules)
    }, [])

    const originalSchedules = getOriginalSchedulesFromWork(schedules, work)

    setSubmitted(true)
    dispatch(updateWork(workspaceId, work.workId, { schedules, originalSchedules }))
  }

  const currentGroupColor = work?.groups.find(g => g.groupId === groupId)?.color || ColorTypes.Silver

  return (
    <>
      <div className={styles.container}>
        <Link to={`/team-schedules`} className="d-flex align-items-center text-secondary w-25 pl-4 pt-4">
          <i className="icf-chevron_left" />
          <span style={{ marginTop: '0.2rem' }}>日付一覧へ</span>
        </Link>
        <div className="d-flex align-items-center px-4">
          <div className="font-x-large font-weight-bold m-0 flex-grow-1">{title}</div>
          <div className="bg-white rounded">
            <Button outline className="d-flex align-items-center" onClick={() => setViewState(!viewState)}>
              <i className={`icf-carot_${viewState ? `down` : `right`} pr-2 font-large`} />
              <div className="pl-1">目標と計画の比較</div>
            </Button>
          </div>
          <div className="bg-white rounded ml-2">
            <Button
              outline
              className="d-flex align-items-center"
              disabled={inputMode}
              onClick={() => setInputMode(true)}
            >
              <i className="icf-edit pr-2 font-large" />
              <div className="pl-1">作業計画の入力</div>
            </Button>
          </div>
        </div>
        <div className="my-3 px-4 d-flex text-nowrap overflow-auto">
          {viewState &&
            workPlanCardItems.map(item => (
              <TeamWorkPlanCard
                key={item.label}
                label={item.label}
                color={item.color}
                targetValue={item.targetValue}
                planValue={item.planValue}
              />
            ))}
        </div>

        <div className={styles.tableWrapper}>
          <table>
            <thead>
              <tr className="border-bottom">
                <td className={`bg-secondary-pale px-3 ${styles.tableHeader}`}>名前</td>
                <td className="p-0">
                  <TimeScale />
                </td>
              </tr>
            </thead>
            <tbody>
              {workers.map(worker => (
                <tr key={worker.workerId} className={styles.tableRow}>
                  <td
                    className={`px-3 ${worker.selected && 'bg-primary text-white'} ${styles.tableHeader}`}
                    onClick={() => selectWorker(worker.workerId)}
                  >
                    <div className="d-flex align-items-center">
                      <WorkerIcon name={worker.name} groupColor={currentGroupColor} />
                      <span className="pl-3 pr-1 flex-grow-1 text-truncate">{worker.name}</span>
                      <i
                        className={`icf-info font-large text-${worker.selected ? 'white' : 'secondary'}`}
                        onClick={() => moveToWorkerDetail(worker.workerId)}
                      />
                    </div>
                  </td>
                  <td className={styles.tableContent}>
                    <ShiftBar
                      items={getShiftBarItems(worker.schedules, worker.workerId)}
                      businessStartTime={businessStartTime}
                      shiftBarWidth={businessDuration}
                      activeRange={workerActiveRange(worker.schedules)}
                      disabled={inputMode}
                      isTeam={true}
                    />
                    <ShiftInputScale
                      show={footer.stepNumber === 3 && worker.selected}
                      businessStartTime={businessStartTime}
                      businessEndTime={businessEndTime}
                      shiftBarWidth={businessDuration}
                      inputSchedule={inputSchedule}
                      selectedScheduleType={selectedScheduleType}
                      activeRange={workerActiveRange(worker.schedules)}
                      onClick={x => shiftInputScaleClick(worker.workerId, x)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <WorksSelector
        open={selectorOpen}
        scheduleTypes={scheduleTypes}
        workspaces={workspaces}
        currentScheduleType={currentSchedule?.scheduleType}
        workspaceId={workspaceId}
        onClose={cancelInputMode}
        onWorkClick={selectorWorkClick}
      />
      {inputMode && (
        <MultipleFooter
          stepNumber={footer.stepNumber}
          stepText={footer.stepText}
          disabled={footer.disabled}
          selectorOpen={selectorOpen}
          decisionButtonClick={footer.decisionButtonClick}
          onCancel={cancelInputMode}
        />
      )}
      {!inputMode && !unchanged && (
        <SubmitFooter
          onCancel={() => setEditChangesDiscardDialogOpen(true)}
          onSubmit={onSubmit}
          updatedBy={work?.updatedBy}
          updatedAt={work?.updatedAt}
        />
      )}

      <EditChangesDiscardDialog
        isOpen={editChangesDiscardDialogOpen}
        onCancel={() => setEditChangesDiscardDialogOpen(false)}
        onDiscard={onCancel}
      />

      <SupportConfirm
        isOpen={openSupportConfirm}
        start={start}
        end={end}
        name={selectedScheduleType?.name || ''}
        onCancel={() => setOpenSupportConfirm(false)}
        onApprove={() => {
          onApprove?.()
          setOpenSupportConfirm(false)
        }}
      />
    </>
  )
}

export default TeamWorkPlan
