import * as React from 'react'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import { DateChangeButton } from 'components/common'

type Props = {
  onChange: (date: string) => void
}
const DashboardDateChangeButton: React.FC<Props> = ({ onChange }) => {
  const history = useHistory()
  const { search } = useLocation()

  const date = React.useMemo(() => new URLSearchParams(search).get('date') || moment().format('YYYY-MM-DD'), [search])

  const filterDate = React.useCallback((newDate: Date) => {
    const targetDate = newDate.getTime()
    const date425DaysAgo = new Date().getTime() - 425 * 24 * 60 * 60 * 1000
    return targetDate > date425DaysAgo
  }, [])

  const onChangeDate = React.useCallback(
    (newDate: Date) => {
      onChange(moment(newDate).format('YYYY-MM-DD'))
      history.replace(`?date=${moment(newDate).format('YYYY-MM-DD')}`)
    },
    [history, onChange]
  )

  return (
    <div>
      <DateChangeButton date={date} popupPosition="bottom right" filterDate={filterDate} onChange={onChangeDate} />
    </div>
  )
}
export default DashboardDateChangeButton
