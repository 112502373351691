import * as Highcharts from 'highcharts'
import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { CardBody, Row, Col } from 'reactstrap'
import { WorkspaceSummaryData, HourlyWorkerRow } from 'api/dashboard'
import { List, BadgeButton, Chart } from 'components/common'
import { append, createLineChartOptions } from 'components/common/utils'
import { BadgeItem, Series } from 'components/common/types'
import { createXAxis, colorTypeToCode, NULL_GROUP_ID, NULL_GROUP_NAME } from './utils'
import styles from './GroupPerformanceGraph.module.scss'

const LIST_WORKSPACE_ID = -1 // 必ずリストの先頭にworkspaceが入るため、groupIdに衝突しない値を定義

type GroupPerformanceBadgeItem = BadgeItem & {
  unit: string
}

export type Props = {
  workspaceId: number
  groupId: number | undefined
  workspaceSummaryData: WorkspaceSummaryData | undefined
  businessStartTime: string
  businessEndTime: string
}

const GroupPerformanceGraph: React.FC<Props> = props => {
  const { workspaceId, groupId, workspaceSummaryData, businessStartTime, businessEndTime } = props
  const [selectedId, setSelectedId] = React.useState<number | undefined>(undefined)
  const [selectedBadgeKeys, setSelectedBadgeKeys] = React.useState<number[]>([])
  const history = useHistory()

  React.useEffect(() => {
    setSelectedId(groupId === undefined ? LIST_WORKSPACE_ID : groupId === NULL_GROUP_ID ? NULL_GROUP_ID : groupId)
  }, [groupId])

  const listItems = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return []
    }
    const groupItems = workspaceSummaryData.groups.map(group => ({
      id: group.groupId ? group.groupId : NULL_GROUP_ID,
      title: group.groupName ? group.groupName : NULL_GROUP_NAME,
    }))
    return [{ id: LIST_WORKSPACE_ID, title: workspaceSummaryData.workspaceName }, ...groupItems]
  }, [workspaceSummaryData])

  const onListAction = (id: number) => {
    if (id === LIST_WORKSPACE_ID) {
      history.push(`/dashboard/${workspaceId}/performance-graph/workspace`)
    } else {
      history.push(`/dashboard/${workspaceId}/performance-graph/groups/${id}`)
    }
  }

  const graphBadges = React.useMemo(() => {
    if (!selectedId || !workspaceSummaryData) {
      return []
    }

    if (selectedId === LIST_WORKSPACE_ID) {
      return workspaceSummaryData.targets.map<GroupPerformanceBadgeItem>(target => ({
        color: target.scheduleTypeColor,
        key: target.scheduleTypeId,
        label: target.scheduleTypeName,
        unit: target.unit,
      }))
    }
    const targetId = selectedId === NULL_GROUP_ID ? null : selectedId
    const items = workspaceSummaryData.groups
      .filter(group => group.groupId === targetId)
      .flatMap(group =>
        group.workers.flatMap(worker =>
          worker.hourlyWorkerData.map<GroupPerformanceBadgeItem>(data => ({
            color: data.scheduleTypeColor,
            key: data.scheduleTypeId,
            label: data.scheduleTypeName,
            unit: data.unit,
          }))
        )
      )
    return _.uniqBy(items, 'key')
  }, [selectedId, workspaceSummaryData])

  React.useEffect(() => {
    // graphBadgesが更新されたら初回はbadgeを全て選択状態にする
    setSelectedBadgeKeys(graphBadges.map(graphBadge => graphBadge.key))
  }, [graphBadges])

  const chartOptions = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return {}
    }

    const selectedBadges = graphBadges.filter(badge => selectedBadgeKeys.findIndex(key => key === badge.key) !== -1)
    const xAxisData = createXAxis(businessStartTime, businessEndTime)

    const yAxis: Series[] = []
    selectedBadges.forEach(graphBadge => {
      const colorCode = colorTypeToCode(graphBadge.color)

      const targetGroups =
        selectedId === LIST_WORKSPACE_ID
          ? workspaceSummaryData.groups
          : selectedId === NULL_GROUP_ID
          ? workspaceSummaryData.groups.filter(group => group.groupId === null)
          : workspaceSummaryData.groups.filter(group => group.groupId === selectedId)

      const hourlyWorkerRows = targetGroups.flatMap(group =>
        group.workers.flatMap(worker =>
          worker.hourlyWorkerData
            .filter(hourlyData => hourlyData.scheduleTypeId === graphBadge.key)
            .flatMap(hourlyData => hourlyData.data.map(data => data))
        )
      )

      const summaryRows = hourlyWorkerRows.reduce<HourlyWorkerRow[]>((acc, cur) => {
        const target = acc.find(row => row.time === cur.time)
        if (!target) {
          return acc.concat([cur])
        }
        const newRow = { ...target }
        newRow.planCount = append(newRow.planCount, cur.planCount)
        newRow.recordCount = append(newRow.recordCount, cur.recordCount)
        return acc.map(row => (row.time === cur.time ? newRow : row))
      }, [])

      // areaの上にlineを表示するためにareaを先にしておく
      const recordData = xAxisData.map(time => {
        // パフォーマンス改善のために moment でなく new Date を使う
        const target = summaryRows.find(row => new Date(row.time).getTime() === new Date(time).getTime())
        return target ? target.recordCount : null
      })
      yAxis.push({
        type: 'area',
        color: colorCode,
        data: recordData,
        name: graphBadge.label,
        custom: {
          unit: graphBadge.unit ?? '',
        },
      })

      const planData = xAxisData.map(time => {
        // パフォーマンス改善のために moment でなく new Date を使う
        const target = summaryRows.find(row => new Date(row.time).getTime() === new Date(time).getTime())
        return target ? target.planCount : null
      })
      yAxis.push({
        type: 'line',
        color: colorCode,
        data: planData,
        name: graphBadge.label,
        custom: {
          unit: graphBadge.unit ?? '',
        },
      })
    })

    const options = createLineChartOptions({
      xAxis: {
        data: xAxisData,
      },
      yAxis,
    })
    _.merge<Highcharts.Options, Highcharts.Options>(options, {
      tooltip: {
        formatter() {
          return (
            '<div style="text-align:center">' +
            [
              `${moment(this.x).format('HH:mm')}~${moment(this.x).add(1, 'h').format('HH:mm')}`,
              this.series.name,
              `${Math.floor(this.y)}${this.series.options.custom!.unit}`,
            ].join('<br>') +
            '</div>'
          )
        },
      },
      xAxis: {
        labels: {
          step: 2,
        },
        tickInterval: 1,
      },
    })
    return options
  }, [graphBadges, selectedBadgeKeys, selectedId, workspaceSummaryData, businessStartTime, businessEndTime])

  return (
    <Row>
      <Col md={4}>
        <div className={styles.list}>
          <List items={listItems} selectedId={selectedId} onAction={(id: number) => onListAction(id)} />
        </div>
      </Col>
      <Col md={8}>
        <Chart options={chartOptions} />

        <CardBody className="d-flex row my-2">
          <BadgeButton
            items={graphBadges}
            selected={selectedBadgeKeys}
            onChange={(list: number[]) => setSelectedBadgeKeys(list)}
          />
        </CardBody>
      </Col>
    </Row>
  )
}

export default GroupPerformanceGraph
