import moment from 'moment'
import * as React from 'react'
import { Input } from 'reactstrap'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
import styles from './DatePicker.module.scss'

registerLocale('ja', ja)

type DatePickerInputProps = {
  value?: string
  onClick?: () => void
  onChange?: () => void
}

type DatePickerProps = {
  value?: string // YYYY-MM-DD
  placeholder?: string
  id?: string
  minDate?: Date
  onChange: (date: string | undefined) => void
}

const DatePicker: React.FC<DatePickerProps> = ({ value, placeholder, id, minDate, onChange }) => {
  const date = value ? moment(value).toDate() : undefined
  const inputId = React.useMemo(() => (id ? id : `id-${Math.random()}`), [id])

  const CustomInput = React.forwardRef<HTMLInputElement, DatePickerInputProps>((props, _) => (
    <div>
      <Input
        type="text"
        id={inputId}
        value={props.value}
        placeholder={placeholder}
        onClick={props.onClick}
        onChange={props.onChange}
      />
      <i className={`icf-cal font-large ${styles.icon}`} onClick={props.onClick} />
    </div>
  ))

  const handleChange = (selectedDate: Date | [Date, Date] | null) => {
    if (selectedDate === null || selectedDate instanceof Array) {
      onChange(undefined)
    } else {
      onChange(
        moment(selectedDate as Date)
          .utc()
          .format()
      )
    }
  }

  return (
    <>
      <ReactDatePicker
        selected={date}
        customInput={<CustomInput />}
        dateFormat="yyyy/MM/dd"
        dateFormatCalendar={'yyyy年MM月'}
        locale="ja"
        wrapperClassName={styles.reactDatePickerWrapper}
        popperClassName={styles.reactDatePickerPopper}
        showPopperArrow={false}
        minDate={minDate}
        onChange={handleChange}
      />
    </>
  )
}

export default DatePicker
