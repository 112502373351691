import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ModalBody, ModalFooter, Button } from 'reactstrap'
import { UNREACHABLE_ERROR_STATUS_CODE } from 'api/utils'
import * as NetworkErrorDialogSlice from 'slices/networkErrorDialogSlice'
import { Modal } from 'components/common'

const NetworkErrorDialog: React.FC = () => {
  const { show, code, errorMessage } = useSelector(NetworkErrorDialogSlice.selectNetworkErrorDialogStatus)

  const dispatch = useDispatch()
  const close = () => {
    dispatch(NetworkErrorDialogSlice.close())
  }

  const problemMessage = React.useMemo(
    () => errorMessage || '問題が発生しているため、しばらくしてから、再度アクセスしてください。',
    [errorMessage]
  )

  return (
    <Modal isOpen={show} zIndex={3000}>
      {code === UNREACHABLE_ERROR_STATUS_CODE ? (
        <>
          <ModalBody className="font-large font-weight-bold">ネットワークエラー</ModalBody>
          <ModalBody className="pb-4">
            ネットワークに接続されていないため、アクセスできませんでした。接続した状態で再度お試しください。
          </ModalBody>
        </>
      ) : (
        <>
          <ModalBody className="font-large font-weight-bold">問題が発生しました</ModalBody>
          <ModalBody className="pb-4">{problemMessage}</ModalBody>
          {!errorMessage && <ModalBody className="pb-4">エラーコード：{code}</ModalBody>}
        </>
      )}
      <ModalFooter className="d-flex justify-content-end">
        <Button outline onClick={close}>
          閉じる
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default NetworkErrorDialog
