import axios from 'axios'
import { GroupResponse } from 'api/groups'
import { UpdateSchedulesType, ScheduleResponse } from 'api/schedules'
import { CommonParams } from 'slices/utils'

export type TargetValues = {
  scheduleTypeId: number
  targetValue: number
}

export type UpdateTargetValuesType = {
  targetValues: TargetValues[]
}

export type WorkPlan = TargetValues & {
  planValue: number
}

export type PerformanceRateData = {
  performanceRateId: number | null
  startTime: string
  duration: number
  performanceRateValue: number | null
}

export type PerformanceRate = {
  scheduleTypeId: number
  data: PerformanceRateData[]
}

export type UpdatePerformanceRateType = {
  performanceRates: PerformanceRate[]
}

type HourlyRecordData = {
  scheduleTypeId: number
  planValue: number | null
  actualValue: number | null
}

type HourlyRecord = {
  time: string
  data: HourlyRecordData[]
}

export type WorkResponse = {
  workId: number
  date: string
  workPlan: WorkPlan[]
  performanceRates: PerformanceRate[]
  hourlyRecords: HourlyRecord[]
  groups: GroupResponse[]
  createdAt: string
  updatedBy: string
  updatedAt: string
  scheduleTruncated: boolean
}

export type WorkListResponse = {
  works: WorkResponse[]
}

type UpdateWorkerSchedulesType = UpdateSchedulesType & {
  workerId: number
  groupId: null
}
type UpdateGroupSchedulesType = UpdateSchedulesType & {
  workerId: null
  groupId: number | null
}
export type UpdateWorkSchedule = {
  scheduleId: number | null
  schedule: UpdateWorkerSchedulesType | UpdateGroupSchedulesType | null
}

export type UpdateWorkType = {
  schedules: UpdateWorkSchedule[]
  originalSchedules: ScheduleResponse[]
}

export const getWorkList = (
  params: CommonParams,
  workspaceId: number,
  from?: string,
  to?: string
): Promise<WorkListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/works`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    const queryParams = from && to ? { from, to } : null
    axios
      .get(url, { headers, params: queryParams })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getWork = (params: CommonParams, workspaceId: number, workId: number): Promise<WorkResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/works/${workId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getWorkByDate = (
  params: CommonParams,
  workspaceId: number,
  date: string,
  filterByShift: boolean
): Promise<WorkResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/works/date/${date}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: { filterByShift } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateWork = (
  params: CommonParams,
  workspaceId: number,
  workId: number,
  data: UpdateWorkType
): Promise<WorkResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/works/${workId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
export const updateWorkAsync = (
  params: CommonParams,
  workspaceId: number,
  workId: number,
  data: UpdateWorkType
): Promise<{ requestId: string }> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/works/${workId}/async`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
export const getWorkAsync = (
  params: CommonParams,
  workspaceId: number,
  workId: number,
  requestId: string
): Promise<WorkResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/works/${workId}/update_status/${requestId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
export const updateTargetValues = (
  params: CommonParams,
  workspaceId: number,
  workId: number,
  data: UpdateTargetValuesType
): Promise<WorkResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/works/${workId}/target_values`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updatePerformanceRateAsync = (
  params: CommonParams,
  workspaceId: number,
  workId: number,
  data: UpdatePerformanceRateType
): Promise<{ requestId: string }> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/works/${workId}/performance_rate/async`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .put(url, { ...data, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
export const getPerformanceRateAsync = (
  params: CommonParams,
  workspaceId: number,
  workId: number,
  requestId: string
): Promise<WorkResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/works/${workId}/performance_rate/update_status/${requestId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
