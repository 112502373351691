import * as React from 'react'
import { ModalBody, ModalFooter, Button } from 'reactstrap'
import { Modal, Notification } from 'components/common'
import styles from './styles.module.scss'

type Props = {
  isOpen: boolean
  title: string
  approveLabel?: string
  approveColor?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'link'
  approveDisabled?: boolean
  errorMessage?: string
  showApprove?: boolean
  onCancel: () => void
  onApprove?: () => void
  onHideNotification: () => void
  submitName?: string // APMを判別するための文字列
  overflow?: string
}

const CustomModal: React.FC<Props> = ({
  children,
  isOpen,
  title,
  approveLabel = '保存',
  approveColor = 'primary',
  approveDisabled = false,
  errorMessage = '',
  showApprove = true,
  onCancel,
  onApprove,
  onHideNotification,
  submitName,
  overflow,
}) => {
  return (
    <Modal isOpen={isOpen} overflow={overflow}>
      <ModalBody className="font-large font-weight-bold">{title}</ModalBody>

      <Notification
        className={styles.notification}
        errorMessage={errorMessage}
        error={!!errorMessage}
        hide={onHideNotification}
      />

      <ModalBody>{children}</ModalBody>

      <ModalFooter className="d-flex justify-content-between">
        <Button outline onClick={onCancel}>
          {showApprove ? 'キャンセル' : '閉じる'}
        </Button>
        {showApprove && (
          <Button color={approveColor} disabled={approveDisabled} onClick={onApprove} name={submitName}>
            {approveLabel}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default CustomModal
