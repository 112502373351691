import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CONFLICT_ERROR_STATUS_CODE } from 'api/utils'
import { clearErrorMessage, createGroup, selectGroupsStatus } from 'slices/groupsSlice'
import { CustomModal, InputFormat } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'

type Props = {
  isOpen: boolean
  workspaceId: number
  onSuccess: () => void
  onCancel: () => void
}

const GroupCreate: React.FC<Props> = ({ isOpen, workspaceId, onSuccess, onCancel }) => {
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [submitted, setSubmitted] = React.useState(false)
  const [groupName, setGroupName] = React.useState<string | undefined>(undefined)
  const [groupNameValidity, setGroupNameValidity] = React.useState(false)
  const dispatch = useDispatch()
  const { isRequesting, errorMessage } = useSelector(selectGroupsStatus)

  const disabled = React.useMemo(() => !(groupName && groupNameValidity), [groupName, groupNameValidity])

  const handleSaveClick = () => {
    if (groupName) {
      setSubmitted(true)
      dispatch(createGroup(workspaceId, groupName))
    }
  }

  const handleCancelClick = () => {
    setGroupName(undefined)
    setGroupNameValidity(false)
    onCancel()
  }

  React.useEffect(() => {
    if (isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      setGroupName(undefined)
      setGroupNameValidity(false)
      onSuccess()
    } else {
      if (errorMessage === CONFLICT_ERROR_STATUS_CODE) {
        setModalErrorMessage('すでにこの名前のグループは存在しています。')
      } else {
        setModalErrorMessage('保存できませんでした。')
      }
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, onSuccess])

  return (
    <CustomModal
      isOpen={isOpen}
      title="グループの追加"
      approveDisabled={disabled}
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleSaveClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-2">
        ワークスペースにグループを追加します。メンバーのグループへの登録はメンバー管理で行ってください。
        <div className="mt-4">
          <InputFormat
            label="グループ名"
            placeholder="グループ名を入力"
            value={groupName}
            size="middle"
            maxLength={100}
            onChange={value => setGroupName(value)}
            validations={[Rules.Required]}
            onValidate={setGroupNameValidity}
          />
        </div>
      </div>
    </CustomModal>
  )
}

export default GroupCreate
