import _ from 'lodash'
import * as React from 'react'
import { CustomInput } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'
import { selectWorkersStatus, clearErrorMessage, exportWorkers } from 'slices/workersSlice'
import { selectWorkspacesStatus } from 'slices/workspacesSlice'
import { CustomModal, ItemEdit } from 'components/common'
import { SuggestionItem } from 'components/common/types'

type Props = {
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

const WorkersExportDialog: React.FC<Props> = ({ isOpen, onSuccess, onCancel }) => {
  const [submitted, setSubmitted] = React.useState(false)
  const [isReplaceForecastProductivity, setReplaceForecastProductivity] = React.useState(false)
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string>()
  const [selectedWorkspaces, setSelectedWorkspaces] = React.useState<SuggestionItem[]>([])
  const dispatch = useDispatch()
  const { isRequesting, errorMessage } = useSelector(selectWorkersStatus)
  const { workspaces } = useSelector(selectWorkspacesStatus)

  const disabled = React.useMemo(() => _.isEmpty(selectedWorkspaces), [selectedWorkspaces])

  const handleSaveClick = () => {
    const targetWorkspaces = selectedWorkspaces.map(ws => ws.id as number)
    dispatch(exportWorkers(targetWorkspaces, isReplaceForecastProductivity))
    setSubmitted(true)
  }

  React.useEffect(() => {
    setSelectedWorkspaces([])
    setReplaceForecastProductivity(false)
  }, [isOpen])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      setModalErrorMessage(undefined)
      onSuccess()
    } else if (ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
      // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
      setModalErrorMessage(undefined)
    } else {
      setModalErrorMessage('保存できませんでした。')
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess])

  return (
    <CustomModal
      isOpen={isOpen}
      title="メンバーエクスポート"
      approveLabel="CSVエクスポート"
      approveDisabled={disabled}
      errorMessage={modalErrorMessage}
      onCancel={onCancel}
      onApprove={handleSaveClick}
      onHideNotification={() => {
        setModalErrorMessage(undefined)
        dispatch(clearErrorMessage())
      }}
      submitName="member-export-submit"
    >
      <div className="pb-4">
        <div className="mb-4">
          <div className="my-2 font-weight-bold">ワークスペース選択</div>
          <div className="mb-2">メンバーエクスポートするワークスペースを選択してください。</div>
          <ItemEdit
            items={workspaces.map(w => ({ id: w.workspaceId, value: w.name }))}
            selectedItems={selectedWorkspaces}
            label="ワークスペースを追加"
            itemName="ワークスペース"
            onChange={setSelectedWorkspaces}
          />
        </div>
        <div className="mb-4">
          <div className="my-2 font-weight-bold">その他の設定</div>
          <div className="mb-2">作業の人時生産性を過去実績最大30日の平均値と置き換えてエクスポート</div>
          <CustomInput
            id="contain-productivity-adjustment"
            label="過去実績の平均値と置き換える"
            className="mt-2"
            checked={isReplaceForecastProductivity}
            type="checkbox"
            onChange={e => setReplaceForecastProductivity(e.target.checked)}
          />
        </div>
      </div>
    </CustomModal>
  )
}

export default WorkersExportDialog
