import axios from 'axios'
import { ScheduleResponse } from 'api/schedules'
import { WorkerResponse } from 'api/workers'
import { CommonParams } from 'slices/utils'
import { ColorType } from 'components/common/types'

export type GroupResponse = {
  groupId: number
  name: string
  color: ColorType
  supportedWorkspaceId: number | null
  supportedWorkspaceName: string | null
  schedules: ScheduleResponse[]
  workers: WorkerResponse[]
  createdAt: string
  updatedAt: string
  updatedBy: string
}

export type GroupListResponse = {
  groups: GroupResponse[]
}

export const getGroupList = (
  params: CommonParams,
  workspaceId: number,
  useWorkers = true
): Promise<GroupListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/groups`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: { useWorkers } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const getGroup = (
  params: CommonParams,
  workspaceId: number,
  groupId: number,
  useWorkers = true
): Promise<GroupResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/groups/${groupId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers, params: { useWorkers } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createGroup = (params: CommonParams, workspaceId: number, name: string): Promise<GroupResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/groups`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, { name, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateGroup = (
  params: CommonParams,
  workspaceId: number,
  groupId: number,
  name: string,
  color: ColorType
): Promise<GroupResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/groups/${groupId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, { name, color, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const deleteGroup = (params: CommonParams, workspaceId: number, groupId: number): Promise<{}> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/workspaces/${workspaceId}/groups/${groupId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers, params: { updatedBy: params.updatedBy } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
