import * as React from 'react'
import { Col, Row } from 'reactstrap'

export type VerticalItem = {
  title: string
  data: string | number | null | undefined
}

type Props = {
  items: VerticalItem[]
  titleColumn: number
}

const VerticalTable: React.FC<Props> = (props: Props) => {
  return (
    <>
      {props.items.map((item: VerticalItem, index: number) => {
        return (
          <Row className="border-bottom" key={`vertical-row-${index}`}>
            <Col md={props.titleColumn} className="bg-light py-3">
              {item.title}
            </Col>
            <Col className="py-3 text-truncate">{item.data}</Col>
          </Row>
        )
      })}
    </>
  )
}

export default VerticalTable
