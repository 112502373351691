import axios, { AxiosResponse } from 'axios'
import { UserResponse } from 'api/users'
import { AuthToken } from 'api/utils'

export type LoginParams = {
  email: string
  password: string
}

export type LoginResponse = {
  newPasswordRequired: boolean
  session: string | null
  idToken: string | null
  accessToken: string | null
  refreshToken: string | null
  expirationDate: string | null
  user: UserResponse
}

export const login = (data: LoginParams): Promise<LoginResponse> =>
  new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/session`
    axios
      .post<any, AxiosResponse<LoginResponse>>(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const logout = (token: AuthToken) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/session`
    const headers = {
      Authorization: token.idToken,
      'X-Access-Authorization': token.accessToken,
    }
    axios
      .delete(url, { headers })
      .then(() => resolve({}))
      .catch(error => reject(error))
  })
}

export type ActivateParams = {
  email: string
  password: string
  name: string
  session: string
}

export type ActivateResponse = {
  idToken: string
  accessToken: string
  refreshToken: string
  expirationDate: string
  user: UserResponse
}

export const activate = (data: ActivateParams): Promise<ActivateResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/session/activate`
    axios
      .put<any, AxiosResponse<ActivateResponse>>(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export type RefreshParams = {
  refreshToken: string
}

export type RefreshResponse = {
  idToken: string
  accessToken: string
  expirationDate: string
}

export const refresh = (data: RefreshParams): Promise<RefreshResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/session/refresh`
    axios
      .post<any, AxiosResponse<RefreshResponse>>(url, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
