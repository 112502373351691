import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Container, Col, Row, Button } from 'reactstrap'
import { logout } from 'slices/sessionSlice'
import { SinglePageCard } from 'components/common'
import styles from './styles.module.scss'

const NotFoundPage: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <SinglePageCard showTitle={false} subtitle="ページが見つかりませんでした。">
      <Container>
        <Row>
          <Col className="text-center">
            お探しのページは見つかりませんでした。別の方法でお試しください。
          </Col>
        </Row>

        <Row>
          <Col className="text-center mt-3">
            <Button color="primary" className={styles.button} onClick={() => history.goBack()}>
              戻る
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            <Button color="link" onClick={() => dispatch(logout())}>
              ログインに戻る
            </Button>
          </Col>
        </Row>
      </Container>
    </SinglePageCard>
  )
}

export default NotFoundPage
