import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from 'store'
import * as API from 'api/shift'
import { UNREACHABLE_ERROR_STATUS_CODE, UNAUTHORIZED_ERROR_STATUS_CODE, makeErrorMessage } from 'api/utils'
import { commonParams } from 'slices/utils'
import * as Spinner from 'slices/spinnerSlice'
import { validateToken } from 'slices/sessionSlice'
import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'

type ShiftState = {
  isRequesting: boolean
  errorMessage: string
}

const initialState: ShiftState = {
  isRequesting: false,
  errorMessage: '',
}

export const shiftSlice = createSlice({
  name: 'shift',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    importShiftSuccess: state => {
      state.isRequesting = false
    },
  },
})

export const { startRequest, clearErrorMessage, apiFailure, importShiftSuccess } = shiftSlice.actions

export const importShift = (fileName: string, csvContent: string): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  try {
    const response = await API.shiftUploadUrl(commonParams(getState), fileName)
    await API.putUploadUrl(commonParams(getState), response.uploadUrl, csvContent)
    dispatch(importShiftSuccess())
  } catch (error) {
    const errorCode = makeErrorMessage(error)
    if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
      dispatch(SessionTimeoutDialog.open())
    } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
      dispatch(NetworkErrorDialog.open({ code: errorCode }))
    }
    dispatch(apiFailure({ errorMessage: errorCode }))
  } finally {
    dispatch(Spinner.stop())
  }
}

export const selectShiftStatus = (state: RootState) => ({ ...state.shift })

export default shiftSlice.reducer
