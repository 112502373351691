import { AxiosError } from 'axios'

export const BAD_REQUEST_ERROR_STATUS_CODE = '400'
export const UNAUTHORIZED_ERROR_STATUS_CODE = '401'
export const NOT_FOUND_ERROR_STATUS_CODE = '404'
export const CONFLICT_ERROR_STATUS_CODE = '409'
export const UNREACHABLE_ERROR_STATUS_CODE = '999'
export const ENABLE_DIALOG_ERROR_STATUS_CODES = [UNAUTHORIZED_ERROR_STATUS_CODE, UNREACHABLE_ERROR_STATUS_CODE]
export const INCORRECT_OLD_PASSWORD_MESSAGE = 'Incorrect oldPassword.'

//TODO: たぶん不要
export type AuthToken = {
  accessToken: string | null
  idToken: string | null
}

export const makeErrorMessage = (res: AxiosError): string => {
  return (res.response?.status || UNREACHABLE_ERROR_STATUS_CODE).toString()
}

// null を除いた平均を求める
export const compactAverage = (baseData: Array<number | null>) => {
  const data = baseData.filter((n): n is number => n !== null)
  if (data.length === 0) {
    return null
  }
  return Math.floor(data.reduce((cur, acc) => cur + acc, 0) / data.length)
}
