import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Button, Card, CardBody, CardText, CardTitle, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { WorkerEditDataType, WorkerResponse } from 'api/workers'
import { CONFLICT_ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'
import { getGroupList, selectGroupsStatus } from 'slices/groupsSlice'
import { showError, showSuccess } from 'slices/notificationSlice'
import { selectSkillsStatus } from 'slices/skillsSlice'
import { getTenant, selectTenantsStatus } from 'slices/tenantsSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { updateWorker, selectWorkersStatus } from 'slices/workersSlice'
import { clearErrorMessage, selectWorkspacesStatus } from 'slices/workspacesSlice'
import {
  CheckBoxFormat,
  DatePicker,
  InputFormat,
  List,
  SelectBoxFormat,
  CardSubmitFooter,
  CustomButton,
  ItemEdit,
} from 'components/common'
import { ListItem, FilterItem, SuggestionItem } from 'components/common/types'
import { MAGIQANNEAL_APPLICATION_ID } from 'components/common/utils'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import EditChangesDiscardDialog from 'components/EditChangesDiscardDialog/EditChangesDiscardDialog'
import useWorker from 'hooks/useWorker'
import WorkerFilter from './WorkerFilter'
import WorkerDelete from './WorkerDelete'
import WorkersOptEngineDialog from './WorkersOptEngineDialog'
import WorkersImportDialog from './WorkersImportDialog'
import WorkersExportDialog from './WorkersExportDialog'
import { WorkerEditType } from './types'
import placeholder from 'images/allEmpty.svg'
import styles from './WorkerList.module.scss'
import PerformanceIndicesInput from './PerformanceIndicesInput'

const WorkerList: React.FC = () => {
  const {
    showPerformanceIndices,
    disabled,
    editData,
    setEditData,
    setNameValidity,
    setWmsMemberIdValidity,
    initData,
  } = useWorker()

  const [initEditData, setInitEditData] = React.useState<WorkerEditType>(initData)
  const [submitted, setSubmitted] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [openEditChangesDiscardDialog, setOpenEditChangesDiscardDialog] = React.useState(false)
  const [openWorkersOptEngineDialog, setOpenWorkersOptEngineDialog] = React.useState(false)
  const [openWorkersImportDialog, setOpenWorkersImportDialog] = React.useState(false)
  const [openWorkersExportDialog, setOpenWorkersExportDialog] = React.useState(false)
  const [filterItems, setFilterItems] = React.useState<FilterItem[]>([])
  const [filterWord, setFilterWord] = React.useState('')
  const [currentIndex, setCurrentIndex] = React.useState<number | undefined>(0)
  const [selectedWorker, setSelectedWorker] = React.useState<WorkerResponse | undefined>(undefined)
  const history = useHistory()

  const dispatch = useDispatch()
  const { isRequesting, errorMessage } = useSelector(selectWorkersStatus)
  const { workers } = useSelector(selectWorkersStatus)
  const { workspaces } = useSelector(selectWorkspacesStatus)
  const { skills } = useSelector(selectSkillsStatus)
  // apiKey を取得する
  const { user } = useSelector(selectSessionStatus)
  const { groups } = useSelector(selectGroupsStatus)
  const { tenants } = useSelector(selectTenantsStatus)

  React.useEffect(() => {
    dispatch(getTenant(user.tenants[0].tenantId))
  }, [dispatch, user])

  const { apiKey, magiQannealTenant, magiQannealLocations } = React.useMemo(() => {
    const application = tenants[0]?.applications.find(app => app.applicationId === MAGIQANNEAL_APPLICATION_ID)
    return {
      apiKey: application?.options.apiKey ?? '',
      magiQannealTenant: application?.options.tenant ?? '',
      magiQannealLocations: application?.options.relatedWorkspaceData?.map(rw => rw.location) ?? [],
    }
  }, [tenants])

  const listItems = React.useMemo(
    () =>
      workers.reduce((acc: ListItem[], cur: WorkerResponse) => {
        const filtered = filterWord === '' || cur.name.includes(filterWord) || cur.wmsMemberId.includes(filterWord)
        if (!filtered) {
          return acc
        }
        const data = filterItems.filter(f => f.checked).find(item => item.key === cur.workspaceId)?.label
        if (data) {
          acc.push({
            id: cur.workerId,
            title: cur.name,
            data,
          })
        }
        return acc
      }, []),
    [workers, filterWord, filterItems]
  )

  React.useEffect(() => {
    const initFilterItem = workspaces
      .map(workspace => ({ key: workspace.workspaceId, label: workspace.name, checked: true }))
      .concat([{ key: 0, label: 'ワークスペース未所属', checked: true }])
    setFilterItems(initFilterItem)
    setFilterWord('')
  }, [workspaces])
  React.useEffect(() => {
    if (typeof currentIndex === 'number') {
      setSelectedWorker(workers[currentIndex])
    }
  }, [currentIndex, workers])
  const onSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterWord(event.target.value)
    setSelectedWorker(undefined)

    setCurrentIndex(undefined)
  }
  const onSearchClick = (items: FilterItem[]) => {
    setFilterItems(items)
    setSelectedWorker(undefined)

    setCurrentIndex(undefined)
  }
  const handleWorkspaceIdListChange = (selectedId: string | number) => {
    const index = workers.findIndex((worker: WorkerResponse) => worker.workerId === selectedId)

    setCurrentIndex(index)
  }
  React.useEffect(() => {
    if (selectedWorker?.workspaceId) {
      dispatch(getGroupList(selectedWorker.workspaceId, false))
    }
  }, [dispatch, selectedWorker?.workspaceId])

  React.useEffect(() => {
    // groupsから選択中のgroupが見つからない場合はgroup選択状態リセット
    if (editData.group && !groups.find(group => group.groupId === editData.group?.key)) {
      setEditData({ ...editData, group: undefined, groupLeader: false })
    }
  }, [groups, editData, setEditData])
  // selectedWorkerが変更された場合のみeditData, initEditDataを初期化する
  const [beforeSelectedWorkerId, setBeforeSelectedWorkerId] = React.useState<number | undefined>(undefined)

  React.useEffect(() => {
    if (!selectedWorker || selectedWorker.workerId === beforeSelectedWorkerId) {
      return
    }

    const nextWorkspace = workspaces.find(workspace => workspace.workspaceId === selectedWorker.workspaceId)
    const nextGroup = groups.find(group => group.groupId === selectedWorker.groupId)
    const nextSelectedSkills = skills.filter(skill => selectedWorker.skillIds.includes(skill.skillId))
    const nextPerformanceIndices = selectedWorker.performanceIndices.reduce((acc, value) => {
      return { ...acc, [value.scheduleTypeId]: value.index?.toString() }
    }, {})
    const requestingWorkspaceData = selectedWorker.workspaceId && !nextWorkspace
    const requestingGroupData = selectedWorker.workspaceId && selectedWorker.groupId && !nextGroup
    const requestingSkillData = selectedWorker.skillIds.length !== nextSelectedSkills.length
    if (requestingWorkspaceData || requestingGroupData || requestingSkillData) {
      // workspaces, groups, skillsの読み込みが終わっていないためスキップする
      return
    }

    const data = {
      name: selectedWorker.name,
      wmsMemberId: selectedWorker.wmsMemberId,
      workspace: nextWorkspace ? { key: nextWorkspace.workspaceId, value: nextWorkspace.name } : undefined,
      group: nextGroup ? { key: nextGroup.groupId, value: nextGroup.name } : undefined,
      groupLeader: selectedWorker.groupLeader,
      hiredAt: selectedWorker.hiredAt,
      skills: nextSelectedSkills,
      performanceIndices: nextPerformanceIndices,
    }
    setEditData(data)
    setInitEditData(data)
    setWmsMemberIdValidity(true)
    setBeforeSelectedWorkerId(selectedWorker.workerId)
    setForecastColorScheduleTypeIds([])
  }, [selectedWorker, beforeSelectedWorkerId, workspaces, groups, skills, setEditData, setWmsMemberIdValidity])

  const workspaceSelectItems = React.useMemo(
    () => workspaces.map(workspace => ({ key: workspace.workspaceId, value: workspace.name })),
    [workspaces]
  )

  const groupSelectItems = React.useMemo(
    () => (editData.workspace?.key ? groups.map(group => ({ key: group.groupId, value: group.name })) : []),
    [editData.workspace?.key, groups]
  )

  const initSelected = React.useMemo(() => selectedWorker === undefined, [selectedWorker])
  const unchanged = React.useMemo(() => _.isEqual(editData, initEditData), [editData, initEditData])

  const showOptEngineButton = React.useMemo(() => !!apiKey, [apiKey])

  const [forecastColorScheduleTypeIds, setForecastColorScheduleTypeIds] = React.useState<number[]>([])

  const onSubmit = () => {
    if (!selectedWorker) {
      return
    }

    setSubmitted(true)
    const performanceIndices = workspaces
      .flatMap(workspace => workspace.scheduleTypes)
      .filter(scheduleType => scheduleType.dataConnection)
      .map(({ scheduleTypeId }) => ({
        scheduleTypeId: Number(scheduleTypeId),
        index: Number(editData.performanceIndices[scheduleTypeId]),
      }))

    const data: WorkerEditDataType = {
      name: editData.name || '',
      wmsMemberId: editData.wmsMemberId || '',
      workspaceId: editData.workspace?.key || 0,
      groupId: editData.group?.key || null,
      groupLeader: editData.groupLeader,
      hiredAt: editData.hiredAt || null,
      skillIds: editData.skills.map(skill => skill.skillId),
      performanceIndices,
    }
    dispatch(updateWorker(selectedWorker.workerId, data))
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
      setForecastColorScheduleTypeIds([])
      // beforeSelectedWorkerIdをリセットしてeditDataとinitEditDataを初期化する
      setBeforeSelectedWorkerId(undefined)
    } else {
      if (errorMessage === CONFLICT_ERROR_STATUS_CODE) {
        dispatch(showError({ errorMessage: 'ID・識別番号が重複しています。' }))
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
        dispatch(showError())
      }
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch])

  const onDeleteSuccess = () => {
    dispatch(showSuccess())
    setOpenDelete(false)
  }

  const handleEditChangesDiscard = () => {
    setOpenEditChangesDiscardDialog(false)

    // group再取得が必要なケースがあるためbeforeSelectedWorkerIdをリセットしてeditDataを初期化する
    setBeforeSelectedWorkerId(undefined)
    if (initEditData.workspace) {
      dispatch(getGroupList(initEditData.workspace.key, false))
    }
  }

  const handleChangeWorkspace = React.useCallback(
    (key: string | number | undefined) => {
      const selectedWorkspaceId = Number(key)
      if (isNaN(selectedWorkspaceId) || editData.workspace?.key === selectedWorkspaceId) {
        return
      }
      const workspace = workspaceSelectItems.find(w => w.key === selectedWorkspaceId)
      dispatch(getGroupList(selectedWorkspaceId, false))
      setEditData({ ...editData, workspace })
    },
    [editData, workspaceSelectItems, dispatch, setEditData]
  )

  const handleWorkersImport = () => {
    setOpenWorkersImportDialog(false)
    dispatch(
      showSuccess({
        successMessage: 'メンバー情報のアップロードに成功しました。反映まで最大30分程度かかる場合があります。',
      })
    )
  }

  const handleSkillEdit = (items: SuggestionItem[]) => {
    const skillData = skills.filter(s => items.some(i => i.id === s.skillId))
    setEditData({ ...editData, skills: skillData })
  }

  return (
    <>
      <div className={`d-flex justify-content-between ${styles.topContents}`}>
        <div className="font-x-large font-weight-bold align-self-center">メンバー一覧</div>
        <div className="d-flex">
          {showOptEngineButton && (
            <CustomButton className="mr-2" outline onClick={() => setOpenWorkersOptEngineDialog(true)}>
              magiQannealと連携
            </CustomButton>
          )}
          <CustomButton className="mr-2" outline onClick={() => setOpenWorkersImportDialog(true)}>
            インポート
          </CustomButton>
          <CustomButton className="mr-2" outline onClick={() => setOpenWorkersExportDialog(true)}>
            エクスポート
          </CustomButton>
          <CustomButton icon="plus" onClick={() => history.push('/worker-create')}>
            メンバー追加
          </CustomButton>
        </div>
      </div>
      <Row className={`py-3 ${styles.row}`}>
        <Col md={4} className="h-100">
          <Card className={`position-sticky ${styles.list}`}>
            <div className="d-flex">
              <Input placeholder="メンバー名もしくはID・識別番号で検索" onChange={onSearchInput}></Input>
              <div className="ml-2">
                <WorkerFilter filterItems={filterItems} onChange={onSearchClick} />
              </div>
            </div>
            {listItems.length > 0 ? (
              <List
                items={listItems}
                selectedId={selectedWorker?.workerId}
                onAction={(id: number) => handleWorkspaceIdListChange(id)}
              />
            ) : (
              <CardBody className="d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <img className={`mx-auto ${styles.placeholderImage}`} src={placeholder} alt="" width="80%" />
                  <div className="font-middle font-weight-bold py-4">メンバーがいません</div>
                  <div>メンバーを追加して、詳細情報を編集しましょう。</div>
                </div>
              </CardBody>
            )}
          </Card>
        </Col>
        <Col md={8} className="h-100">
          <Card className="h-100">
            {initSelected ? (
              <CardBody className="d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                  <div className="font-middle font-weight-bold py-4">メンバーが選択されていません</div>
                  <div>メンバーを選択して、詳細情報を編集しましょう。</div>
                </div>
              </CardBody>
            ) : (
              <>
                <div className={styles.memberDetail}>
                  <CardBody>
                    <CardTitle className="font-large font-weight-bold">メンバー詳細</CardTitle>
                    <InputFormat
                      label="名前※"
                      placeholder="メンバー名もしくはID・識別番号で検索"
                      value={editData.name}
                      size="middle"
                      maxLength={100}
                      onChange={value => setEditData({ ...editData, name: value.trim() })}
                      validations={[Rules.Required]}
                      onValidate={setNameValidity}
                    />
                    <InputFormat label="ID・識別番号※" value={editData.wmsMemberId} size="middle" disabled={true} />
                    <FormGroup row>
                      <Label for="hiredAt" md={4}>
                        入社日
                      </Label>
                      <Col md={4} className="align-self-center">
                        <DatePicker
                          value={editData.hiredAt}
                          placeholder="入社日を選択"
                          onChange={date => setEditData({ ...editData, hiredAt: date })}
                        />
                      </Col>
                    </FormGroup>

                    <SelectBoxFormat
                      label="所属ワークスペース"
                      placeholder="ワークスペースを選択"
                      formText="メンバーに予定を設定するためにはワークスペースに所属させてください。"
                      value={editData.workspace?.key.toString()}
                      size="middle"
                      items={workspaceSelectItems}
                      onChange={e => handleChangeWorkspace(e.key)}
                    />
                    {groupSelectItems.length > 0 && (
                      <>
                        <SelectBoxFormat
                          label="所属グループ"
                          placeholder="グループを選択"
                          value={editData.group?.key.toString()}
                          size="middle"
                          items={groupSelectItems}
                          onChange={e => {
                            const group = groupSelectItems.find(g => g.key.toString() === e.key?.toString())
                            setEditData({ ...editData, group })
                          }}
                        />
                        {editData.group && (
                          <CheckBoxFormat
                            label="グループリーダー"
                            checkboxLabel="リーダー"
                            checked={editData.groupLeader}
                            onChange={e =>
                              setEditData({
                                ...editData,
                                groupLeader: e.target.checked,
                              })
                            }
                          />
                        )}
                      </>
                    )}
                  </CardBody>

                  <CardBody>
                    <CardTitle className="font-large font-weight-bold">スキル設定</CardTitle>
                    <CardText className="py-2">
                      メンバーにはスキルを設定することができます。新しいスキルは<Link to="/skills">スキル管理</Link>
                      で登録してください。
                    </CardText>
                    <ItemEdit
                      items={skills.map(s => ({ id: s.skillId, value: s.name }))}
                      selectedItems={editData.skills.map(s => ({ id: s.skillId, value: s.name }))}
                      label="メンバーにスキルを追加"
                      itemName="スキル"
                      onChange={handleSkillEdit}
                    />
                  </CardBody>
                  {showPerformanceIndices && (
                    <CardBody>
                      <CardTitle className="font-large font-weight-bold">人時生産性設定</CardTitle>
                      <CardText>
                        全てのワークスペースに登録されている作業に対する人時生産性を設定する事ができます。所属ワークスペース以外の作業についても人時生産性を設定しておく事でワークスペース間を移動して作業した際にも人時生産性を計算する事ができます。
                      </CardText>
                      <PerformanceIndicesInput
                        workerId={selectedWorker?.workerId}
                        forecastColorScheduleTypeIds={forecastColorScheduleTypeIds}
                        onFocus={setForecastColorScheduleTypeIds}
                        performanceIndices={editData.performanceIndices}
                        onChange={value =>
                          setEditData({
                            ...editData,
                            performanceIndices: value,
                          })
                        }
                        initialPerformanceIndices={initEditData.performanceIndices}
                      />
                    </CardBody>
                  )}

                  <CardBody>
                    <CardTitle className="font-large font-weight-bold">メンバーの削除</CardTitle>
                    <CardText>メンバーを削除すると、作業履歴情報などはすべて失われ、復旧できません。</CardText>
                    <Button outline color="danger" className="my-3" onClick={() => setOpenDelete(true)}>
                      このメンバーを削除
                    </Button>
                  </CardBody>
                </div>
                <CardSubmitFooter
                  onCancel={() => setOpenEditChangesDiscardDialog(true)}
                  onSubmit={onSubmit}
                  updatedBy={selectedWorker?.updatedBy}
                  updatedAt={selectedWorker?.updatedAt}
                  cancelDisabled={unchanged}
                  submitDisabled={unchanged || disabled}
                  submitName="worker-list-submit"
                />
              </>
            )}
          </Card>
        </Col>
      </Row>

      {selectedWorker && (
        <WorkerDelete
          isOpen={openDelete}
          workerId={selectedWorker.workerId}
          onSuccess={onDeleteSuccess}
          onCancel={() => setOpenDelete(false)}
        />
      )}

      <EditChangesDiscardDialog
        isOpen={openEditChangesDiscardDialog}
        onCancel={() => setOpenEditChangesDiscardDialog(false)}
        onDiscard={handleEditChangesDiscard}
      />

      <WorkersOptEngineDialog
        isOpen={openWorkersOptEngineDialog}
        apiKey={apiKey}
        magiQannealTenant={magiQannealTenant}
        magiQannealLocations={magiQannealLocations}
        onCancel={() => setOpenWorkersOptEngineDialog(false)}
      />

      <WorkersImportDialog
        isOpen={openWorkersImportDialog}
        onSuccess={handleWorkersImport}
        onCancel={() => setOpenWorkersImportDialog(false)}
      />

      <WorkersExportDialog
        isOpen={openWorkersExportDialog}
        onSuccess={() => setOpenWorkersExportDialog(false)}
        onCancel={() => setOpenWorkersExportDialog(false)}
      />
    </>
  )
}

export default WorkerList
