import * as React from 'react'
import PivotOuterIndex from './PivotOuterIndex'

type TypedProps = {
  defaultIndex?: number
  onChange?: (index: number) => void
}
const Pivot: React.FC<TypedProps> = props => {
  const [selectedIndex, setIndex] = React.useState(props.defaultIndex || 0)
  React.useEffect(() => {
    if (props.onChange) {
      props.onChange(selectedIndex)
    }
  }, [selectedIndex, props])
  return (
    <PivotOuterIndex selectedIndex={selectedIndex} onChange={index => setIndex(index)}>
      {props.children}
    </PivotOuterIndex>
  )
}

export default Pivot
