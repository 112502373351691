import _ from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Container, Row, Col, Card, CardText } from 'reactstrap'
import { WorkerIcon } from 'components/common'
import { ColorTypes } from 'components/common/utils'
import { logout, selectSessionStatus, setTeamWorker } from 'slices/sessionSlice'
import { getGroup, selectGroupsStatus } from 'slices/groupsSlice'
import styles from './styles.module.scss'

const TeamSelectWorkers: React.FC = () => {
  const dispatch = useDispatch()
  const {
    team: { workspaceId, groupId, groupName },
  } = useSelector(selectSessionStatus)
  React.useEffect(() => {
    dispatch(getGroup(workspaceId, groupId))
  }, [dispatch, workspaceId, groupId])

  const groupsStatus = useSelector(selectGroupsStatus)
  const group = React.useMemo(() => _.find(groupsStatus.groups, { groupId }), [groupsStatus, groupId])
  const workers = _.sortBy(group?.workers, 'name')

  const history = useHistory()
  const moveToAssignment = (workerId: number, workerName: string) => {
    dispatch(setTeamWorker({ workerId, workerName }))
    history.push(`/team-assignment`)
  }

  return (
    <>
      <Container fluid className={styles.header}>
        <Row>
          <Col>
            <i className="icf-chevron_left font-x-large" onClick={() => history.push(`/team-select-groups`)} />
          </Col>
          <Col className="font-x-large font-weight-bold text-center">操作メンバー選択</Col>
          <Col className="text-right" onClick={() => dispatch(logout())}>
            ログアウト
          </Col>
        </Row>
      </Container>
      <div className={`position-sticky ${styles.list}`}>
        {workers.map(worker => (
          <Card
            className="p-3 mb-2"
            key={worker.workerId}
            onClick={() => moveToAssignment(worker.workerId, worker.name)}
          >
            <div className="d-flex justify-content-start align-items-center">
              <WorkerIcon name={worker.name} groupColor={group?.color || ColorTypes.Silver} />
              <div className="px-3 overflow-hidden">
                <CardText className="m-0 text-truncate">{worker.name}</CardText>
                <CardText className="m-0 text-truncate font-weight-light text-black-50">{groupName}</CardText>
              </div>
              <i className="ml-auto icf-chevron_right font-large" />
            </div>
          </Card>
        ))}
      </div>
    </>
  )
}

export default TeamSelectWorkers
