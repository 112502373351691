import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Card, CardText } from 'reactstrap'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'
import { CustomButton, NavMenu } from 'components/common'
import { isReadOnlyWorkspace } from 'components/common/utils'
import placeholder from 'images/allEmpty.svg'
import styles from './Workspaces.module.scss'

const menuItems = [
  {
    type: 'workspaces',
    label: 'ワークスペース一覧',
  },
]

const onDetailClick = () => window.open('https://help.smileboard.jp/create_workspace', '_blank')

const Workspaces: React.FC = () => {
  const [menuType, setMenuType] = React.useState('workspaces')
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  const workspacesStatus = useSelector(selectWorkspacesStatus)

  const { user } = useSelector(selectSessionStatus)
  const isReadOnly = React.useMemo(() => isReadOnlyWorkspace(user), [user])

  const history = useHistory()

  const onWorkspaceCreateClick = () => {
    history.push('/workspace-create')
  }

  const moveToDetail = (workspaceId: number) => {
    history.push(`workspaces/${workspaceId}`)
  }

  return (
    <NavMenu type={menuType} items={menuItems} onNavMenuClick={setMenuType}>
      <div className="mt-3 mx-3" style={{ maxWidth: '80vw' }}>
        <div className="d-flex justify-content-between pb-3">
          <div className="font-x-large font-weight-bold align-self-center">ワークスペース一覧</div>
          <CustomButton icon="plus" disabled={isReadOnly} onClick={onWorkspaceCreateClick}>
            ワークスペースを追加
          </CustomButton>
        </div>
        <Card className={`position-sticky ${styles.list}`}>
          {workspacesStatus.workspaces.length ? (
            workspacesStatus.workspaces.map((workspace, index) => (
              <Card
                key={`workspace-card-${index}`}
                className="py-2 px-3 mb-2"
                role="button"
                onClick={() => moveToDetail(workspace.workspaceId)}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="overflow-hidden">
                    <CardText className="m-0 text-truncate">{workspace.name}</CardText>
                    <CardText className="small text-muted text-truncate">
                      {workspace.scheduleTypes.map(scheduleType => scheduleType.name).join('、')}
                    </CardText>
                  </div>
                  <i className="icf-chevron_right font-large" />
                </div>
              </Card>
            ))
          ) : (
            <div className="p-3 text-center">
              <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
              <div className="font-middle font-weight-bold py-4">ワークスペースがまだ登録されていません</div>
              <div>ワークスペースはあなたの事務所に合わせてカスタマイズできます。</div>
              <div>まずは最初のワークスペースを登録してみましょう。</div>
              <Button className="mx-auto d-block m-4" size="sm" outline onClick={onDetailClick}>
                ワークスペースについてもっと詳しく
              </Button>
            </div>
          )}
        </Card>
      </div>
    </NavMenu>
  )
}

export default Workspaces
