import moment from 'moment'
import { chain } from 'lodash'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectOptimizationStatus,
  getLocationConfig,
  getMagiQannealSchedule,
  clearError,
} from 'slices/optimizationSlice'
import { showSuccess } from 'slices/notificationSlice'
import { CustomModal } from 'components/common'
import { SHIFT_SCHEDULE_TYPE_ID, SUPPORT_SCHEDULE_TYPE_ID } from 'components/common/utils'
import { EditGroupsType, WorkPlanSchedulesType } from '../types'

type ScheduleType = {
  scheduleTypeId: number
  startAt: string
  duration: number
}
type EditOptEngineScheduleType = {
  wmsMemberId: string
  schedules: ScheduleType[]
}

type Props = {
  isOpen: boolean
  apiKey: string
  magiQannealTenant: string
  magiQannealLocation: string
  editGroups: EditGroupsType[]
  setEditGroups: (items: EditGroupsType[]) => void
  onCancel: () => void
  workDate?: string
}

const createWorkerSchedules = (optSchedules: ScheduleType[], workerSchedules: WorkPlanSchedulesType[]) => {
  const formatted = optSchedules.map<WorkPlanSchedulesType>(data => ({
    scheduleId: Math.random(),
    scheduleTypeId: data.scheduleTypeId,
    supportWorkspaceId: null,
    supportWorkspaceName: null,
    startAt: data.startAt,
    duration: data.duration,
    editable: true,
  }))

  const newWorkerSchedules = workerSchedules.reduce<WorkPlanSchedulesType[]>((acc, cur) => {
    // シフト情報と応援のデータの場合はそのまま返す
    if (
      cur.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID ||
      (cur.scheduleTypeId === SUPPORT_SCHEDULE_TYPE_ID && !cur.editable)
    ) {
      acc.push(cur)
      return acc
    }

    const reschedule = optSchedules.reduce<{ startAt?: string; duration?: number }>(
      (worker, opt) => {
        if (!worker.startAt || !worker.duration) {
          return worker
        }
        const optStart = moment(opt.startAt)
        const optEnd = moment(opt.startAt).add(opt.duration, 'seconds')
        const workerEnd = moment(worker.startAt).add(worker.duration, 'seconds')
        // optEngineのレスポンススケジュールのstartAtが登録スケジュールに含まれる時
        // startAt以降の登録スケジュールは削除される（上書き）
        if (optStart.isSame(worker.startAt, 'minute')) {
          return {}
        }
        if (optStart.isBetween(worker.startAt, workerEnd, 'minute', '()')) {
          return { ...worker, duration: worker.duration - (workerEnd.unix() - optStart.unix()) }
        }

        // optEngineのレスポンススケジュールのEND値が登録スケジュールに含まれる時
        // END値以前の登録スケジュールは削除される（上書き）
        if (optEnd.isSame(workerEnd, 'minute')) {
          return {}
        }
        if (optEnd.isBetween(worker.startAt, workerEnd, 'minute', '()')) {
          return {
            startAt: optEnd.format(),
            duration: workerEnd.unix() - optEnd.unix(),
          }
        }

        // optEngineのレスポンススケジュールに登録スケジュールが含まれる時
        if (
          moment(worker.startAt).isBetween(optStart, optEnd, 'minute', '()') &&
          workerEnd.isBetween(optStart, optEnd, 'minute', '()')
        ) {
          return {}
        }
        return worker
      },
      { startAt: cur.startAt, duration: cur.duration }
    )

    if (reschedule.startAt && reschedule.duration) {
      acc.push({ ...cur, startAt: reschedule.startAt, duration: reschedule.duration })
    }

    return acc
  }, [])

  return newWorkerSchedules.concat(formatted)
}

// シフト情報と応援情報から入力可能域を'1'にして返す
const getInputableSchedules = (schedules: WorkPlanSchedulesType[]) => {
  return chain(schedules)
    .filter(s => [SUPPORT_SCHEDULE_TYPE_ID, SHIFT_SCHEDULE_TYPE_ID].includes(s.scheduleTypeId))
    .sortBy('scheduleTypeId')
    .reduce((acc, cur) => {
      const isSupport = cur.scheduleTypeId === SUPPORT_SCHEDULE_TYPE_ID
      const isShift = cur.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID
      // 編集可能な応援は対象外
      if (isSupport && cur.editable) {
        return acc
      }

      const baseDate = moment(cur.startAt).startOf('day')
      const start = moment(cur.startAt)
      const end = moment(cur.startAt).add(cur.duration, 'seconds')
      return [...Array(24 * 4)]
        .map((_, index) => {
          const isActiveTime = baseDate.add(15, 'minutes').isBetween(start, end, 'minutes', '(]')
          return isActiveTime && isShift ? '1' : isActiveTime && isSupport ? '0' : acc.charAt(index)
        })
        .join('')
    }, ''.padStart(96, '0'))
    .value()
}

const ImportOptimizedDialog: React.FC<Props> = props => {
  const {
    apiKey,
    magiQannealTenant,
    magiQannealLocation,
    isOpen,
    editGroups,
    setEditGroups,
    onCancel,
    workDate,
  } = props
  const [submitted, setSubmitted] = React.useState(false)
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string>()
  const { isRequesting, optimizationError, optimizedSchedule, data } = useSelector(selectOptimizationStatus)
  const dispatch = useDispatch()

  const handleSubmitClick = () => {
    const date = moment(workDate).startOf('day').format()
    dispatch(getMagiQannealSchedule(apiKey, magiQannealTenant, magiQannealLocation, date))
    setSubmitted(true)
  }

  const optimizedScheduleIds = React.useMemo(() => data?.works.map(w => w.workId) ?? [], [data?.works])

  const formattedWorkerSchedules = React.useMemo(
    () =>
      editGroups.flatMap(edit =>
        edit.workers.map(w => ({
          ...w,
          schedules: getInputableSchedules(w.schedules),
        }))
      ),
    [editGroups]
  )

  const editOptEngineSchedules = React.useMemo(() => {
    const date = moment(optimizedSchedule?.period.datetimeFrom).startOf('day')
    // optEngineのレスポンスデータを startAt/duration 型に変更する
    // 応援のデータが入力済みの箇所を調整
    return optimizedSchedule?.workerSchedules.map<EditOptEngineScheduleType>(worker => {
      const inputable = formattedWorkerSchedules.find(w => w.wmsMemberId === worker.workerId)?.schedules
      const schedules = worker.assignedSchedule.flatMap<ScheduleType>(s => {
        const workData: [moment.Moment, number][] = []
        for (let index = 0; index < 24 * 4; index++) {
          if (s.schedule.charAt(index) === '1' && (!inputable || inputable.charAt(index) === '1')) {
            const start = date.clone().add(index * 15, 'minutes')
            let add = 0
            let duration = 0
            do {
              add++
              const moved = index + add
              if (moved === 24 * 4 || s.schedule.charAt(moved) === '0' || inputable?.charAt(moved) === '0') {
                duration = add * 15 * 60
              }
            } while (duration === 0)
            workData.push([start, duration])
            index += add
          }
        }

        return workData.map(d => ({ scheduleTypeId: s.workId, startAt: d[0].utc().format(), duration: d[1] }))
      })
      return { wmsMemberId: worker.workerId, schedules }
    })
  }, [optimizedSchedule?.period.datetimeFrom, optimizedSchedule?.workerSchedules, formattedWorkerSchedules])

  const formattedEditGroups = React.useMemo(
    () =>
      editGroups.map<EditGroupsType>(edit => {
        const workers = edit.workers.map(worker => {
          const found = editOptEngineSchedules?.find(opt => opt.wmsMemberId === worker.wmsMemberId)
          const filtered = worker.schedules.filter(s => !optimizedScheduleIds.includes(s.scheduleTypeId))
          if (found) {
            const schedules = createWorkerSchedules(found.schedules, filtered)
            return { ...worker, schedules }
          }
          return worker
        })

        return { ...edit, schedules: edit.schedules, workers }
      }),
    [editGroups, editOptEngineSchedules, optimizedScheduleIds]
  )

  React.useEffect(() => {
    if (!isOpen || isRequesting) {
      return
    }
    if (optimizationError) {
      // ここで NetworkErrorDialog が表示される

      dispatch(clearError())
      onCancel()
    } else if (submitted && !optimizationError) {
      setEditGroups(formattedEditGroups)
      onCancel()
      dispatch(
        showSuccess(
          optimizedSchedule?.workerSchedules.length === 0
            ? { successMessage: '「最適配置」のデータがありません。' }
            : undefined
        )
      )
    }
    setSubmitted(false)
  }, [
    dispatch,
    formattedEditGroups,
    isOpen,
    isRequesting,
    onCancel,
    optimizationError,
    setEditGroups,
    submitted,
    optimizedSchedule?.workerSchedules,
  ])

  React.useEffect(() => {
    if (isOpen) {
      // 最適配置対象作業を取得する
      dispatch(
        getLocationConfig(apiKey, magiQannealTenant, magiQannealLocation, moment(workDate).startOf('day').format())
      )
    }
  }, [apiKey, dispatch, isOpen, workDate, magiQannealTenant, magiQannealLocation])

  return (
    <CustomModal
      isOpen={isOpen}
      title="配置結果取込み"
      approveLabel="取込"
      errorMessage={modalErrorMessage}
      onCancel={onCancel}
      onApprove={handleSubmitClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div>「最適配置」の結果を作業計画に取込みます。</div>
      <div>（「最適配置ボタン」押下以降に入力された作業計画は上書きされます）</div>
    </CustomModal>
  )
}

export default ImportOptimizedDialog
