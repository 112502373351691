import * as React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { PopupActions, PopupPosition } from 'reactjs-popup/dist/types'
import Popup from 'reactjs-popup'
import ja from 'date-fns/locale/ja'
import { selectWorksStatus } from 'slices/worksSlice'
import { CustomButton } from 'components/common'
import styles from './DateChangeButton.module.scss'

registerLocale('ja', ja)

type Props = {
  date: string // YYYY-MM-DD
  onError?: () => void
  onSuccess?: () => void
  onChange?: (date: Date) => void
  popupPosition?: PopupPosition
  filterDate?: (date: Date) => boolean
  className?: string
  isWorkPlanView?: boolean
}
export const DateChangeButton: React.FC<Props> = ({
  date,
  onChange = () => {},
  onSuccess = () => {},
  onError = () => {},
  popupPosition = 'bottom center',
  filterDate,
  className,
  isWorkPlanView = false,
}) => {
  const [submitted, setSubmitted] = React.useState(false)
  const ref = React.useRef<PopupActions>(null)
  const { errorMessage, isRequesting } = useSelector(selectWorksStatus)
  const handleChangeTo = (newDate: Date | [Date, Date] | null) => {
    if (newDate instanceof Date) {
      onChange(newDate)
      setSubmitted(true)
    }
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }

    setSubmitted(false)
    if (errorMessage === '') {
      onSuccess()
    } else {
      onError()
    }
    ref.current?.close()
  }, [isRequesting, errorMessage, submitted, onSuccess, onError])

  const trigger = (
    <div>
      {isWorkPlanView ? (
        <div className={`d-flex align-items-center px-3 py-1 bg-white ${styles.dateButton} ${className}`}>
          <div className="font-weight-bold">{moment(date).format('YYYY/MM/DD')}</div>
          <i className="icf-cal pl-2 font-large" />
        </div>
      ) : (
        <CustomButton icon="cal" iconPosition="right" outline className={className}>
          {moment(date).format('YYYY/MM/DD')}
        </CustomButton>
      )}
    </div>
  )

  return (
    <Popup ref={ref} trigger={trigger} position={popupPosition}>
      <ReactDatePicker
        selected={new Date(date)}
        onChange={handleChangeTo}
        selectsEnd
        inline
        excludeDates={[new Date(date)]}
        filterDate={filterDate}
        dateFormat="yyyy/MM/dd"
        dateFormatCalendar={'yyyy年MM月'}
        locale="ja"
      />
    </Popup>
  )
}
export default DateChangeButton
