import moment from 'moment'
import * as React from 'react'
import { DropdownItem, Button } from 'reactstrap'
import { DropdownList } from 'components/common'
import styles from './styles.module.scss'

export type Props = {
  start: string
  end: string
  selectedMonth: string | undefined
  onChange: (slectedMonth: string) => void
}

const MonthSelect: React.FC<Props> = ({ start, end, selectedMonth, onChange }) => {
  const [openMonth, setOpenMonth] = React.useState(false)
  const [monthLabel, setMonthLabel] = React.useState('')

  const monthList = React.useMemo(() => {
    const startMonth = moment(start).startOf('month')
    const endMonth = moment(end).startOf('month')
    const months = []
    while (startMonth.diff(endMonth) <= 0) {
      months.push({
        label: startMonth.format('YYYY/M'),
        month: startMonth.format('YYYY-MM'),
        id: startMonth.format('YYYYMM'),
      })
      startMonth.add(1, 'month')
    }
    return months.reverse()
  }, [start, end])

  React.useEffect(() => {
    const month = monthList.find(m => m.month === selectedMonth)
    setMonthLabel(month?.label || '')
  }, [monthList, selectedMonth])

  const toggleContent = (
    <div className="bg-white rounded">
      <Button outline className="d-flex align-items-center">
        <div className={styles.buttonLabel}>{monthLabel}</div>
        <i className="icf-carot_down pl-2 font-large" />
      </Button>
    </div>
  )

  return (
    <div>
      <DropdownList
        open={openMonth}
        setOpen={() => setOpenMonth(!openMonth)}
        content={toggleContent}
        direction="down"
        dropdownClassName={styles.transform}
      >
        {monthList.map(m => (
          <React.Fragment key={m.id}>
            <DropdownItem onClick={() => onChange(m.month)}>{m.label}</DropdownItem>
          </React.Fragment>
        ))}
      </DropdownList>
    </div>
  )
}

export default MonthSelect
