import _ from 'lodash'
import * as React from 'react'
import { Button, CustomInput } from 'reactstrap'

export type FilterItem = {
  key: number
  label: string
  checked: boolean
}

type Props = {
  filterItems: FilterItem[]
  onChange: (filterItems: FilterItem[]) => void
}

const CheckboxGroup: React.FC<Props> = ({ filterItems, onChange }) => {
  const allSelected = React.useMemo(() => filterItems.every(item => item.checked), [filterItems])

  const handleCheckboxChange = (index: number, checked: boolean) => {
    const newFilterItems = _.cloneDeep(filterItems)
    newFilterItems.splice(index, 1, { ...filterItems[index], checked })
    onChange(newFilterItems)
  }

  const handleButtonClick = () => {
    const newFilterItems = filterItems.map(item => ({ ...item, checked: !allSelected }))
    onChange(newFilterItems)
  }

  return (
    <div className="p-2">
      <Button className="mb-2" color="primary" size="sm" block outline onClick={handleButtonClick}>
        {allSelected ? 'すべて解除' : 'すべて選択'}
      </Button>
      {filterItems.map((filterItem, index) => (
        <CustomInput
          key={filterItem.label}
          id={`checkboxGroup-${filterItem.key}-${filterItem.label}`}
          type="checkbox"
          label={filterItem.label}
          checked={filterItem.checked}
          onChange={e => handleCheckboxChange(index, e.target.checked)}
          className="text-break"
        />
      ))}
    </div>
  )
}

export default CheckboxGroup
