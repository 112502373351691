import _ from 'lodash'
import * as React from 'react'
import { Input } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectSkillsStatus } from 'slices/skillsSlice'
import { selectGroupsStatus } from 'slices/groupsSlice'
import { FilterItem } from 'components/common/types'
import { FilteringButton } from 'components/common'
import styles from './AssignmentWorkerFilter.module.scss'
import { ScheduleEditType } from './DroppingWorkerCards'

type Props = {
  scheduleEditData: ScheduleEditType[]
  onChange: (data: ScheduleEditType[]) => void
}

const BLANK_ID = -1

const AssignmentWorkerFilter: React.FC<Props> = ({ onChange, scheduleEditData }) => {
  const { skills } = useSelector(selectSkillsStatus)
  const { groups } = useSelector(selectGroupsStatus)
  const [filterWord, setFilterWord] = React.useState('')
  const [selectedFilterGroups, setSelectedFilterGroups] = React.useState<number[]>([])
  const [selectedFilterSkills, setSelectedFilterSkills] = React.useState<number[]>([])

  React.useEffect(() => groups && setSelectedFilterGroups(groups.map(g => g.groupId).concat(BLANK_ID)), [groups])

  React.useEffect(() => skills && setSelectedFilterSkills(skills.map(s => s.skillId).concat(BLANK_ID)), [skills])

  const filterGroupItems = React.useMemo<FilterItem[]>(
    () =>
      groups
        .map(g => ({ key: g.groupId, label: g.name, checked: selectedFilterGroups.includes(g.groupId) }))
        .concat({ key: BLANK_ID, label: '未所属', checked: selectedFilterGroups.includes(BLANK_ID) }),
    [groups, selectedFilterGroups]
  )

  const filterSkillItems = React.useMemo<FilterItem[]>(
    () =>
      skills
        .map(s => ({ key: s.skillId, label: s.name, checked: selectedFilterSkills.includes(s.skillId) }))
        .concat({ key: BLANK_ID, label: '未設定', checked: selectedFilterSkills.includes(BLANK_ID) }),
    [skills, selectedFilterSkills]
  )

  const selectedGroupNames = React.useMemo(() => {
    const names = groups.filter(g => selectedFilterGroups.includes(g.groupId)).map(g => g.name)
    selectedFilterGroups.includes(BLANK_ID) && names.push('未所属')
    return names
  }, [selectedFilterGroups, groups])

  React.useEffect(() => {
    const filteredScheduleEditData = scheduleEditData.map(s => {
      const workers = s.workers.filter(w => {
        const hasGroups = selectedGroupNames.includes(w.groupName || '未所属')
        const hasBlankSkill = selectedFilterSkills.includes(BLANK_ID) && _.isEmpty(w.skillIds)
        const hasSelectedSkill = hasBlankSkill || !_.isEmpty(_.intersection(selectedFilterSkills, w.skillIds))
        const hasFilterWord = filterWord === '' || w.name.includes(filterWord) || w.wmsMemberId.includes(filterWord)
        return hasGroups && hasSelectedSkill && hasFilterWord
      })

      return { ...s, workers }
    })
    onChange(filteredScheduleEditData)
  }, [scheduleEditData, selectedGroupNames, selectedFilterSkills, filterWord, onChange])

  return (
    <div className={`d-flex mb-3 ${styles.filterButtonGroup}`}>
      <div className="position-relative w-25">
        <i className={`icf-search font-large text-muted ${styles.searchIcon}`} />
        <Input
          placeholder="メンバー名もしくはID・識別番号で検索"
          className="border-secondary"
          style={{ paddingLeft: '38px' }}
          onChange={e => setFilterWord(e.target.value)}
        />
      </div>
      <FilteringButton
        items={filterGroupItems}
        onChange={setSelectedFilterGroups}
        value={selectedFilterGroups}
        label="グループで絞り込み"
        disabled={_.isEmpty(groups)}
      />
      <FilteringButton
        items={filterSkillItems}
        onChange={setSelectedFilterSkills}
        value={selectedFilterSkills}
        label="スキルで絞り込み"
        disabled={_.isEmpty(skills)}
      />
    </div>
  )
}
export default AssignmentWorkerFilter
