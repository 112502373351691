import _ from 'lodash'
import { WorkspaceSummaryResponse, HourlyWorkerRow } from 'api/dashboard'

export const getWorkerPerformance = (
  workspaceSummary: WorkspaceSummaryResponse | undefined,
  groupId: number,
  workerId: number
): 'bad' | 'normal' | 'good' => {
  const targetHour = new Date().getHours() - 1
  const data = _.chain(workspaceSummary?.workspaceData.groups)
    .find({ groupId })
    .get('workers')
    .find({ workerId })
    .get('hourlyWorkerData')
    .flatMap<HourlyWorkerRow>('data')
    .filter(d => new Date(d.time).getHours() === targetHour)
    .value()
  const totalPlanCount = data.reduce((acc, cur) => acc + (cur.planCount || 0), 0)
  const totalRecordCount = data.reduce((acc, cur) => acc + (cur.recordCount || 0), 0)
  const performance = totalPlanCount === 0 ? 0 : Math.floor((totalRecordCount / totalPlanCount) * 100)
  if (totalPlanCount === 0 && totalRecordCount === 0) {
    return 'normal'
  } else if (performance < 90) {
    return 'bad'
  } else if (111 <= performance) {
    return 'good'
  }
  return 'normal'
}
