import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { INCORRECT_OLD_PASSWORD_MESSAGE } from 'api/utils'
import { Role } from 'api/users'
import { updateAccountPassword, selectSessionStatus, clearErrorMessage } from 'slices/sessionSlice'
import { InputFormat, CustomModal } from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'

type Props = {
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

const PasswordChange: React.FC<Props> = ({ isOpen, onSuccess, onCancel }) => {
  const { user, isRequesting, errorMessage } = useSelector(selectSessionStatus)
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string | undefined>(undefined)
  const [oldPassword, setOldPassword] = React.useState<string | undefined>(undefined)
  const [newPassword, setNewPassword] = React.useState<string | undefined>(undefined)
  const [confirmation, setConfirmation] = React.useState<string | undefined>(undefined)
  const [oldPasswordValidity, setOldPasswordValidity] = React.useState(false)
  const [newPasswordValidity, setNewPasswordValidity] = React.useState(false)
  const [confirmationValidity, setConfirmationValidity] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const dispatch = useDispatch()

  const disabled = React.useMemo(
    () =>
      !(
        oldPassword &&
        oldPasswordValidity &&
        newPassword &&
        newPasswordValidity &&
        confirmation &&
        confirmationValidity
      ),
    [oldPassword, oldPasswordValidity, newPassword, newPasswordValidity, confirmation, confirmationValidity]
  )

  const initInputState = () => {
    setOldPassword(undefined)
    setNewPassword(undefined)
    setConfirmation(undefined)
    setModalErrorMessage(undefined)
    setSubmitted(false)
  }

  const handleSaveClick = () => {
    if (oldPassword && newPassword) {
      setSubmitted(true)
      dispatch(updateAccountPassword({ oldPassword, newPassword }))
    }
  }
  const handleCancelClick = () => {
    initInputState()
    onCancel()
  }

  React.useEffect(() => {
    if (isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      initInputState()
      onSuccess()
    } else {
      if (errorMessage === INCORRECT_OLD_PASSWORD_MESSAGE) {
        setModalErrorMessage('現在のパスワードが間違っています')
      } else {
        setModalErrorMessage('保存できませんでした')
      }
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess, dispatch])

  return (
    <CustomModal
      isOpen={isOpen}
      title="パスワードを変更"
      approveDisabled={disabled}
      errorMessage={modalErrorMessage}
      onCancel={handleCancelClick}
      onApprove={handleSaveClick}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <div className="pb-2">
        <div>
          <InputFormat
            label="現在のパスワード"
            type="password"
            placeholder="現在のパスワード"
            value={oldPassword}
            size="middle"
            maxLength={32}
            onChange={value => setOldPassword(value)}
            validations={[Rules.MinLength(8), Rules.Password]}
            onValidate={setOldPasswordValidity}
          />
        </div>

        <div className="mt-4">
          {user.role !== Role.TenantAdmin && (
            <div className="mb-2">
              新しいパスワード（半角英数字8文字以上・大文字・小文字を含む）を入力してください。
            </div>
          )}

          <InputFormat
            label="新しいパスワード"
            type="password"
            placeholder="新しいパスワード"
            value={newPassword}
            size="middle"
            maxLength={32}
            onChange={value => setNewPassword(value)}
            validations={[Rules.MinLength(8), Rules.Password, Rules.DifferentPassword(oldPassword)]}
            onValidate={setNewPasswordValidity}
          />
        </div>

        <div className="mt-4">
          <InputFormat
            label="パスワードの確認"
            type="password"
            placeholder="パスワードの確認"
            value={confirmation}
            size="middle"
            maxLength={32}
            onChange={value => setConfirmation(value)}
            validations={[Rules.MinLength(8), Rules.Password, Rules.PasswordConfirmation(newPassword)]}
            onValidate={setConfirmationValidity}
          />
        </div>
      </div>
    </CustomModal>
  )
}

export default PasswordChange
