import _ from 'lodash'
import moment, { Moment } from 'moment'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { getTenantSummary, selectTenantsStatus, getTenant, getWorkspaceSummary } from 'slices/tenantsSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getDashboardFilter } from 'slices/usersSlice'
import { selectWorksStatus, getWorkByDate } from 'slices/worksSlice'
import { getWorkerCounts, getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'
import { BadgeLabel, CustomButton, DropdownButton, MoveDropdown, NavMenu } from 'components/common'
import TotalSummary from './TotalSummary'
import IndividualSummary from './IndividualSummary'
import WorkerCountsCard from './WorkerCountsCard'
import DashboardDateChangeButton from './DashboardDateChangeButton'
import ManualInputDialog from './ManualInputDialog/ManualInputDialog'
import { getManualInputWorkspaces } from './utils'
import CSVManualInputDialog from './CSVManualInputDialog'

type ParamProps = {
  workspaceId: string
}

const MANUAL_INPUT_AVAILABLE_PERIOD = 32

const Dashboard: React.FC = () => {
  const [openMoment, setOpenMoment] = React.useState<Moment>(moment())
  const { workspaceId } = useParams<ParamProps>()
  const [menuType, setMenuType] = React.useState(workspaceId || 'summary')
  const [selectedCards, setSelectedCards] = React.useState<number[]>([])
  const [selectedGraphs, setSelectedGraphs] = React.useState<number[]>([])
  const [date, setDate] = React.useState(moment().format('YYYY-MM-DD'))
  const [workspaceIds, setWorkspaceIds] = React.useState<number[]>([])
  const [isOpenManualInputDialog, setIsOpenManualInputDialog] = React.useState(false)
  const [isOpenCSVInputDialog, setIsOpenCSVInputDialog] = React.useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = useLocation()
  const pathMatch = useRouteMatch<{ workspaceId: string }>('/dashboard/:workspaceId')

  const { works } = useSelector(selectWorksStatus)
  const { user } = useSelector(selectSessionStatus)
  const { tenantSummary } = useSelector(selectTenantsStatus)
  const { workspaces } = useSelector(selectWorkspacesStatus)

  const tenantId = React.useMemo(() => user.tenants[0].tenantId, [user.tenants])
  const dateQuery = React.useMemo(() => new URLSearchParams(search).get('date'), [search])

  React.useEffect(() => {
    dispatch(getDashboardFilter())
  }, [dispatch])

  React.useEffect(() => {
    dispatch(getTenant(tenantId))
    dispatch(getWorkspaceList(tenantId))
  }, [dispatch, tenantId])

  React.useEffect(() => {
    dispatch(getWorkerCounts(workspaceIds, date))
  }, [dispatch, workspaceIds, date])

  React.useEffect(() => {
    if (menuType === 'summary' || (dateQuery && date !== dateQuery)) {
      return
    }
    dispatch(getWorkspaceSummary(Number(menuType), date))
  }, [dispatch, menuType, openMoment, date, dateQuery])

  React.useEffect(() => {
    if (dateQuery && date !== dateQuery) {
      return
    }
    if (menuType === 'summary') {
      dispatch(getTenantSummary(date, { dashboardFilter: true }))
    } else {
      dispatch(getWorkByDate(Number(menuType), date, false))
      dispatch(getTenantSummary(date, { dashboardFilter: true, workspaceId: Number(menuType) }))
    }
  }, [dispatch, menuType, date, dateQuery])

  React.useEffect(() => {
    // TotalSummary や IndividualSummary の中で history.push により遷移した場合でも displayItem が動作するよう
    // handleNavMenuClick の中ではなく pathMatch の useEffect で setMenuType を実行する
    setMenuType(pathMatch?.params.workspaceId || 'summary')
  }, [pathMatch])

  React.useEffect(() => {
    if (dateQuery) {
      setDate(dateQuery)
      return
    }
    setDate(openMoment.format('YYYY-MM-DD'))
  }, [dateQuery, openMoment])

  React.useEffect(() => {
    // 15 分毎に getTenantSummary を定期的に呼ぶ
    const timerId = setTimeout(() => {
      const scheduleTypeIds = menuType === 'summary' ? selectedGraphs : selectedCards
      scheduleTypeIds.forEach(scheduleTypeId => dispatch(getTenantSummary(date, { scheduleTypeId })))
      setOpenMoment(moment())
    }, 15 * 60 * 1000)
    return () => clearTimeout(timerId)
  }, [dispatch, openMoment, menuType, selectedGraphs, selectedCards, date])

  const menuItems = React.useMemo(() => {
    const items = _.sortBy(tenantSummary?.workspaceData, 'workspaceName').map(data => ({
      type: data.workspaceId.toString(),
      label: data.workspaceName,
    }))
    return [{ type: 'summary', label: 'サマリー' }].concat(items)
  }, [tenantSummary?.workspaceData])

  React.useEffect(() => {
    const ids = tenantSummary?.workspaceData.map(wd => wd?.workspaceId) || []
    if (_.isEqual(workspaceIds, ids)) {
      return
    }
    setWorkspaceIds(ids)
  }, [tenantSummary?.workspaceData, workspaceIds])

  const tenantSummaryData = React.useMemo(
    () => tenantSummary?.workspaceData.find(data => data.workspaceId === Number(menuType)),
    [tenantSummary?.workspaceData, menuType]
  )
  const handleNavMenuClick = (type: string) => {
    if (type === 'summary') {
      history.push(`/dashboard?date=${date}`)
      return
    }
    history.push(`/dashboard/${type}?date=${date}`)
  }

  const movePaths = React.useMemo(() => {
    const items = []
    if (!_.isEmpty(works)) {
      items.push({ label: '作業計画', onClick: () => history.push(`/schedules/${workspaceId}/${works[0].workId}`) })
    }
    if (date === moment().format('YYYY-MM-DD')) {
      items.push({ label: '人員配置', onClick: () => history.push(`/assignment/${workspaceId}`) })
    }
    return items
  }, [history, works, workspaceId, date])
  const displayItem = (): JSX.Element => {
    if (menuType === 'summary') {
      return (
        <>
          <TotalSummary
            tenantId={tenantId}
            tenantSummary={tenantSummary}
            selectedGraphs={selectedGraphs}
            setSelectedGraphs={setSelectedGraphs}
            date={date}
          />
          <WorkerCountsCard tenantId={tenantId} close total />
        </>
      )
    }
    if (tenantSummaryData) {
      return (
        <IndividualSummary
          tenantId={tenantId}
          workspaceId={tenantSummaryData.workspaceId}
          tenantSummary={tenantSummary}
          selectedCards={selectedCards}
          setSelectedCards={setSelectedCards}
          date={date}
        />
      )
    }
    return <div />
  }

  const showManualInput = React.useMemo(() => {
    const selectedWorkspace =
      menuType === 'summary' ? workspaces : workspaces.filter(w => w.workspaceId === Number(menuType))
    const existManualInput = !_.isEmpty(getManualInputWorkspaces(selectedWorkspace))
    const today = moment().startOf('d')
    const diff = moment(today).diff(date, 'd')
    const inPeriod = diff >= 0 && diff <= MANUAL_INPUT_AVAILABLE_PERIOD

    return existManualInput && inPeriod
  }, [workspaces, date, menuType])

  const handleManualInputSuccess = () => {
    setIsOpenManualInputDialog(false)
    dispatch(getTenantSummary(date, { dashboardFilter: true }))
  }

  return (
    <NavMenu type={menuType} items={menuItems} onNavMenuClick={handleNavMenuClick} name="dashboard-nav-menu">
      <div>
        <div className="d-flex m-4">
          <div className="d-flex align-items-center">
            <div className="font-x-large font-weight-bold pr-2">ダッシュボード</div>
            <BadgeLabel label={`${menuType === 'summary' ? 'サマリー' : tenantSummaryData?.workspaceName}`} />
          </div>
          <div className="d-flex ml-auto">
            {menuType !== 'summary' && !_.isEmpty(movePaths) && (
              <div className="mr-2">
                <MoveDropdown items={movePaths} name="individual-summary-move-dropdown"></MoveDropdown>
              </div>
            )}
            {showManualInput && (
              <DropdownButton
                buttonLabel="実績入力"
                onClickButton={() => setIsOpenManualInputDialog(true)}
                dropdownItems={[{ label: '実績CSV一括入力', onClick: () => setIsOpenCSVInputDialog(true) }]}
                className="mr-2"
              />
            )}
            {menuType !== 'summary' && (
              <CustomButton
                icon="edit"
                outline
                className="mr-2"
                onClick={() => history.push(`/workspaces/${workspaceId}`)}
              >
                ワークスペース設定
              </CustomButton>
            )}
            <DashboardDateChangeButton onChange={setDate} />
          </div>
        </div>
        <div className="mt-3 mx-3">{displayItem()}</div>
        <ManualInputDialog
          isOpen={isOpenManualInputDialog}
          onCancel={() => setIsOpenManualInputDialog(false)}
          date={date}
          menuType={menuType}
          onSuccess={handleManualInputSuccess}
        />
        <CSVManualInputDialog
          isOpen={isOpenCSVInputDialog}
          onCancel={() => setIsOpenCSVInputDialog(false)}
          date={date}
        />
      </div>
    </NavMenu>
  )
}

export default Dashboard
