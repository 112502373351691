import moment from 'moment'
import * as React from 'react'
import { Card, CardBody, Input } from 'reactstrap'
import Popup from 'reactjs-popup'
import { PopupActions } from 'reactjs-popup/dist/types'

type Props = {
  performanceRateValue: string
  startTime: string
  duration: number
  disabled?: boolean
  onChange: (value: number) => void
  onDelete: () => void
}

const PerformanceRatePopover: React.FC<Props> = props => {
  const { performanceRateValue, startTime, duration, disabled = false, onChange, onDelete } = props
  const [invalid, setInvalid] = React.useState(false)
  const [time, setTime] = React.useState('')

  React.useEffect(() => {
    const start = moment(startTime).local().format('HH:mm')
    const endTime = moment(startTime).local().add(duration, 'seconds').format('HH:mm')
    setTime(`${start}〜${endTime}`)
  }, [duration, startTime])

  const onInputChange = (value: string) => {
    if (/^(\+|-)|\.$|^\d*\.\d{3,}$/.test(value) || isNaN(Number(value)) || value === '') {
      setInvalid(true)
    } else {
      setInvalid(false)
      value !== performanceRateValue && onChange(Number(value))
    }
  }

  const ref = React.useRef<PopupActions>(null)
  const trigger = <div style={{ minWidth: '15px', minHeight: '30px' }}>{performanceRateValue}</div>

  const onDeleteHandler = () => {
    onDelete()
    ref.current?.close()
  }

  return (
    <Popup
      ref={ref}
      trigger={trigger}
      onOpen={() => setInvalid(false)}
      position="bottom center"
      contentStyle={{ width: '12.5rem' }}
    >
      <Card>
        <CardBody className="p-2 small">
          <div className="d-flex justify-content-end font-large">
            {!disabled && <i className="icf-delete" onClick={() => onDeleteHandler()} />}
            <i className="icf-close pl-1" onClick={() => ref.current?.close()} />
          </div>
          <div className="my-2">
            <Input
              defaultValue={performanceRateValue}
              invalid={invalid}
              placeholder="1.00"
              maxLength={10}
              disabled={disabled}
              onChange={e => onInputChange(e.target.value)}
            />
          </div>
          <div className="mb-1">{time}</div>
        </CardBody>
      </Card>
    </Popup>
  )
}

export default PerformanceRatePopover
