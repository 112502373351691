import axios from 'axios'
import { CommonParams } from 'slices/utils'

export type SkillResponse = {
  skillId: number
  name: string
  updatedBy: string
  updatedAt: string
}

export type SkillListResponse = {
  skills: SkillResponse[]
}

export const getSkillList = (params: CommonParams): Promise<SkillListResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/skills`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .get(url, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const createSkill = (params: CommonParams, name: string): Promise<SkillResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/skills`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .post(url, { name, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const updateSkill = (params: CommonParams, skillId: number, name: string): Promise<SkillResponse> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/skills/${skillId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .patch(url, { name, updatedBy: params.updatedBy }, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

export const deleteSkill = (params: CommonParams, skillId: number): Promise<{}> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API_SERVER}/api/v1/tenants/${params.tenantId}/skills/${skillId}`
    const headers = {
      Authorization: params.idToken,
      'X-Access-Authorization': params.accessToken,
    }
    axios
      .delete(url, { headers, params: { updatedBy: params.updatedBy } })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
