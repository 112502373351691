import * as React from 'react'
import { CELL_WIDTH } from 'components/common/utils'
import useBusinessTime from 'hooks/useBusinessTime'
import ManualInputCell from './ManualInputCell'
import styles from './ManualInput.module.scss'

type Props = {
  id: string
  items: number[]
  onChange: (value: number[]) => void
}
const ManualInputRow: React.FC<Props> = ({ id, items, onChange }) => {
  const { businessHourBlocks } = useBusinessTime()
  const width = CELL_WIDTH * 4 * businessHourBlocks.length

  const handleChange = (newValue: number, index: number) => {
    onChange(items.map((item, itemIndex) => (itemIndex === index ? newValue : item)))
  }

  return (
    <table>
      <tbody>
        <tr className={styles.container} style={{ width }}>
          {businessHourBlocks.map((_, hourBlockIndex) => (
            <ManualInputCell
              key={`${id}-${hourBlockIndex}`}
              value={items[hourBlockIndex]}
              onChange={newValue => handleChange(newValue, hourBlockIndex)}
            />
          ))}
        </tr>
      </tbody>
    </table>
  )
}

export default ManualInputRow
