import { AxiosError } from 'axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from 'store'
import * as API from 'api/groups'
import { makeErrorMessage, UNREACHABLE_ERROR_STATUS_CODE, UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'
import { commonParams } from 'slices/utils'
import { validateToken } from 'slices/sessionSlice'
import * as Spinner from 'slices/spinnerSlice'
import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import { ColorType } from 'components/common/types'

export type AllGroupType = {
  groups: API.GroupResponse[]
  workspaceId: number
}

type GroupsState = API.GroupListResponse & {
  allGroups: AllGroupType[]
  isRequesting: boolean
  errorMessage: string
}

const initialState: GroupsState = {
  isRequesting: false,
  errorMessage: '',
  groups: [],
  allGroups: [],
}

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getGroupListSuccess: (state, action: PayloadAction<API.GroupListResponse>) => {
      state.isRequesting = false
      state.groups = action.payload.groups
    },
    getGroupSuccess: (state, action: PayloadAction<API.GroupResponse>) => {
      state.isRequesting = false
      const index = state.groups.findIndex(group => group.groupId === action.payload.groupId)
      state.groups.splice(index, 1, action.payload)
    },
    createGroupSuccess: (state, action: PayloadAction<API.GroupResponse>) => {
      state.isRequesting = false
      state.groups.push(action.payload)
    },
    updateGroupSuccess: (state, action: PayloadAction<API.GroupResponse>) => {
      state.isRequesting = false
      state.groups = state.groups.map(group => (group.groupId === action.payload.groupId ? action.payload : group))
    },
    deleteGroupSuccess: (state, action: PayloadAction<number>) => {
      state.isRequesting = false
      state.groups = state.groups.filter(group => group.groupId !== action.payload)
    },
    getAllGroupsSuccess: (state, action: PayloadAction<AllGroupType[]>) => {
      state.isRequesting = false
      state.allGroups = action.payload
    },
  },
})

export const {
  startRequest,
  clearErrorMessage,
  apiFailure,
  getGroupListSuccess,
  getGroupSuccess,
  createGroupSuccess,
  updateGroupSuccess,
  deleteGroupSuccess,
  getAllGroupsSuccess,
} = groupsSlice.actions

export const getGroupList = (workspaceId: number, useWorkers = true): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getGroupList(commonParams(getState), workspaceId, useWorkers)
    .then((res: API.GroupListResponse) => dispatch(getGroupListSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const getGroup = (workspaceId: number, groupId: number, useWorkers = true): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.getGroup(commonParams(getState), workspaceId, groupId, useWorkers)
    .then((res: API.GroupResponse) => dispatch(getGroupSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const createGroup = (workspaceId: number, name: string): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.createGroup(commonParams(getState), workspaceId, name)
    .then((res: API.GroupResponse) => dispatch(createGroupSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const updateGroup = (workspaceId: number, groupId: number, name: string, color: ColorType): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.updateGroup(commonParams(getState), workspaceId, groupId, name, color)
    .then((res: API.GroupResponse) => dispatch(updateGroupSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const deleteGroup = (workspaceId: number, groupId: number): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  API.deleteGroup(commonParams(getState), workspaceId, groupId)
    .then(() => dispatch(deleteGroupSuccess(groupId)))
    .catch((res: AxiosError) => dispatch(apiFailure({ errorMessage: makeErrorMessage(res) })))
    .finally(() => dispatch(Spinner.stop()))
}

export const getAllGroups = (workspaceIds: number[]): AppThunk => async (dispatch, getState) => {
  dispatch(startRequest())
  const valid = await dispatch(validateToken())
  if (!valid) {
    return
  }

  dispatch(Spinner.start())
  const promises: Promise<AllGroupType>[] = workspaceIds.map(workspaceId =>
    API.getGroupList(commonParams(getState), workspaceId, false).then(res => ({ groups: res.groups, workspaceId }))
  )

  await Promise.all(promises)
    .then((res: AllGroupType[]) => dispatch(getAllGroupsSuccess(res)))
    .catch((res: AxiosError) => {
      const errorCode = makeErrorMessage(res)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    })
    .finally(() => dispatch(Spinner.stop()))
}

export const selectGroupsStatus = (state: RootState) => ({ ...state.groups })

export default groupsSlice.reducer
